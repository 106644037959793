import { paymentsConstants } from "../constants";
import { Alert } from "../../components/Alert.jsx";
import { PaymentsService } from "../../services/PaymentsService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (newFileData, actionType) => {
  return {
    type: actionType,
    payload: newFileData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const SeletedPlanUser = (data) => {
  console.log(data, 'dataPlan')
  return {
    type: paymentsConstants.PAYMENT_SELECTEDPLAN_USER_SUCCESS,
    payload: data,
  };
};

export const ResetPlanUser = () => {
  return {
    type: paymentsConstants.PAYMENT_RESETPLAN_USER,
  };
};

export const getPlansService = (filesData) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.GET_PLANS_REQUEST));
    try {
      const paymentsService = PaymentsService.getInstance();
      console.log('filesData', filesData)
      const getPlansData = await paymentsService.GetPlansService(filesData);
      console.log('getPlansData', getPlansData);
      dispatch(success(getPlansData.data, paymentsConstants.GET_PLANS_SUCCESS));
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, paymentsConstants.GET_PLANS_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

export const GetDataPlanClient = (dataClient) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_GETUSERDATA_REQUEST));
    try {
      console.log('dataClient', dataClient)
      const paymentsService = PaymentsService.getInstance();
      const getPlansData = await paymentsService.GetClientData(dataClient);
      console.log('getPlansData', getPlansData);
      dispatch(success(getPlansData.data, paymentsConstants.PAYMENT_GETUSERDATA_SUCCESS));
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, paymentsConstants.PAYMENT_GETUSERDATA_FAILURE));
      /* Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      }); */
    }
  };
};

export const GetBilling = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_GETBILLING_REQUEST));
    try {
      const paymentsService = PaymentsService.getInstance();
      const paymentData = await paymentsService.GetBilling(data);
      console.log('BILLINGS', paymentData)
      dispatch(success(paymentData, paymentsConstants.PAYMENT_GETBILLING_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentsConstants.PAYMENT_GETBILLING_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

export const RegisterBillingInformation = (data, isRetry = false) => {
  return async (dispatch) => {
    if (isRetry == true) {
      dispatch(request(paymentsConstants.PAYMENT_REGISTER_BILLING_RESET));
    } else {
      dispatch(request(paymentsConstants.PAYMENT_REGISTER_BILLING_REQUEST));
      try {
        const paymentsService = PaymentsService.getInstance();
        const paymentData = await paymentsService.RegisterBillingInformation(data);
        console.log('paymentData', paymentData)
        dispatch(success(paymentData.data, paymentsConstants.PAYMENT_REGISTER_BILLING_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, paymentsConstants.PAYMENT_REGISTER_BILLING_FAILURE));
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + error.message,
        });
      }
    };
  }

};

export const PutDataFiscalClient = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_REGISTER_BILLING_REQUEST));
    try {
      const paymentService = PaymentsService.getInstance();
      const paymentData = await paymentService.PutDataFiscalClient(data);
      dispatch(success(paymentData.data, paymentsConstants.PAYMENT_REGISTER_BILLING_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentsConstants.PAYMENT_REGISTER_BILLING_FAILURE));
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const GetRegimenFiscal = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_GET_REGIMENFISCAL_REQUEST));
    try {
      const paymentService = PaymentsService.getInstance();
      const paymentData = await paymentService.GetRegimenFiscal(data);
      dispatch(success(paymentData.data, paymentsConstants.PAYMENT_GET_REGIMENFISCAL_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentsConstants.PAYMENT_GET_REGIMENFISCAL_FAILURE));
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const GetUseCfdi = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_GET_USECDFI_REQUEST));
    try {
      const paymentService = PaymentsService.getInstance();
      const paymentData = await paymentService.GetUseCfdi(data);
      console.log('paymentData', paymentData)
      dispatch(success(paymentData.data, paymentsConstants.PAYMENT_GET_USECDFI_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentsConstants.PAYMENT_GET_USECDFI_FAILURE));
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const CancelSuscription = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_REQUEST));
    try {
      const paymentService = PaymentsService.getInstance();
      const paymentData = await paymentService.CancelSuscription(data);
      dispatch(success(paymentData.data, paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_FAILURE));
      console.log('error', error)
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const ResetCancelSubscripcion = () => {
  return {
    type: paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_RESET,
  };
};
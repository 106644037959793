import React, { useState } from "react";
import { Box, Tab as Tb, Tabs as Tbs, styled } from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ResetPasswordComponent from "./ressetPassword/ResetPasswordComponent";
import EditProfiel from "./editProfiel/EditProfiel";
import BackdropComponent from "../../components/BackDrop";
import { useLocation } from "react-router-dom";

const Tab = styled(Tb)(({ theme }) => ({
  borderRight: "1px solid #C20E30",
  minWidth: 200,
  "&.MuiTab-root": {
    padding: "0 5px",
    fontSize: 14,
    fontWeight: 500,
    color: "black",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.selectMenu,
    fontWeight: 700,
    color: theme.palette.primary.mainLyrics,
  },
}));
const Tabs = styled(Tbs)(({ theme }) => ({
  minHeight: 30,
  flexGrow: 1,
  "& .MuiTabs-flexContainer button": {
    minHeight: 30,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  display: "center",
  alignItems: "center",
  justifyContent: "center",
}));

export const ProfileTabs = () => {
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const valueInt = parseInt(tab, 2)
  const [value, setValue] = useState(valueInt);
  const [loading, setLoading] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <BackdropComponent loading={loading}/>
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="disabled tabs example"
        >
          <Tab label="Editar Perfil" icon={<AccountCircleOutlinedIcon />} iconPosition="start" />
          <Tab label="|** Cambiar contraseña" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <EditProfiel setLoading={setLoading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ResetPasswordComponent />
      </TabPanel>
    </Box>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
};

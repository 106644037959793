export const Button = (theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: '14px !important',
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedPrimary: {
          padding: "5px 45px",
          borderRadius: 25,
          "&.Mui-disabled": {
            backgroundColor: theme.palette.secondary.main40,
            color: "#FFF",
          },
        },
        containedSecondary: {
          padding: "5px 45px",
          borderRadius: 25,
          "&.Mui-disabled": {
            backgroundColor: theme.palette.secondary.main40,
            color: "#FFF",
          },
        },
        outlinedPrimary: {
          padding: "5px 45px",
          borderRadius: 25,
          "&.Mui-disabled": {
            borderColor: theme.palette.secondary.main40,
            color: theme.palette.secondary.main40,
          },
        },
        outlinedSecondary: {
          padding: "5px 45px",
          borderRadius: 25,
          "&.Mui-disabled": {
            borderColor: theme.palette.secondary.main40,
            color: theme.palette.secondary.main40,
          },
        },
        outlinedInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
};

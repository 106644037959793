export const fileFlowConstants = {
  FILE_FLOW_REQUEST: "FILE_FLOW_REQUEST",
  FILE_FLOW_SUCCESS: "FILE_FLOW_SUCCESS",
  FILE_FLOW_FAILURE: "FILE_FLOW_FAILURE",
  RESET_FLOW_REQUEST: 'RESET_FLOW_REQUEST',

  SET_FILE_FLOW: "SET_FILE_FLOW",
  APROBATE_DOCUMENT_REQUEST: "APROBATE_DOCUMENT_REQUEST",
  APROBATE_DOCUMENT_SUCCESS: "APROBATE_DOCUMENT_SUCCESS",
  APROBATE_DOCUMENT_FAILTURE: "APROBATE_DOCUMENT_FAILTURE",
  UPDATE_SECTION_REQUEST: "UPDATE_SECTION_REQUEST",
  UPDATE_SECTION_SUCCESS: "UPDATE_SECTION_SUCCESS",
  UPDATE_SECTION_FAILURE: "UPDATE_SECTION_FAILURE",

  UPLOAD_VIDEO_REQUEST: "UPLOAD_VIDEO_REQUEST",
  UPLOAD_VIDEO_SUCCESS: "UPLOAD_VIDEO_SUCCESS",
  UPLOAD_VIDEO_FAILURE: "UPLOAD_VIDEO_FAILURE",
  UPLOAD_VIDEO_RESET: "UPLOAD_VIDEO_RESET",
}; 
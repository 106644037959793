import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  "&.MuiDialogTitle-root": {
    fontSize: 20,
    color: theme.palette.primary.text,
    fontWeight: 300,
    lineHeight: "130%",
  },
  display: "inline-flex",
  alignItems: "center",
}));

export const CustomModalAlert = (props) => {
  return (
    <CustomDialog
      aria-labelledby="customized-dialog-title"
      open={props.Open}
      fullWidth={true}
    >
      <StyledDialogTitle sx={{ m: 0, p: 2, color: "", display:"flex", justifyContent:'center' }}>
       
          <Typography variant={'titulo'} sx={{fontWeight: 600, lineHeight: "37px",}}>
            {props.Title}
          </Typography>
      </StyledDialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>
          {props.SubTitle && (
            <Typography variant="subtitle" sx={{fontWeight: 400}} gutterBottom>
              {props.SubTitle}
            </Typography>
          )}
          {props.Comments && (
            <TextField
              autoFocus
              fullWidth
              placeholder={props.CommentPlaceHolder}
              type="text"
              multiline
              value={props.ValueComment}
              rows={4}
              variant="outlined"
              onChange={(event) => {
                props.setValueComment(event.target.value);
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ textAling: "center !important"}}>
        <Grid container spacing={2} justifyContent="center" sx={{p: 2}}>
          <Grid item xs={12} sm={6} >
            <Button
              fullWidth
              variant="outlined"
              sx={{ height: 38, minWidth: 250 }}
              onClick={props.LeftButtonAction}
            >
              {props.LeftButtonText}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Button
              fullWidth
              variant="contained"
              sx={{ height: 38, minWidth: 250 }}
              onClick={props.RightButtonAction}
              disabled={props.ValueComment === ""}
            >
              {props.RightButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};

import styled from "@emotion/styled";
import { Box, Button, Grid, Input, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncEffect } from "use-async-effect";
import Spinner from "../../../components/Spinner";
import VisorPDF from "../../../components/Viewer/VisorPDF";
import XMLViewer from "react-xml-viewer";
import HelpVideoXML from '../../../assets/video_xml.mp4'
import {
  verifySignFEAWithOutT,
} from "../../../store/actions";
import XmlDocumento from "./XML.js";
import nom from "./assets/nom.png";
import firmado from "./assets/firmado.png";
import xml from "./assets/xml.png";
import { FileService } from "../../../services/FileService";
import BackdropComponent from "../../../components/BackDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { SignatureService } from "../../../services/SignatureService";
import { Alert } from "../../../components/Alert";
import HelpPages from "../../../components/HelpPages";

const DropZoneContainer = styled(Box)(({ theme }) => ({
  minHeight: 400,
  width: "50%",
  border: `dashed 2px ${theme.palette.primary.main}`,
  borderRadius: 10,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "&:hover": {
    opacity: 0.9,
    cursor: "pointer",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default function DocumentUploadXML() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const questionId = query.get('uid');
  const [pdfFile, setpdfFile] = useState(null);
  const [urlPdf, setUrlPdf] = useState(null);
  const [nameDocument, setNameDocument] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [b64Xml, setb64Xml] = useState(null);
  const [activeDoc, setActiveDoc] = useState();
  const xmlData = useSelector((state) => state.FileFlow);

  const onDrop = (files) => {
    const extension = files[0]?.name?.slice((files[0]?.name.lastIndexOf(".") - 1 >>> 0) + 2)
    if (extension == 'xml' || extension == 'XML') {
      setpdfFile(files[0]);
    } else {
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        title: "Verifica tu documento",
        text: 'El archivo que ingresaste no es en formato .XML, verifica que el archivo que descargaste este descomprimido e ingresa el archivo en formato .xml',
      });
      return null
    }
  };

  const b64toUrl = async (base64Data) => {
    const r = await fetch(base64Data);
    const blob = await r.blob();
    const url = URL.createObjectURL(blob);
    return url;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useAsyncEffect(async () => {
    if (questionId) {
      const signatureService = SignatureService.getInstance();
      const resXML = await signatureService.downloadDocumentXmlByUid({ Body: questionId });
      dispatch(
        verifySignFEAWithOutT({
          XmlB64: resXML.Body.B64Content,
          NombreDocumento: resXML.Body.FileName?.split(".")[0],
          Extension: 'xml',
        })
      );
    } else if (pdfFile) {
      const fileConverted = await toBase64(pdfFile);
      const base64result = fileConverted.split(",")[1];
      dispatch(
        verifySignFEAWithOutT({
          XmlB64: base64result,
          NombreDocumento: pdfFile?.name.split(".")[0],
          Extension: pdfFile?.type.split("/")[1],
        })
      );
    } else {
      if (sessionStorage.token) {
        setpdfFile(null);
        setUrlPdf(null);
        dispatch(
          verifySignFEAWithOutT("", true)
        );
      }
    }
  }, [pdfFile, questionId]);


  useEffect(() => {
    console.log('xmlData?.file_flow', xmlData?.file_flow)
    if (xmlData?.file_flow !== undefined && xmlData?.file_flow !== null && xmlData?.file_flow !== "") {
      console.log('DataXml', xmlData?.file_flow)
      if (xmlData?.file_flow.verificationResults.VerifyXml == true) {
        traerDocumento("Expediente Digital");
        setLoadingDocument(false);
      } else {
        Alert({
          icon: "warning",
          okbtntext: "Aceptar",
          title: "Verifica tu documento",
          text: 'El archivo que ingresaste ya no es un archivo válido.',
        });
        regresarDatos()
        setLoadingDocument(false);
      }
    } else if (xmlData.error !== undefined && xmlData.error !== null && xmlData.error !== "") {
      console.log('DataXml', xmlData)
      Alert({
        icon: "warning",
        okbtntext: "Aceptar",
        title: "Verifica tu documento",
        text: xmlData.error,
      });
      regresarDatos()
      setLoadingDocument(false);
    }
  }, [xmlData])

  const xmlToPdf = async (b64Document) => {
    const documentPdfB64 = `data:application/pdf;base64,${b64Document}`;
    const pdfUrl = await b64toUrl(documentPdfB64);
    setUrlPdf(pdfUrl);
  };

  const regresarDatos = () => {
    navigate(`/inbox/verify-xml`);
    dispatch(
      verifySignFEAWithOutT("", true)
    );
    setpdfFile(null);
    setUrlPdf(null);
  };

  const traerDocumento = async (name) => {
    setActiveDoc(name);
    setLoadingDocument(true);
    const documentSelect = xmlData?.file_flow?.DocumentsGenerated?.filter(
      (doc) => doc.DocumentType_Name === name
    );
    if (documentSelect?.length === 0) {
      return null;
    }
    const fileService = FileService.getInstance();
    const documentData = await fileService.getDownloadDocumentWithOuthT({
      document_Id: documentSelect[0]?.Document_Id,
      ConvertSfdt: false,
    });

    if (documentData.Body) {
      if (name === "Xml Valid Firma-Electrónica") {
        let b = Buffer.from(documentData.Body.B64Content, "base64");
        setNameDocument(documentData.Body.FileName);
        let s = b.toString();
        setb64Xml(s);
        setLoadingDocument(false);
      } else {
        if (name === 'Expediente Digital') {
          let extension = documentData.Body.FileName.split(".")
          setNameDocument(`${extension[0]} - Firmado`);
        } else {
          setNameDocument(documentData.Body.FileName);
        }
        setb64Xml(null);
        xmlToPdf(documentData.Body.B64Content);
        setLoadingDocument(false);
      }
    } else {
      setLoadingDocument(false);
    }
    setLoadingDocument(false);
  };

  useEffect(() => {
    dispatch(
      verifySignFEAWithOutT("", true)
    );
  }, [])

  const active = (item) => {
    return activeDoc === item;
  };

  return (
    <Fragment>
      <HelpPages
        Body={
          <Box sx={{ mt: 2 }}>
            <video
              width={"100%"}
              className="HelpVideo"
              src={HelpVideoXML}
              autoPlay
              loop
              muted
            />
          </Box>
        }
      />
      <BackdropComponent loading={loadingDocument} />
      <Grid
        container
        sx={{
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <Grid item xs={12} md={9}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
            <Typography
              variant="subtitle"
              sx={{ mb: 1 }}
            >
              Carga el archivo en formato <b>.XML</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          {pdfFile || questionId ? (
            <Box>
              <Button
                color="primary"
                variant="contained"
                sx={{ minWidth: 255 }}
                onClick={() => regresarDatos()}
              >
                <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
                  Regresar
                </Typography>
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {pdfFile || questionId ? (
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Box sx={{ textAlign: "left", ml: 1, mt: 2 }}>
            <Button
              onClick={() => traerDocumento("Expediente Digital")}
              sx={(theme) => ({
                backgroundColor: active("Expediente Digital")
                  ? theme.palette.primary.main40
                  : "#FFFFFF",
              })}
            >
              <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                <img src={firmado} alt="imagenNom" style={{ maxWidth: 25 }} />
                <Typography sx={{ ml: 1, color: "black" }} variant="body1">
                  Archivo firmado
                </Typography>
              </Box>
            </Button>
            <Button
              onClick={() =>
                traerDocumento("Xml Valid Firma-Electrónica")
              }
              sx={(theme) => ({
                backgroundColor: active("Xml Valid Firma-Electrónica")
                  ? theme.palette.primary.main40
                  : "#FFFFFF",
              })}
            >
              <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                <img src={xml} alt="imagenNom" style={{ maxWidth: 25 }} />
                <Typography sx={{ ml: 1, color: "black" }} variant="body1">
                  XML
                </Typography>
              </Box>
            </Button>
            {xmlData?.file_flow?.DetalleNom151 !== null ? (
              <Button
                onClick={() => traerDocumento("Expediente Digital - Nom151")}
                sx={(theme) => ({
                  backgroundColor: active("Expediente Digital - Nom151")
                    ? theme.palette.primary.main40
                    : "#FFFFFF",
                })}
              >
                <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                  <img src={nom} alt="imagenNom" style={{ maxWidth: 25 }} />
                  <Typography sx={{ ml: 1, color: "black" }} variant="body1">
                    NOM 151
                  </Typography>
                </Box>
              </Button>
            ) : null}
          </Box>
        </Grid>
      ) : null}
      <Grid>
        {pdfFile || questionId ? (
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box >
                {urlPdf ? (
                  b64Xml ? (
                    <Box sx={{ width: '100%', mt: 1 }}>
                      <Box sx={{ mb: 1, wordWrap: "break-word" }}>
                        <Typography variant='body2'>
                          <b>
                            {nameDocument ?? ""}
                          </b>
                        </Typography>
                      </Box>
                      <Box overflow={"auto"} sx={{ wordWrap: "break-word", width: "100%", height: 690 }}>
                        <XMLViewer height={500} xml={b64Xml} />
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ p: .5 }}>
                      <VisorPDF pdfUrl={urlPdf} pdfName={nameDocument ?? ""} />
                    </Box>
                  )
                ) : (
                  <Spinner />
                )}
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <XmlDocumento xmlData={xmlData?.file_flow} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
            <DropZoneContainer {...getRootProps()}>
              <Input inputProps={getInputProps()} sx={{ display: "none" }} />

              <Typography
                sx={{ fontWeight: 400, display: { xs: 'none', md: 'block' } }}
                align="center"
                variant="subtitle1"
              >
                Arrastra el archivo .XML para validar <br /> el firmado de tu documento
              </Typography>
              <Box sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{ fontWeight: 400, display: { xs: 'none', md: 'block' } }}
                  align="center"
                  variant="subtitle1"
                >
                  o
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={open}
                  variant="contained"
                  sx={{
                    maxWidth: 280,
                    whiteSpace: "nowrap",
                    height: 38,
                    m: "10px auto",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 400, letterSpacing: 1.2 }}
                    align="center"
                    variant="body1"
                  >
                    Selecciónalo desde el equipo
                  </Typography>
                </Button>
              </Box>
            </DropZoneContainer>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
}

import { Box, Tab as Tb, styled, Grid, Tabs, Typography, useMediaQuery, useTheme,
  Pagination as Pag,
  PaginationItem,

 } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import React, { useState } from "react";
import { useEffect } from "react";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { Icon } from "@iconify/react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Pagination = styled(Pag)(({ theme }) => ({
  "& ul": { justifyContent: "flex-end" },
  "& ul li button": { fontSize: "14px" },
  "& .Mui-selected": {
    backgroundColor: (theme) => `${theme.palette.secondary.main40} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    "& ul": { justifyContent: "center" },
  },
}));

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: 30,
    flexGrow: 1,
    "& .MuiTabs-flexContainer button": {
      minHeight: 25,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Tab = styled(Tb)(({ theme }) => ({
  minWidth: 105,
  borderBottom: `1px solid #767D89`,
  "&.MuiTab-root": {
    padding: "0 5px",
    fontSize: 14,
    fontWeight: 500,
    color: "black",
  },
  "&.Mui-selected": {
    // backgroundColor: theme.palette.primary.selectMenu,
    borderBottom: `3px solid ${theme.palette.primary.selectMenu}`,
    fontWeight: 700,
    // color: theme.palette.primary.mainLyrics,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 5,
  marginBottom: 1,
  borderBottom: "1px solid #D9D9D9",
  display: "flex",
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "60%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
}));

export default function InboxResponsive(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [newData, setNewData] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setNewData(props.data);
  }, [props.data]);

  const filterData = (stage) => {
    const dataFilter = props.data?.filter(
      (item) => item.statusVigencia === stage
    );
    setNewData(dataFilter);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid
          item
          lg={12}
          xs={12}
          sx={{
            display: { xs: "flex", md: "center" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="disabled tabs example"
          >
            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("EnTiempo")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#6DB625" }}
                  />
                  Activos
                </Box>
              }
            />
            <Tab
              sx={{
                borderRight: `1px solid #767D89`,
                borderLeft: `1px solid #767D89`,
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("PorVencer")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#FBBF28" }}
                  />
                  Por vencer
                </Box>
              }
            />
            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("Vencido")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#C20E30" }}
                  />
                  Vencidos
                </Box>
              }
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        {newData?.map((item, index) => (
          <CardResponsiveFile key={index} data={item} fileFlow={props.fileFlow} />
        ))}
      </Grid>
      <Grid>
      <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              px: { xs: 0, sm: 1 },
              mt: 2,
            }}
          >
            <Box sx={{ mr: 5 }}>
              <Typography variant="body1">
                Total de solicitudes: {props.TotalRows}
              </Typography>
            </Box>

            <Pagination
              shape="rounded"
              count={props.TotalPages}
              page={props.PageCurrent}
              // onChange={handleChangePage}
              onChange={(event, page) => {
                props.onClickChangePage(event, page);
              }}
              siblingCount={xsScreen ? 0 : 1}
              boundaryCount={xsScreen ? 0 : 1}
              size={xsScreen ? "small" : "medium"}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: PreviusPag, next: NextPag }}
                  {...item}
                />
              )}
            />
          </Box>
      </Grid>
    </Box>
  );
}

const CardResponsiveFile = (props) => {
  const colorReturn = () => {
    if (props.data.statusVigencia === "EnTiempo") {
      return "#6DB625";
    } else if (props.data.statusVigencia === "PorVencer") {
      return "#ffdb7e";
    } else {
      return "#C20E30";
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          p: 1,
          m: 0.8,
          border: 1.5,
          borderColor: "#D9D9D9",
          borderRadius: 3,
        }}
        onClick={() => props.fileFlow(props.data)}
      >
        <Box>
          <Typography variant="h6" sx={{ color: colorReturn() }}>
            Folio: {props.data?.invoice}
          </Typography>
        </Box>
        <Statements title={"Nombre documental"} text={props.data.product} />
        <Statements
          title={"Nombre del solicitante"}
          text={props.data.applicant}
        />
        <Statements
          title={"Fecha de creación"}
          text={props.data.creationDate}
        />
        <Statements title={"Fecha límite"} text={props.data.validity} />
        <Statements title={"Estatus"} text={props.data.status} />
        <BoxContainer>
          <BoxTitulo>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Tipo de solicitud
            </Typography>
          </BoxTitulo>
          <Box sx={{display:"flex", justifyContent:"right", mr: 1}}>
            <Typography variant="subtitle1" textAlign={"right"}>
              {props.data.TypeSolicitud === true ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <WhiteTooltip
                    arrow
                    enterTouchDelay={0}
                    placement="top"
                    title={"Solicitud Masivo"}
                  >
                    <Icon color={props.data.color} icon="heroicons:users" fontSize={25} />
                  </WhiteTooltip>
                </Box>
              )
                :
                (<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <WhiteTooltip
                    arrow
                    enterTouchDelay={0}
                    placement="top"
                    title={"Solicitud Individual"}
                  >
                    <Icon color={props.data.color} icon="uil:user" fontSize={25} />
                  </WhiteTooltip>
                </Box>)
              }
            </Typography>
          </Box>
        </BoxContainer>
      </Box>
    </Grid>
  );
};


const PreviusPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ mt: 0.5 }}>
        <ArrowBackIos sx={{ fontSize: "14px" }} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Anterior</Box>
    </Box>
  );
};

const NextPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Siguiente</Box>
      <Box sx={{ mt: 0.5 }}>
        <ArrowForwardIos sx={{ fontSize: "14px" }} />
      </Box>
    </Box>
  );
};

const Statements = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle1" textAlign={"right"}>
          {props.text}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};

import {
  autocompleteClasses,
  Box,
  Button,
  Grid,
  Paper,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UseImage } from "../../components/UseImage";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import { CustomButton } from "../../components/CustomButton";
import PropTypes from 'prop-types';
import {
  setTitleGral,
  getPlansService,
  GetDataPlanClient,
  editStack,
  resetFile,
  resetDocumentEnlace,
  resetDocument64,
  SeletedPlanUser,
  ResetProjectClientPlan,
  ResetStripeKey,
  CancelSuscription,
  ResetCancelSubscripcion,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BackdropComponent from "../../components/BackDrop";
import { numeroFormateado, UTCtoLocal } from "../../utils";
import moment from "moment";
import { Alert, AlertConfirm } from "../../components/Alert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CardCustom = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  minHeight: "300px", // Alto mínimo
  height: "530px", // Alto máximo
  background: "#FFFFFF",
  border: "1px solid #E0E0E0",
  boShadow: "0px 6px 31px rgba(0, 0, 0, 0.09)",
  borderRadius: "12px",
  padding: "1.5em",
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

export default function PaymentsPlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewPlans, setViewPlans] = useState(false);
  const [typePlans, setTypePlans] = useState([]);

  const [creditosOff, setCreditosOff] = useState();
  const useClient = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Email
  );
  let roles = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles
  );
  const dataClient = useSelector((state) => state?.DataPaymentClient);
  const dataCancel = useSelector((state) => state?.CancelSuscription);
  const dataPlans = useSelector((state) => state?.GetPlansPayment?.plans);

  let fecha2 = moment(
    dataClient?.items?.projects[0]?.services[0]?.plan?.transactionLimitDate
  );
  let fechaActual = moment();
  let caducidad = fecha2.isSameOrAfter(fechaActual, "day");

  let handleBuyClick = (item) => {
    dispatch(SeletedPlanUser(item));
    dispatch(ResetProjectClientPlan());
    dispatch(ResetStripeKey());
    navigate("/inbox/BuySummary");
  };

  let viewPlansBuy = () => {
    setViewPlans(!viewPlans);
  };

  useEffect(() => {
    dispatch(setTitleGral("Planes"));
    dispatch(
      getPlansService({
        referenceData: "",
        productId: 4,
      })
    );

    dispatch(
      GetDataPlanClient({
        referenceData: "",
        clientId: null,
        email: useClient,
      })
    );
  }, []);

  useEffect(() => {
    if (dataClient?.items) {
      setCreditosOff(
        dataClient?.items?.projects[0]?.services[0]?.plan?.maxTrnsactions ===
          dataClient?.items?.projects[0]?.services[0]?.plan?.transactionCount
      );
    }
  }, [dataClient]);

  useEffect(() => {
    if (dataPlans !== null || dataPlans !== undefined) {
      const newPlans = dataPlans?.plans.filter(
        (item) => item.id != 1 && item.id != 5
      );
      setTypePlans(newPlans);
    }
  }, [dataPlans]);

  useEffect(() => {
    if (dataCancel.items !== undefined && dataCancel.items !== null) {
      if (dataCancel.items.responseCode == 0) {
        Alert(
          {
            icon: "success",
            text: "Su suscripción se ha cancelado",
            okbtntext: "Aceptar",
          },
          () => {
            dispatch(
              GetDataPlanClient({
                referenceData: "",
                clientId: null,
                email: useClient,
              })
            );
            dispatch(ResetCancelSubscripcion());
          }
        );
      } else if (dataCancel.items.responseCode == 1) {
        Alert(
          {
            icon: "error",
            text: dataCancel.items.responseCodeMessage,
            okbtntext: "Aceptar",
          },
          () => {
            dispatch(
              GetDataPlanClient({
                referenceData: "",
                clientId: null,
                email: useClient,
              })
            );
            dispatch(ResetCancelSubscripcion());
          }
        );
      }
    }
  }, [dataCancel.loading, dataCancel.items]);

  return (
    <Grid container>
      <BackdropComponent loading={dataClient.loading || dataCancel.loading} />
      {dataClient.items?.projects[0]?.services[0]?.plan?.periodicity ===
        "Gratuito" &&
      (creditosOff || caducidad === false) ? (
        <ExpiredPlan DataClient={dataClient.items} />
      ) : dataClient.items !== null ? (
        <PlanProgress
          DataClient={dataClient.items}
          ViewPlansBuy={viewPlansBuy}
          creditosOff={creditosOff}
          caducidad={caducidad}
        />
      ) : null}

      {viewPlans ||
      ((caducidad === false || creditosOff) &&
        dataClient?.items?.projects[0]?.services[0]?.plan?.name ===
          "Prueba Gratuita") ||
      dataClient?.items?.isCancelled ||
      roles.length === 0 ? (
        <>
          <Grid container justifyContent={"center"} alignItems={"items"}>
            {typePlans?.map((item) =>
              item.id != 6 && item.id != 7 ? (
                <Grid item lg={4} mb={4} xs={12} sx={{ p: 1 }}>
                  <CardPersonalizePlan
                    Title={item.name}
                    Price={item.price}
                    SubTitle={item.description}
                    Periodicity={item.periodicity}
                    ButtonTitle="Comprar ahora"
                    Data={item}
                    Options={JSON.parse(item.descriptionFormat)}
                    ClickEvent={handleBuyClick}
                  />
                </Grid>
              ) : null
            )}
          </Grid>
        </>
      ) : creditosOff && caducidad ? (
        <Grid container justifyContent={"center"} alignItems={"items"}>
          {typePlans?.map((item) => (
            <Grid item lg={4} mb={4} xs={12} sx={{ p: 1 }}>
              <CardPersonalizePlan
                Title={item.name}
                Price={item.price}
                SubTitle={item.description}
                Periodicity={item.periodicity}
                ButtonTitle="Comprar ahora"
                Data={item}
                Options={JSON.parse(item.descriptionFormat)}
                ClickEvent={handleBuyClick}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
}

const PlanProgress = ({ DataClient, ViewPlansBuy }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nuevaSolicitud = () => {
    dispatch(resetFile());
    dispatch(resetDocumentEnlace());
    dispatch(resetDocument64());
    sessionStorage.removeItem("ctrlExpediente");
    sessionStorage.removeItem("ctrlPreFirmado");
    sessionStorage.removeItem("FileId");
    dispatch(editStack(false));
    navigate("/inbox/new-file");
  };

  const cancelarPlan = () => {
    AlertConfirm(
      {
        icon: "warning",
        title: "Tu suscripción será cancelada",
        text: "¿Estás seguro que deseas cancelar tu suscripción?",
        okbtntext: "Aceptar",
        cancelButtonText: "Cancelar",
      },
      (result) => {
        if (result.isConfirmed) {
          dispatch(
            CancelSuscription({
              referenceData: "",
              clientId: DataClient.client_Id,
              planId: DataClient?.projects[0]?.services[0]?.plan?.id,
            })
          );
        } else if (result.dismiss === "backdrop" && result.isDismissed) {
        }
      }
    );
  };

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid xs={12} md={6} lg={3} sx={{ m: 3 }}>
        <Paper elevation={3} sx={{ p: 2, height: 370 }}>
          <Box>
            <Typography variant="titulo">
              {DataClient?.projects[0]?.services[0]?.plan?.name}
            </Typography>
          </Box>
          <Box sx={{ p: 1, alignItems: "center", justifyContent: "center" }}>
            <UseImage
              type="Image"
              className="swal2-icon-content-img"
              src="Separator.svg"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h2" sx={{ fontWeight: 480 }}>
                {DataClient?.projects[0]?.services[0]?.plan?.maxTrnsactions -
                  DataClient?.projects[0]?.services[0]?.plan?.transactionCount}
              </Typography>
              <br />
              <Typography variant="caption">Disponibles</Typography>
            </Box>
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <UseImage
                type="Image"
                className="swal2-icon-content-img"
                src="SeparatorRed.svg"
              />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h2" sx={{ fontWeight: 480 }}>
                {DataClient?.projects[0]?.services[0]?.plan?.transactionCount}
              </Typography>
              <br />
              <Typography variant="caption">Utilizados</Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption">
              Fecha de vencimiento <br />
              {UTCtoLocal(
                DataClient?.projects[0]?.services[0]?.plan?.transactionLimitDate
              )}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => nuevaSolicitud()}
            sx={{ marginTop: "1.5em", marginBottom: "1.5em" }}
          >
            Crear nueva solicitud
          </CustomButton>
        </Paper>
      </Grid>
      <Grid xs={12} md={6} lg={3} sx={{ m: 3 }}>
        <Paper elevation={3} sx={{ p: 2, height: 370 }}>
          <Box>
            <Typography variant="titulo">
              {DataClient?.projects[0]?.services[0]?.plan?.periodicity ===
              "Gratuito"
                ? " Incluido en tu prueba"
                : "Incluido en tu plan"}
            </Typography>
          </Box>
          <Box sx={{ p: 1, alignItems: "center", justifyContent: "center" }}>
            <UseImage
              type="Image"
              className="swal2-icon-content-img"
              src="Separator.svg"
            />
          </Box>
          <Grid
            container
            sx={{ padding: "0.1em", overflow: "auto", minHeight: 280 }}
          >
            <Grid item xs={14} sx={{ paddingLeft: "1em" }}>
              <ul style={{ padding: "0 1em" }}>
                {DataClient.projects.length !== 0
                  ? JSON.parse(
                      DataClient?.projects[0]?.services[0]?.plan
                        ?.descriptionFormat
                    )?.map((item) => {
                      return (
                        <li style={{ padding: "0.2em 0" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginTop: "0.5em" }}
                          >
                            {item}
                          </Typography>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid xs={12} md={6} lg={3} sx={{ m: 3 }}>
        <Paper elevation={3} sx={{ p: 2, height: 370 }}>
          <Box>
            <Typography variant="titulo">
              {DataClient?.projects[0]?.services[0]?.plan?.periodicity ===
              "Gratuito"
                ? "Planes de pago"
                : "Facturación y Pago"}
            </Typography>
          </Box>
          <Box sx={{ p: 1, alignItems: "center", justifyContent: "center" }}>
            <UseImage
              type="Image"
              className="swal2-icon-content-img"
              src="Separator.svg"
            />
          </Box>
          <Box sx={{ textAlign: "justify", mt: 3 }}>
            {DataClient?.projects[0]?.services[0]?.plan?.periodicity ===
            "Gratuito" ? (
              <Box sx={{ height: 150 }}>
                <Typography variant="caption">
                  Por el momento no cuentas con un plan contratado, puedes
                  adquirirlo eligiendo un plan.
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: 150 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Icon fontSize={24} icon="ic:outline-credit-card" />
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    Visa que termina en **{DataClient?.cardData?.cardNumber}
                  </Typography>
                </Box>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Icon
                    fontSize={50}
                    icon="material-symbols:calendar-month-outline"
                  />
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    {numeroFormateado(
                      DataClient?.projects[0]?.services[0]?.plan?.price
                    )}{" "}
                    MXN <span style={{ color: "#C20E30" }}>+ IVA </span>/{" "}
                    {DataClient?.projects[0]?.services[0]?.plan?.periodicity}
                    <br />
                    {DataClient?.isCancelled == true ? null : (
                      <>
                        El cargo de renovación automática se realizará el
                        próximo:{" "}
                        {UTCtoLocal(
                          DataClient?.projects[0]?.services[0]?.plan
                            ?.transactionLimitDate
                        )}
                      </>
                    )}
                    <Tip
                      title={
                        "El plan contratado se puede cancelar en cualquier momento. Para evitar la renovación automática, es necesario cancelar por lo menos 1 día antes de tu fecha de vigencia de acuerdo con los Términos y Condiciones de uso. Si cancelas tu plan, podrás ingresar a SoluSign y tu información estará disponible para consulta durante 30 días naturales después de la fecha de vigencia, posterior a esa fecha tu usuario se desactivará y no será posible recuperar tu información."
                      }
                      placement="top"
                      arrow
                      enterTouchDelay={0}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  </Typography>
                </Box>
                {DataClient?.isCancelled == true ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#C20E30",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 600,
                      }}
                    >
                      Suscripción cancelada
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
          <Box>
            {console.log("DataClient", DataClient)}
            {DataClient?.projects[0]?.services[0]?.plan?.periodicity ===
              "Gratuito" ? (
              <CustomButton
                sx={{ marginTop: "1.8em" }}
                onClick={() => ViewPlansBuy()}
              >
                Elegir plan
              </CustomButton>
            ) : (
              <Box>
                <CustomButton
                  onClick={() => navigate("/inbox/billings")}
                  sx={{ marginTop: "1.8em" }}
                >
                  Consultar historial de pago
                </CustomButton>
                {DataClient.isCancelled == true ? null : (
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => cancelarPlan()}
                    sx={{ height: 38, mt: 1, fontSize: "16px!important" }}
                  >
                    Cancelar plan
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const ExpiredPlan = ({ DataClient }) => {
  console.log("DATA***", DataClient);
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid xs={12} md={6} lg={5} sx={{ m: 3 }}>
        <Paper elevation={3} sx={{ p: 2, minHeight: 250 }}>
          <Box>
            <Typography
              variant="titulo"
              sx={{
                color: "#C20E30",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {DataClient?.projects[0]?.services[0]?.plan?.name} Vencida
            </Typography>
          </Box>
          {DataClient?.isCancelled == true ? (
            <Box>
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Suscripción cancelada
              </Typography>
            </Box>
          ) : null}
          <Box sx={{ p: 1, alignItems: "center", justifyContent: "center" }}>
            <UseImage
              type="Image"
              width="100%"
              className="swal2-icon-content-img"
              src="Separator.svg"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h2"
                sx={{ fontWeight: 480, color: "#C20E30" }}
              >
                {DataClient?.projects[0]?.services[0]?.plan?.maxTrnsactions -
                  DataClient?.projects[0]?.services[0]?.plan?.transactionCount}
              </Typography>
              <br />
              <Typography variant="caption">Disponibles</Typography>
            </Box>
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <UseImage
                type="Image"
                width="100%"
                className="swal2-icon-content-img"
                src="SeparatorRed.svg"
              />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h2" sx={{ fontWeight: 480 }}>
                {DataClient?.projects[0]?.services[0]?.plan?.transactionCount}
              </Typography>
              <br />
              <Typography variant="caption">Utilizados</Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid xs={12} md={6} lg={5} sx={{ m: 3 }}>
        <Paper elevation={3} sx={{ p: 2, minHeight: 250 }}>
          <Box sx={{ textAlign: "justify", mt: 3 }}>
            <Typography variant="subtitle1">
              Si no contratas un plan, podrás ingresar a SoluSign y tu
              información estará disponible para consulta durante 15 días
              naturales después de la fecha de vigencia de tu prueba gratuita,
              posterior a esa fecha tu usuario se desactivará y no será posible
              recuperar tu información.
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};


PaymentsPlans.propTypes = {
  DataClient: PropTypes.shape({
    isCancelled: PropTypes.bool,
  }),
};

const CardPersonalizePlan = (props) => {
  return (
    <Paper elevation={4} sx={{ borderRadius: "12px" }}>
      <CardCustom>
        <Box>
          <Typography variant="titulo">{props.Title}</Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ marginTop: "0.5em" }}>
          {props.SubTitle}
        </Typography>
        <CustomButton
          onClick={() => props.ClickEvent(props.Data)}
          sx={{ marginTop: "1.5em", marginBottom: "1.5em" }}
        >
          {props.ButtonTitle}
        </CustomButton>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="titulo">
            {numeroFormateado(props.Price)}/MXN
          </Typography>
          <Typography variant="subtitle1" color="#C20E30" sx={{ ml: 1 }}>
            {props.Data.periodicity} + IVA
          </Typography>
        </Box>
        {props.Data.id == 2 ? (
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">
              {numeroFormateado(1750)}/MXN
            </Typography>
            <Typography variant="subtitle1" color="#C20E30" sx={{ ml: 1 }}>
              Anual + IVA
            </Typography>
          </Box>
        ) : null}

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Incluye:</Typography>
          <Grid
            container
            sx={{ padding: "0.5em", overflow: "auto", maxHeight: 200 }}
          >
            <Grid item xs={14} sx={{ paddingLeft: "1em" }}>
              <ul style={{ padding: "0 1em" }}>
                {props.Options?.map((item) => {
                  return (
                    <li style={{ padding: "0.2em 0" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginTop: "0.5em" }}
                      >
                        {item}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </Box>
      </CardCustom>
    </Paper>
  );
};
import React from "react";
import {
  Tooltip,
  MenuItem,
  tooltipClasses,
  TextField as InputText,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Typography,
  autocompleteClasses,
  Radio as Rd,
  RadioGroup,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Parser from "html-react-parser";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import { UTCtoLocal } from "../utils";
import { Icon } from "@iconify/react/dist/iconify.js";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { fontSize: 14 },
  display: "inline-flex",
  alignItems: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "140%",
  fontFamily: '"Montserrat",sans-serif',
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "144%",
  },
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: "4px 0",
  paddingLeft: theme.spacing(1),
}));

const Radio = styled(Rd)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      fontWeight: 650,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 15,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontWeight: 650,
      borderRadius: 5,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 650,
      fontSize: "12px !important",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#C20E30 !important",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#C20E30 !important", // Cambia el color de error aquí
  },
  "& .MuiSelect-select": {
    height: 25,
    // color: "#C20E30 !important",
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 650,
        fontSize: "10px",
      },
    },
  },
}));

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "2.5px 5px",
  },
  "& ~ .MuiAutocomplete-popper .MuiAutocomplete-noOptions": {
    fontSize: 14,
  },
}));

const useStylesPhone2 = makeStyles({
  inputBase: {
    width: "100% !important",
    color: "inherit",
  },
});

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPhone =
  "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}";
const regexCURP =
  /^([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)$/i;

export const CustomInputField = (props) => {
  const theme = useTheme();
  const classesPhone = useStylesPhone2();
  const formatDefaultDate = new Date();

  const {
    inputType,
    iconSpam,
    defaultValue,
    functionClick,
    label,
    multiline,
    minRows,
    sxLabel,
    sxInput,
    optionsList,
    tooltipText,
    control,
    name,
    validations,
    icon,
    posIcon,
    onEnter,
    placeholder,
    onSelect,
    disabled,
    loading,
    onInputChange,
    open,
    onOpen,
    onClose,
    error,
    helperText,
    isPassword,
    radioValues,
    minDate,
    maxDate,
    required,
    register,
    regexData,
    handleDeleteOption,
    optionDelete = false,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case "select":
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue ?? ""}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                error={error}
                helperText={helperText}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                name={name}
                fullWidth
                select
                inputRef={ref}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                onChange={(e) => {
                  onChange(e);
                  onSelect && onSelect(e);
                }}
                inputProps={{ displayEmpty: true, shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    height: 26,
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem disabled value="">
                  <spam>{placeholder}</spam>
                </MenuItem>
                {optionsList.map((opt, i) => (
                  <MenuItem
                    key={`${opt?.Name}${i}`}
                    value={opt?.Value}
                    placeholder="Seleccione"
                    selected
                    sx={{ color: "black" }}
                  >
                    {opt.Name?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        );
      case "check":
        return (
          <ControlLabel
            sx={{
              fontSize: "14px !important",
              mr: 1,
              my: "0px",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
            }}
            name={name}
            disabled={disabled}
            control={
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={validations}
                render={({
                  field: { ref, value, onChange, name, ...restFields },
                }) => (
                  <Box sx={{ mt: 0.4 }}>
                    <Check
                      {...restFields}
                      name={name}
                      inputRef={ref}
                      checked={value ?? defaultValue}
                      disabled={disabled}
                      required={required}
                      onChange={(e) => {
                        onChange(e);
                        onSelect && onSelect(e);
                      }}
                      size="small"
                    />
                  </Box>
                )}
              />
            }
            label={
              <Box
                color={error ? "#E60D33" : ""}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 1,
                  mt: 0.4,
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: '"Montserrat",sans-serif',
                  [theme.breakpoints.down("sm")]: {
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "144%",
                  },
                }}
              >
                {Parser(label)}
                {tooltipText && (
                  <Tip
                    title={tooltipText}
                    placement="top"
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                  </Tip>
                )}
              </Box>
            }
          />
        );
      case "radio":
        return (
          <ControlLabel
            sx={{
              fontSize: "14px !important",
              m: "0",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            disabled={disabled}
            control={
              <Controller
                defaultValue={null}
                control={control}
                name={name}
                rules={validations}
                disabled={disabled}
                render={({ field }) => (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "144%",
                        },
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      {label ? (
                        <>
                          {label}
                          {""}
                          {required ? (
                            <spam
                              style={{
                                color: error ? "#C20E30" : "inherit",
                                marginLeft: 3,
                              }}
                            >
                              *
                            </spam>
                          ) : null}
                        </>
                      ) : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>

                    <RadioGroup {...field}>
                      {radioValues.map((rv) => (
                        <FormControlLabel
                          key={rv.label + rv.value}
                          onClick={functionClick}
                          disabled={disabled}
                          value={rv.value}
                          control={
                            <Radio
                              // required={required}
                              disabled={disabled}
                              onChange={(e) => {
                                onSelect && onSelect(e, rv.Value);
                              }}
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <Box
                              color={error ? "#E60D33" : ""}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                                fontFamily: '"Montserrat",sans-serif',
                                [theme.breakpoints.down("sm")]: {
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "144%",
                                },
                              }}
                            >
                              {rv.label}
                              {required ? (
                                <spam
                                  style={{
                                    color: error ? "#C20E30" : "inherit",
                                    fontWeight: 600,
                                    marginLeft: 3,
                                  }}
                                >
                                  *
                                </spam>
                              ) : null}
                              {rv.tooltipText && (
                                <Tip
                                  title={rv.tooltipText}
                                  placement="top"
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <InfoOutlinedIcon
                                    sx={{ ml: 1, fontSize: 15 }}
                                  />
                                </Tip>
                              )}
                            </Box>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              />
            }
          />
        );
      case "date":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue ?? formatDefaultDate}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "140%",
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "144%",
                    },
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {label !== undefined && label !== null && label !== "" ? (
                    <>
                      {label}{" "}
                      {required ? <spam style={{ marginLeft: 3 }}>*</spam> : ""}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </>
                  ) : null}
                </Box>
                <DatePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  inputFormat="dd/MM/yyyy"
                  inputProps={{ placeholder: "dd/mm/aaaa" }}
                  components={{
                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                  }}
                  {...restFields}
                  onChange={(e) => {
                    if (e) {
                      const d = new Date();
                      d.setFullYear(e.getFullYear(), e.getMonth(), e.getDate());
                      d.setHours(0);
                      d.setMinutes(0);
                      d.setSeconds(0);
                    }
                    console.log("formatoHora", UTCtoLocal(e, false));
                    onChange(e);
                    onSelect && onSelect(e);
                  }}
                  inputRef={ref}
                  disabled={disabled}
                  renderInput={(params) => {
                    return (
                      <InputText
                        onKeyUp={onEnter}
                        {...params}
                        error={error}
                        fullWidth
                        sx={{ ...(sxInput ?? {}) }}
                        InputLabelProps={{ sx: sxLabel, style: {
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        } }}
                      />
                    );
                  }}
                />
              </Box>
            )}
          />
        );
      case "dateTimer":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue ?? formatDefaultDate}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "140%",
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "144%",
                    },
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {label !== undefined && label !== null && label !== "" ? (
                    <>
                      {label}{" "}
                      {required ? <spam style={{ marginLeft: 3 }}>*</spam> : ""}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </>
                  ) : null}
                </Box>
                <DateTimePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  inputFormat="dd/MM/yyyy hh:mm a" // Formato de fecha y hora
                  inputProps={{ placeholder: "dd/mm/aaaa hh:mm AM/PM" }}
                  components={{
                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                  }}
                  ampm // Habilitar el formato AM/PM
                  {...restFields}
                  onChange={(e) => {
                    if (e) {
                      const d = new Date();
                      d.setFullYear(e.getFullYear(), e.getMonth(), e.getDate());
                      d.setHours(0);
                      d.setMinutes(0);
                      d.setSeconds(0);
                    }
                    console.log("formatoHora", UTCtoLocal(e, true));
                    onChange(e);
                    onSelect && onSelect(e);
                  }}
                  inputRef={ref}
                  disabled={disabled}
                  renderInput={(params) => {
                    return (
                      <InputText
                        onKeyUp={onEnter}
                        {...params}
                        error={error}
                        fullWidth
                        sx={{ ...(sxInput ?? {}) }}
                        InputLabelProps={{ sx: sxLabel, style: {
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        } }}
                      />
                    );
                  }}
                />
              </Box>
            )}
          />
        );
      case "phone2":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    if (!required) {
                      return true;
                    }
                    return (
                      new RegExp(regexPhone).test(value) ||
                      `${label} dato inválido. Número de teléfono a 10 digitos. ej. 5567883730`
                    );
                  },
                  minLength: {
                    value: 10,
                    message: "Número de teléfono a 10 digitos. ej. 5567883730",
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  inputComponent: TextMaskCustom,
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "phone":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "140%",
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "144%",
                    },
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {label}
                  {""}
                  {required ? (
                    <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                  ) : null}
                  {tooltipText && (
                    <Tip
                      title={tooltipText}
                      placement="top"
                      arrow
                      enterTouchDelay={0}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  )}
                </Box>

                <PhoneInput
                  {...restFields}
                  country={"mx"}
                  inputClass={classesPhone.inputBase}
                  enableSearch={true}
                  preferredCountries={["mx", "co"]}
                  // specialLabel={`${label} ${required ? " *" : ""}`}
                  onChange={(value, country, e, formattedValue) => {
                    onChange(value);
                    onSelect && onSelect(value);
                  }}
                  key={name}
                  inputProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                />
              </Box>
            )}
          />
        );
      case "autocomplete":
        const filter = createFilterOptions();
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { onChange, value } }) => (
              <AutocompleteStyled
                clearOnBlur
                handleHomeEndKeys
                sx={{ p: 0 }}
                fullWidth
                disabled={disabled}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    onChange({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    onChange({
                      name: newValue.inputValue,
                    });
                  } else {
                    onChange(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Agregar "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                loadingText="Cargando..."
                noOptionsText="Sin resultados"
                clearText="Limpiar"
                openText="Abrir"
                closeText="Cerrar"
                onInputChange={onInputChange}
                disablePortal={true}
                loading={loading}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                value={value}
                options={optionsList}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    placeholder={placeholder}
                    InputLabelProps={{ sx: sxLabel, style: {
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    } }}
                    required={required}
                    sx={{ ...(sxInput ?? {}) }}
                    label={
                      <>
                        {" "}
                        {label}
                        {iconSpam ? (
                          <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                            *
                          </spam>
                        ) : null}
                      </>
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                // renderOption={(props, option, { selected }) => (
                //   <Typography
                //     {...props}
                //     sx={{ fontSize: "14px !important" /* margin: "0 10px" */ }}
                //   >
                //     {option.name}
                //   </Typography>
                // )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px !important" /* margin: "0 10px" */,
                      }}
                    >
                      {option.name}
                    </Typography>
                    {optionDelete === true ? (
                      <Icon
                        fontSize={15}
                        icon="fxemoji:cancellationx"
                        onClick={() => handleDeleteOption(option)}
                      />
                    ) : null}
                  </li>
                )}
              />
            )}
          />
        );
      case "email":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    if (
                      value !== "" &&
                      value !== null &&
                      value !== undefined &&
                      required === false
                    ) {
                      return (
                        new RegExp(regexEmail).test(value) ||
                        `${label} dato inválido. ${
                          label === "Correo electrónico"
                            ? "ej. ejemplo@ejemplo.com"
                            : ""
                        }`
                      );
                    } else if (required === true) {
                      return (
                        new RegExp(regexEmail).test(value) ||
                        `${label} dato inválido. ${
                          label === "Correo electrónico"
                            ? "ej. ejemplo@ejemplo.com"
                            : ""
                        }`
                      );
                    }
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "curp":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    return (
                      new RegExp(regexCURP).test(value) ||
                      `${label} dato inválido. ingrese 18 caracteres.`
                    );
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 18,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "rfc":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 13,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "clabe":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  minLength: {
                    value: 18,
                    message: "Clabe a 18 digitos. ej. 452136987456985632",
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 18,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "PostalCode":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  minLength: {
                    value: 5,
                    message: "Ingresa los 5 digitos de tu código postal.",
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 5,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel , style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "currency":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "numberDecimal":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    style: { textTransform: "uppercase" },
                    pattern: "^\\d*\\.?\\d*$",
                    title: "",
                    onChange: (e) => {
                      e.target.value = e.target.value
                        .replace(/[^\d.]/g, "") // elimina todos los caracteres que no sean dígitos o puntos
                        .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // asegura que solo haya hasta 2 decimales
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "moneda":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    style: { textTransform: "uppercase" },
                    pattern: "^\\$?[0-9]+(\\.[0-9]{0,2})?$",
                    title: "",
                    onChange: (e) => {
                      e.target.value = e.target.value
                        .replace(/[^\d.]/g, "") // elimina todos los caracteres que no sean dígitos o puntos
                        .replace(/^(\d*\.?\d{0,2}).*$/, "$1"); // asegura que solo haya hasta 2 decimales
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ), // agregar el prefijo de $
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                } }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "inputRegex":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    return new RegExp(regexData).test(value);
                  },
                })}
                type={isPassword ? "password" : "text"}
                error={error}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                helperText={helperText}
                autoComplete="new-password"
                label={
                  label !== undefined && label !== null && label !== "" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                      {""}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  startAdornment:
                    posIcon === "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon === "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }, }}
                sx={{
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    return new RegExp(regexData).test(value);
                  },
                })}
                fullWidth
                error={error}
                inputRef={ref}
                disabled={disabled}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                onKeyPress={(event) => {
                  const charCode = event.which ? event.which : event.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    event.preventDefault(); // Prevenir entrada de caracteres no numéricos
                  }
                }}
                InputLabelProps={{ sx: sxLabel, style: {
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                },}}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "text":
      case "password":
      default:
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                })}
                type={isPassword ? "password" : "text"}
                error={error}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                helperText={helperText}
                autoComplete="new-password"
                label={
                  label !== undefined && label !== null && label !== "" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                      {""}
                      {iconSpam ? (
                        <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                          *
                        </spam>
                      ) : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  startAdornment:
                    posIcon === "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon === "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: sxLabel,
                  style: {
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  },
                }}
                sx={{
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
    }
  };

  if (inputType === "check") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>{getInputType()}</Box>
    );
  }

  return getInputType();
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask="(00) 0000 - 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      overwrite
    />
  );
});

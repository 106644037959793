import React, { useEffect, useState, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Button,
  IconButton,
  TableFooter,
  TablePagination,
  Grid,
} from "@mui/material";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { WhiteTooltip } from "./WhiteTooltip";
import { CustomInputField } from "./CustomInputField.jsx";

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const GenericTablePaginationAutomatic = (props) => {
  const buttonSubmit = useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Formulario dinamico
  const formMethods = useForm();
  const { control, handleSubmit, register, formState } = formMethods;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: props.Columns,
        data: props.Data,
        title: "",
        initialState: { pageIndex: 0, hiddenColumns: ["stage"] },
      },
      useSortBy
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (props.sendDataActive) {
      props.setSendDataActive(null);
      buttonSubmit.current.click();
    }
  }, [props.sendDataActive]);

  return (
    <FormProvider {...formMethods}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <TableContainer
            component={Paper}
            elevation={5}
            sx={{ maxHeight: 800 }}
          >
            <Table {...getTableProps()}>
              <TableHead
                sx={{
                  backgroundColor: "primary.main",
                  position: "sticky",
                  top: 0,
                }}
              >
                {headerGroups.map((headerGroup, index) => (
                  <TableRow
                    key={index + 1}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <WhiteTooltip
                        title={column?.Toltip}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <TableCell
                          sx={{
                            padding: 1.5,
                            width:
                              column?.type === "check"
                                ? 85
                                : column?.type === "Option"
                                ? 230
                                : "auto",
                          }}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent:
                                column?.type === "check" ||
                                column?.Header === "Clave_Solicitud_Consecutiva"
                                  ? "center"
                                  : "",
                              fontSize: "14px",
                              minWidth:
                                column?.type === "check"
                                  ? 85
                                  : column?.type === "Option"
                                  ? 230
                                  : "auto",
                            }}
                          >
                            <span>
                              {column.isSorted ? (
                                !column.isSortedDesc ? (
                                  <ArrowUpward
                                    fontSize="small"
                                    sx={{ mr: 1, mt: "5px" }}
                                  />
                                ) : (
                                  <ArrowDownward
                                    fontSize="small"
                                    sx={{ mr: 1, mt: "5px" }}
                                  />
                                )
                              ) : (
                                <ArrowDownward
                                  fontSize="small"
                                  sx={{
                                    mr: 1,
                                    mt: "5px",
                                    color: "primary.mainLyrics",
                                  }}
                                />
                              )}
                            </span>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: 14,
                                minWidth: 80,
                                fontWeight: 500,
                                color: "primary.mainLyrics",
                              }}
                            >
                              {column.render("Header")}
                            </Typography>
                          </Box>
                        </TableCell>
                      </WhiteTooltip>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => {
                  prepareRow(row);
                  return (
                    <TableRowStyled
                      {...row.getRowProps()}
                      onClick={() => {
                        props.onClickRow(row);
                      }}
                      sx={{
                        borderBottom: "none !important",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        if (cell.column.type === "Editable") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              <CustomInputField
                                inputType="text"
                                control={control}
                                register={register}
                                name={`EtiquetaCaptura_${row.original.NombreEtiqueta}`}
                                placeholder={cell.column.Header}
                                validations={{
                                  required: row.original.Required,
                                }}
                                error={
                                  !!formState.errors[
                                    `EtiquetaCaptura_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `EtiquetaCaptura_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={row.original.Required}
                                defaultValue={row.original.NombreEtiqueta}
                              />
                            </TableCell>
                          );
                        } else if (cell.column.type === "Option") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                paddingLeft: 1,
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              <CustomInputField
                                inputType="select"
                                control={control}
                                register={register}
                                name={`TipoDato_${row.original.NombreEtiqueta}`}
                                placeholder={cell.column.Header}
                                optionsList={cell.column.listOption}
                                validations={{
                                  required: row.original.Required,
                                }}
                                error={
                                  !!formState.errors[
                                    `TipoDato_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `TipoDato_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={row.original.Required}
                                defaultValue={row.original.TipoDato ?? ""}
                              />
                            </TableCell>
                          );
                        } else if (cell.column.type === "check") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                borderBottom: "none",
                                fontSize: "14px !important",
                                textAlign: "center",
                              }}
                            >
                              <CustomInputField
                                inputType="check"
                                control={control}
                                register={register}
                                name={`CheckCaptura_${row.original.NombreEtiqueta}`}
                                label={""}
                                validations={{ required: false }}
                                error={
                                  !!formState.errors[
                                    `CheckCaptura_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `CheckCaptura_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={false}
                                defaultValue={row.original.Required ?? false}
                              />
                            </TableCell>
                          );
                        } else {
                          if (cell.column.id === "status") {
                            return (
                              <TooltipTableCell
                                row={row}
                                statusVigencia={
                                  cell.row.original.statusVigencia
                                }
                                cell={cell}
                                key={cell.column.id}
                                enterTouchDelay={0}
                              />
                            );
                          }
                          if (cell.column.id === "estatus") {
                            return (
                              <TooltipTableCellEstatus
                                row={row}
                                statusRow={cell.row.original.estatus}
                                cell={cell}
                                key={cell.column.id}
                                enterTouchDelay={0}
                              />
                            );
                          }
                          if (cell.column.id === "statusError") {
                            return null;
                          }
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                textAlign:
                                  cell?.column?.Header ===
                                  "Clave_Solicitud_Consecutiva"
                                    ? "center"
                                    : "",
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent={"flex-end"}
            >
              <TableFooter
                sx={{
                  width: "auto",
                }}
              >
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[0]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={
                      ({ from, to, count }) => `${from}-${to} de ${count}` // Personalizar la descripción de las filas mostradas
                    }
                  />
                </TableRow>
              </TableFooter>
            </Grid>
          </TableContainer>
        </Box>
        <Button
          id={`btn_Save`}
          ref={buttonSubmit}
          onClick={handleSubmit(props.HandleSendData)}
          color="primary"
          style={{ marginTop: "0.5em", display: "none" }}
        >
          Send Data
        </Button>
      </LocalizationProvider>
    </FormProvider>
  );
};

const TooltipTableCell = (props) => {
  const [open, setopen] = useState(false);
  const tooltipTitle = (
    <Typography variant="caption">{props.row.original.stage}.</Typography>
  );

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{ borderBottom: "none", padding: 1 }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "status" &&
        props.row.original.stage === true ? (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="el:ok-circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        ) : (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="zondicons:close-outline"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};

const TooltipTableCellEstatus = (props) => {
  const [open, setopen] = useState(false);
  const tooltipTitle = (
    <Typography variant="caption">
      {props.row.original.statusError ??
        "Los datos no estan completos, todos los datos son requeridos."}
    </Typography>
  );

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{
        borderBottom: "none",
        padding: 1,
      }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "estatus" && props.statusRow === false ? (
          <WhiteTooltip placement="top" open={open} title={"Datos completos."}>
            <Icon
              icon="el:ok-circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        ) : (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="zondicons:close-outline"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#FF4842",
              }}
            />
          </WhiteTooltip>
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};

import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, styled } from "@mui/material";
import LoginComponent from "../login/LoginComponent.js";

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFF",
  width: "100%",
  height:'100%',
  margin: "0px auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const OutletWrapper = styled(Box)(({ theme }) => ({
  margin: "0px",
  [theme.breakpoints.down(1200)]: {
    margin: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0px",
  },
}));

export const LayoutLogin = () => {
  const location = useLocation();
  if (location.pathname.includes("login")) {
    return <LoginComponent />;
  }

  return (
    <RootStyle>
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
      {/* <Footer /> */}
    </RootStyle>
  );
};

import { templatesConstants } from "../constants";
import { TemplatesServices } from "../../services/TemplatesServices";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const GetRolesUsers = (filesData) => {
  return {
    type: templatesConstants.GET_ROLES_DATA_USERS,
    payload: filesData,
  };
};

export const resetRolesUsers = () => {
  return {
    type: templatesConstants.RESET_ROLES_DATA_USERS,
  };
};

export const resetTemplate = () => {
  return {
    type: templatesConstants.UPLOAD_TEMPLATE_RESET,
  };
};

const emptyAction = (actionType) => {
  return {
    type: actionType,
  };
};

export const UploadTemplateData = (data) => {
  return async (dispatch) => {
    dispatch(request(templatesConstants.UPLOAD_TEMPLATE_REQUEST));
    try {
      const templatesServices = TemplatesServices.getInstance();
      const fileData = await templatesServices.UploadTemplateData(data);
      dispatch(success(fileData.Body, templatesConstants.UPLOAD_TEMPLATE_SUCCESS));
    } catch (error) {
      console.log("errorBandeja", error)
      dispatch(failure(error.message, templatesConstants.UPLOAD_TEMPLATE_FAILURE));
    }
  };
};

export const GetTemplates = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.GET_TEMPLATES_EMPTY));
    } else {
      dispatch(request(templatesConstants.GET_TEMPLATES_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.GetTemplates(data);
        dispatch(success(responseData.Body, templatesConstants.GET_TEMPLATES_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.GET_TEMPLATES_FAILURE));
      }
    }
  };
};

export const GetTemplatesActive = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.GET_TEMPLATES_ACTIVE_EMPTY));
    } else {
      dispatch(request(templatesConstants.GET_TEMPLATES_ACTIVE_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.GetTemplatesActive(data);
        dispatch(success(responseData.Body, templatesConstants.GET_TEMPLATES_ACTIVE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.GET_TEMPLATES_ACTIVE_FAILURE));
      }
    }
  };
};

export const GetCategoriesTemplate = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.GET_CATEGORIES_TEMPLATE_EMPTY));
    } else {
      dispatch(request(templatesConstants.GET_CATEGORIES_TEMPLATE_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.GetCategoriesTemplate(data);
        dispatch(success(responseData.Body, templatesConstants.GET_CATEGORIES_TEMPLATE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.GET_CATEGORIES_TEMPLATE_FAILURE));
      }
    }
  };
};

export const GetContentTemplate = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.GET_CONTENT_TEMPLATES_EMPTY));
    } else {
      dispatch(request(templatesConstants.GET_CONTENT_TEMPLATES_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.GetContentTemplate(data);
        dispatch(success(responseData.Body, templatesConstants.GET_CONTENT_TEMPLATES_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.GET_CONTENT_TEMPLATES_FAILURE));
      }
    }
  };
};

export const DeleteLogicCategoriesTemplate = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.DELETE_CATEGORY_TEMPLATE_EMPTY));
    } else {
      dispatch(request(templatesConstants.DELETE_CATEGORY_TEMPLATE_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.DeleteLogicCategoriesTemplate(data);
        dispatch(success(responseData, templatesConstants.DELETE_CATEGORY_TEMPLATE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.DELETE_CATEGORY_TEMPLATE_FAILURE));
      }
    }
  };
};


export const DeleteLogicTemplate = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(templatesConstants.DELETE_TEMPLATE_EMPTY));
    } else {
      dispatch(request(templatesConstants.DELETE_TEMPLATE_REQUEST));
      try {
        const templatesServices = TemplatesServices.getInstance();
        const responseData = await templatesServices.DeleteLogicTemplate(data);
        dispatch(success(responseData, templatesConstants.DELETE_TEMPLATE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, templatesConstants.DELETE_TEMPLATE_FAILURE));
      }
    }
  };
};

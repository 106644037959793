import { GenericServicePayment } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class PaymentsService {
  static instance;

  constructor() {
    this.baseUrl = "Payment";
  }

  static getInstance() {
    if (!PaymentsService.instance) {
      PaymentsService.instance = new PaymentsService();
    }
    return PaymentsService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    let respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      let responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async GetPlansService(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/GetPlans`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseDataPlans', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetClientData(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/GetClientData`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseDataCliente', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async RegisterClient(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/RegisterClient`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async RegisterPaymentClientPlan(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/RegisterPaymentClientPlan`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetBilling(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/GetBilling`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseGetBilling', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  /*   async RegisterBillingInformation(data) {
      try {
        console.log('DATA', data)
        const response = await GenericServicePayment.post({
          endpoint: `${this.baseUrl}/RegisterBillingInformation`,
          data: data,
          config: Config.configNoAuth(),
        });
        console.log('responseRegisterClient', response)
        return response.data;
      } catch (error) {
        console.log('ERROR SERVER', error)
      }
    } */

  async GetRegimenFiscal(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.get({
        endpoint: `${this.baseUrl}/GetRegimenFiscal`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetPaymentStatus(data) {
    try {
      console.log('GetPaymentStatus', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/GetPaymentStatus`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async CancelSuscription(data) {
    try {
      console.log('CancelSuscription', data)
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/CancelSuscription`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async RegisterBillingInformation(data) {
    try {
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/PostDataFiscalClient`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetUseCfdi(data) {
    try {
      const response = await GenericServicePayment.get({
        endpoint: `${this.baseUrl}/GetUseCfdi`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async PutDataFiscalClient(data) {
    try {
      const response = await GenericServicePayment.put({
        endpoint: `${this.baseUrl}/PutDataFiscalClient`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('PutDataFiscalClient', response.data)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetDataFiscalClient(data) {
    try {
      const response = await GenericServicePayment.get({
        endpoint: `${this.baseUrl}/GetDataFiscalClient?clientId=${data}`,
        data: data,
        config: Config.configNoAuth(),
      });
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async CrearCFDI(data) {
    try {
      const response = await GenericServicePayment.post({
        endpoint: `${this.baseUrl}/CrearCFDIV4?idBilling=${data}`,
        config: Config.configNoAuth(),
      });
       return response.data
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

}

import { Box, Grid, Typography, styled } from "@mui/material";
import React, { Fragment } from "react";
import { makeStyles } from '@mui/styles'
import imagenIlustrativa from "../../../assets/RegistroExitoso.png";
import imagenIlusReponsive from "../../../assets/RegistroExitosoRersponsive.png";

const useStyles = makeStyles(() => ({//Estílos solamente del componente "LoginComponent"
  formInput:{
    width: "100%",
    padding: 1
  },
  containerImage:{
    height: 250
  },
  imagen:{
    height: '100%'
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: 330,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    height: '100%',
    maxWidth: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    height: '100%',
    maxWidth: "100%"
  },
}));

export default function ConfirmRegister() {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container justifyContent={"center"}>
        <Grid item lg={12}>
          <Box
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            className={classes.containerImagen}
          >
            <Image alt="Imagen Iustrativa" src={imagenIlustrativa} />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
            }}
            className={classes.containerImagen}
          >
            <Image alt="Imagen Iustrativa" src={imagenIlusReponsive} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box width={"75%"} sx={{ p: 3, mt: 1 }}>
              <Box sx={{p:1}}>
                <Typography variant="h5">
                  <b>Registro exitoso</b>
                </Typography>
              </Box>
              <Box sx={{p:1}}>
                <Typography sx={{fontSize: 17}}>
                  Para acceder es necesario que actives tu cuenta desde el link
                  que ha sido enviado a tu correo electrónico.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

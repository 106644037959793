import { Box, Button, Grid, Typography } from "@mui/material";
import { InputField } from "../../../components/InputField";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRegimenFiscal,
  GetUseCfdi,
  PutDataFiscalClient,
  RegisterBillingInformation,
} from "../../../store/actions/PaymentsActions";
import { useEffect } from "react";
import BackdropComponent from "../../../components/BackDrop";
import { useState } from "react";
import { CustomInputField } from "../../../components/CustomInputField";
import { PaymentsService } from "../../../services/PaymentsService";
import { Alert } from "../../../components/Alert";

export default function BillingData() {
  const formMethods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataClientFiscal, setDataClientFiscal] = useState(null);
  const clientIdDs = useSelector((state) => state.DataPaymentClient?.items);
  const registerBilling = useSelector(
    (state) => state.RegisterBillingInformation
  );

  const onSubmit = (data) => {
    if (dataClientFiscal !== null) {
      dispatch(
        PutDataFiscalClient({
          request: {
            id: 0,
            client_Id: clientIdDs?.client_Id,
            regime_Id: data.regFiscal,
            useCfdi_Id: data.usoCFDI,
            regime: "",
            useCfdi: "",
            rfc: data.rfc.toUpperCase(),
            email: data.email,
            tradeName: data.tradeName.toUpperCase(),
            postalCode: data.postalCode,
            active: true,
          },
          client_Id: clientIdDs?.client_Id,
        })
      );
    } else {
      dispatch(
        RegisterBillingInformation({
          request: {
            id: 0,
            client_Id: clientIdDs?.client_Id,
            regime_Id: data.regFiscal,
            useCfdi_Id: data.usoCFDI,
            regime: "",
            useCfdi: "",
            rfc: data.rfc.toUpperCase(),
            email: data.email,
            tradeName: data.tradeName.toUpperCase(),
            postalCode: data.postalCode,
            active: true,
          },
          client_Id: clientIdDs?.client_Id,
        })
      );
    }
  };

  useEffect(() => {
    if (
      registerBilling.items !== null &&
      registerBilling.items?.responseCode == 0
    ) {
      Alert({
        title: "Datos registrados correctamente",
        icon: "success",
      });
      dispatch(RegisterBillingInformation("", true));
      navigate("/inbox/billings");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [registerBilling.items]);

  const GetDataClient = async () => {
    try {
      setDataClientFiscal(null);
      setLoading(true);
      const dataEdit = PaymentsService.getInstance();
      const response = await dataEdit.GetDataFiscalClient(
        clientIdDs?.client_Id
      );
      if (response.data.clientDataFiscalResponse != null) {
        setDataClientFiscal(response.data.clientDataFiscalResponse);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("ERRORSERVER", error);
      Alert({
        title: "Error",
        error: error.responseCodeMessage,
        icon: "error",
      });
    }
  };

  const dynamicControls = [
    {
      Type: "RFC",
      Name: "rfc",
      Label: "RFC",
      Required: true,
      Value: "",
    },
    {
      Type: "TEXTO",
      Name: "tradeName",
      Label: "Razón social",
      Required: true,
      Value: "",
    },
    {
      Type: "CP",
      Name: "postalCode",
      Label: "Código postal",
      Required: true,
      Value: "",
    },
    {
      Type: "EMAIL",
      Name: "email",
      Label: "Correo electrónico",
      Required: true,
      Value: "",
    },
  ];

  useEffect(() => {
    dispatch(RegisterBillingInformation("", true));
    GetDataClient();
  }, []);

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <BackdropComponent loading={loading} />
      <Grid item xs={12} lg={11} md={12}>
        <Box sx={{ m: 1 }}>
          <Typography variant="titulo" sx={{ fontWeight: 550 }}>
            Información Fiscal
          </Typography>
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle1">
            Una vez ingresados, tus datos de facturación podrán ser modificados
            en cualquier momento, pero <b>no eliminados.</b>
          </Typography>
        </Box>
        <FormProvider {...formMethods}>
          <FormRegister
            data={dynamicControls}
            clickSubmit={onSubmit}
            DataClientFiscal={dataClientFiscal}
          />
        </FormProvider>
      </Grid>
    </Grid>
  );
}

const FormRegister = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateRegimenes = useSelector((state) => state.GetRegimenFiscal);
  const stateUsoCdfi = useSelector((state) => state.GetUseCfdi);
  const stateDataClient = useSelector((state) => state.Authentication.items);
  const [regimenes, setRegimenes] = useState([]);
  const [usoCdfi, setUsoCdfi] = useState([]);
  const { control, handleSubmit, watch, setValue, formState, register } =
    useForm({
      mode: "onBlur",
    });
  const rfc = watch("rfc");

  useEffect(() => {
    dispatch(GetRegimenFiscal());
    dispatch(GetUseCfdi());
    setValue("email", stateDataClient.UserLoginData.Email);
  }, []);

  useEffect(() => {
    if (stateRegimenes.items) {
      if (determinarTipoPersona(rfc) === "moral") {
        const newState = stateRegimenes.items?.catalogoRegimenFiscal.filter(
          (item) => item.moral === true
        );
        const newDatos = newState.map((item) => {
          return {
            Name: `${item.code} - ${item.description}`,
            Value: item.id,
          };
        });
        const newStateCfdi = stateUsoCdfi.items?.catalogUseCfdi.filter(
          (item) => item.moral === true
        );
        const newDatosCfdi = newStateCfdi.map((item) => {
          return {
            Name: `${item.code} - ${item.description}`,
            Value: item.id,
          };
        });
        setRegimenes(newDatos);
        setUsoCdfi(newDatosCfdi);
      } else if (determinarTipoPersona(rfc) === "fisica") {
        const newState = stateRegimenes?.items?.catalogoRegimenFiscal?.filter(
          (item) => item.fisica === true
        );
        const newDatos = newState?.map((item) => {
          return {
            Name: `${item.code} - ${item.description}`,
            Value: item.id,
          };
        });

        const newStateFisica = stateUsoCdfi?.items?.catalogUseCfdi?.filter(
          (item) => item.fisica === true
        );
        const newDatosFisica = newStateFisica.map((item) => {
          return {
            Name: `${item.code} - ${item.description}`,
            Value: item.id,
          };
        });

        setRegimenes(newDatos);
        setUsoCdfi(newDatosFisica);
      }
    }
  }, [rfc]);

  const determinarTipoPersona = (rfc) => {
    const regexPersonaMoral = /^[A-Z&Ñ]{3}\d{6}[A-Z0-9]{3}$/i;
    const regexPersonaFisica = /^[A-Z&Ñ]{4}\d{6}[A-Z0-9]{3}$/i;

    if (regexPersonaMoral.test(rfc)) {
      return "moral";
    } else if (regexPersonaFisica.test(rfc)) {
      return "fisica";
    } else {
      return ""; // O algún valor por defecto
    }
  };

  useEffect(() => {
    if (props.DataClientFiscal !== null) {
      Object.keys(props?.DataClientFiscal)?.forEach((key) => {
        const data = props.DataClientFiscal[key];
        props.data?.forEach((item) => {
          if (item.Name === key) {
            setValue(item.Name, data);
          }
        });
      });
      setValue("regFiscal", props.DataClientFiscal.regime_Id);
      setValue("usoCFDI", props.DataClientFiscal.useCfdi_Id);
    }
  }, [props.DataClientFiscal]);

  return (
    <>
      <Grid container>
        {props.data.length > 0 &&
          props.data.map((itemInput, indexInput) => {
            switch (itemInput.Type.toUpperCase()) {
              case "TEXTO":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="text"
                      iconSpam={true}
                      control={control}
                      disabled={false}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={itemInput.Required}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "SELECT":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="select"
                      iconSpam={true}
                      control={control}
                      optionsList={regimenes}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{
                        required: itemInput.Required,
                      }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "PHONE":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="phone"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "EMAIL":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="email"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "CP":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="cp"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "RFC":
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="rfc"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
                    <InputField
                      inputType="text"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
            }
          })}
        <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
          <CustomInputField
            inputType="select"
            iconSpam={true}
            control={control}
            optionsList={regimenes}
            register={register}
            name={"regFiscal"}
            label={"Régimen fiscal"}
            validations={{
              required: true,
            }}
            error={!!formState.errors[`regFiscal`]}
            helperText={formState.errors[`regFiscal`]?.message}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={11} sx={{ p: 1 }}>
          <CustomInputField
            inputType="select"
            iconSpam={true}
            control={control}
            optionsList={usoCdfi}
            register={register}
            name={"usoCFDI"}
            label={"Uso de CFDI"}
            validations={{
              required: true,
            }}
            error={!!formState.errors[`usoCFDI`]}
            helperText={formState.errors[`usoCFDI`]?.message}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: 3,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/inbox/billings")}
            sx={{
              minWidth: 300,
              height: 38,
              fontSize: 16,
            }}
          >
            Cancelar
          </Button>
        </Box>
        <Box sx={{ p: 1 }}>
          <CustomButton
            onClick={handleSubmit(props.clickSubmit)}
            sx={{
              minWidth: 300,
              height: 38,
            }}
          >
            Guardar
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getFile, resetDataUploadVideo,  trueLoading } from "../../../../../store/actions";
import { SignatureService } from "../../../../../services/SignatureService";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useNavigate } from "react-router-dom";
import { AlertConfirm } from "../../../../../components/Alert";
import imgBio from '../../../../../assets/Biometricos.gif'
import CargaVideo from '../../../../../assets/CargaVideo.gif'
import errorIcon from '../../../../../assets/icons/ErrorIcon.gif'
import saveIcon from '../../../../../assets/icons/SuccessIcon.gif'
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import { renderToString } from "react-dom/server";

const useStylesInt = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  paperResponsive: {
    display: "flex",
    padding: 8,
    flexWrap: "wrap",
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      padding: 7,
      minHeight: 'auto',
    },
  },
  botonReponsive: {
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  imgResponsive: {
    maxHeight: 300, alignContent: 'center', alignItems: 'center', justifyItems: 'center',
    [theme.breakpoints.down("sm")]: {
      maxHeight: 130,
    },
  }
}));

export default function ValidacionBiometrica({ setVetanaVideo, ValidateStatus, setValue, isActiveMassiveSignature }) {
  const [timerSeconds, setTimerSeconds] = useState(-1);
  const classes = useStylesInt();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogoutClose = true;
  const [responseValidation, setResponseValidation] = useState()
  const [validacionStatus, setValidacionStatus] = useState(null);
  const [validacionFinal, setValidacionFinal] = useState(null);
  const [statusUploadVideo, setStatusUploadVideo] = useState(null);
  const [statusValidationErrors, setStatusValidationErrors] = useState(false);
  const uploadVIdeoUserData = useSelector((state) => state.UploadVideoUser);
  const documentData = useSelector((state) => state.File);
  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );
  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.StageName
  );
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );

  const cerrarSesion = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/auth/login");
    window.location.reload();
  };

  const handleSignature = () => {
    AlertConfirm(
      {
        title: "Documento firmado correctamente",
        icon: "success",
        okbtntext: "Entendido",
        html: renderToString(
          <Box>
            <Typography
              textAlign={"justify"}
              sx={{ maxWidth: 400, mx: "auto" }}
            >
              Agradecemos la aplicación de tu firma electrónica en
              el documento. El administrador de la solicitud
              recibirá una notificación por medio de un correo
              electrónico, para conocimiento de tu firmado.
            </Typography>
            <Divider sx={{ maxWidth: 300, mt: 3, mx: "auto" }} />
          </Box>
        ),
      },
      (result) => {
        if (result.isConfirmed) {
          reenviarVentana();
        } else if (result.dismiss === "cancel" && result.isDismissed) {
        }
      }
    );
  };

  const reenviarVentana = () => {
    if (rolUser === "Operador" && userLogoutClose === true) {
      setTimeout(() => {
        cerrarSesion();
      }, 2000);
    } else {
      dispatch(getFile({ FileId: document?.item?.FileData?.Id }));
      dispatch(trueLoading());
      sessionStorage.removeItem('viewDocument')
      setTimeout(() => {
        navigate("/inbox/files");
      }, 2000);
    }
  };

  const getBiometrics = useCallback(async () => {
    try {
      const signatureService = SignatureService.getInstance();
      const resSign = await signatureService.getBiometricStatus({
        File_Id: documentData.item?.FileData?.Id,
        Actor_Id: null,
        ContinueWorkflow: true,
        IsMasive: isActiveMassiveSignature,
      });
      if (fileAuthentication?.ConfigurationVideoValidates.ValidateLocked === true) {
        if (resSign.data.data.Body) {
          setResponseValidation(resSign.data.data.Body)
          if (resSign.data.data.Body.StageResult === "Aprobado" &&
            resSign.data.data.Body.EvidenceResult === true &&
            resSign.data.data.Body.FacialResult === true &&
            resSign.data.data.Body.ConsentResult === true
          ) {
            setValidacionStatus(true);
            setTimerSeconds(-1)
          } else if (resSign.data.data.Body.StageResult === 'Reintento') {
            setValidacionStatus(false);
            setTimerSeconds(-1)
          } else if (resSign.data.data.Body.StageResult === 'Rechazado') {
            setValidacionFinal(true);
            setTimerSeconds(-1)
            setValidacionStatus(false);
          } else {
            setValidacionStatus(null);
          }
        } else {
          setValidacionStatus(false);
        }
      } else {
        if (resSign.data.data.Body) {
          setResponseValidation(resSign.data.data.Body)
          if (resSign.data.data.Body.StageResult === "Aprobado" &&
            resSign.data.data.Body.EvidenceResult === true &&
            resSign.data.data.Body.FacialResult === true
          ) {
            setValidacionStatus(true);
            setTimerSeconds(-1)
          } else if (resSign.data.data.Body.StageResult === 'Reintento') {
            setValidacionStatus(false);
            setTimerSeconds(-1)
          } else if (resSign.data.data.Body.StageResult === 'Rechazado') {
            setValidacionFinal(true);
            setTimerSeconds(-1)
            setValidacionStatus(false);
          } else {
            setValidacionStatus(null);
          }
        } else {
          setValidacionStatus(false);
        }
      }

    } catch (error) {
      console.error(error)
    }
  }, []);

  useEffect(() => {
    if (timerSeconds === -1) {
      // No hagas nada si timerSeconds es -1
      return;
    }

    if (timerSeconds === 0) {
      getBiometrics();
      setTimerSeconds(15)
    }
    if (!timerSeconds) return;
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);

    // clear interval al volver a renderizar para evitar pérdidas de memoria
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  useEffect(() => {
    if (stageFileFlow === "EvidenciasValidacion") {
      getBiometrics();
      setStatusUploadVideo(true);
      setTimerSeconds(15);
    }
  }, [stageFileFlow]);

  useEffect(() => {
    if ((uploadVIdeoUserData.file_flow !== undefined && uploadVIdeoUserData.file_flow !== null && uploadVIdeoUserData.file_flow !== "") && uploadVIdeoUserData.loading === false) {
      if (uploadVIdeoUserData.file_flow.ResponseCode === 0 && uploadVIdeoUserData.file_flow.Response !== null) {
        setStatusUploadVideo(true);
        setTimerSeconds(15);
      } else if (uploadVIdeoUserData.file_flow.ResponseCode !== 0 &&
        (uploadVIdeoUserData.file_flow.Message !== undefined && uploadVIdeoUserData.file_flow.Message !== null && uploadVIdeoUserData.file_flow.Message !== "")) {
        setStatusUploadVideo(false);
        setStatusValidationErrors(true);
      }
    }
  }, [uploadVIdeoUserData])


  return (
    <Box sx={{ p: 1, mt: 2 }}>
      <Box
        className={classes.paperResponsive}
      >
        <Box>
          {statusValidationErrors === true ? (
            <Grid container justifyContent={'center'}>
              <Grid sm={12} xs={12} lg={12}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="titulo">
                    Verificación de consentimiento
                  </Typography>
                </Box>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 3, maxHeight: 300 }}>
                <img height={'80%'} alt="imagen de biometricos" src={errorIcon} />
              </Box>
              <Grid item xs={12} lg={12}>
                <Box sx={{ mt: 3, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: "center" }}>
                    <Typography variant="titulo">
                      ¡Algo salió mal al cargar tu video!
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', ml: 1, mr: 2, mt: .8, alignItems: 'center', alignContent: 'center', justifyContent: "center" }}>
                  <Icon style={{ marginRight: 4 }} icon="teenyicons:sound-off-outline" fontSize={27} color="#C20E30" />
                  <Typography variant="h6" sx={{ fontWeight: 450 }}>
                    {uploadVIdeoUserData.file_flow.Message === "No se pudo procesar validación de audio" ||
                      uploadVIdeoUserData.file_flow.Message === "El audio del video es muy bajo" ||
                      uploadVIdeoUserData.file_flow.Message === "No se detecto audio en el video, por favor realiza otra toma" ?
                      "Video sin audio; Lee en voz alta el texto de consentimiento" : uploadVIdeoUserData.file_flow.Message
                    }
                  </Typography>
                </Box>

              </Grid>
              <Grid sm={8} xs={11} lg={6} sx={{ mt: 3 }}>
                <Button
                  fullWidth
                  sx={{ height: 38 }}
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    if (ValidateStatus === true) {
                      dispatch(getFile({ FileId: documentData?.item?.FileData?.Id }));
                      setStatusValidationErrors(false);
                      setValue(2)
                      dispatch(resetDataUploadVideo());
                    } else {
                      setStatusValidationErrors(false);
                      setVetanaVideo(0);
                      dispatch(resetDataUploadVideo());
                    }
                  }}
                >
                  <Typography sx={{ letterSpacing: 1.2 }}>Reintentar</Typography>
                </Button>
              </Grid>
            </Grid>
          ) :
            validacionStatus === false ? (
              <Grid container justifyContent={'center'}>
                <Grid sm={12} xs={12} lg={12}>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="titulo">
                      Verificación de consentimiento
                    </Typography>
                  </Box>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 3, maxHeight: 300 }}>
                  <img height={'80%'} alt="imagen de biometricos" src={errorIcon} />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ mt: 3, mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                      <Typography variant="titulo">
                        ¡Algo salió mal!
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: 'center', mt: .5 }}>
                      <Typography variant="h6" sx={{ fontWeight: 450 }}>
                        Por favor intenta de nuevo.
                      </Typography>
                    </Box>
                  </Box>
                  {responseValidation?.StatusMessage != undefined && responseValidation?.StatusMessage != "" && responseValidation?.StatusMessage != null ? (
                    <Box sx={{ display: 'flex', ml: 3, mt: 1, alignItems: 'center', alignContent: 'center', justifyContent: "center" }}>
                      <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
                        {responseValidation?.StatusMessage}
                      </Typography>
                    </Box>
                  ) :
                    (
                      <div>
                        {fileAuthentication?.ConfigurationVideoValidates.ValidateLocked === true ? (
                          responseValidation?.ConsentResult === true ? (
                            <Box sx={{ display: 'flex', ml: 3, mt: .8, alignItems: 'left', alignContent: 'left', justifyContent: "left" }}>
                              <Icon icon="akar-icons:sound-on" fontSize={34} color='green' style={{ marginRight: 11 }} />
                              <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                                Leyenda de consentimiento
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', ml: 3, mt: .8, alignItems: 'left', alignContent: 'left', justifyContent: "left" }}>
                              <Icon icon="akar-icons:sound-on" fontSize={34} color='#C20E30' style={{ marginRight: 11 }} />
                              <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                                Leyenda de consentimiento; Asegurate de leer el texto de manera clara y en voz alta
                              </Typography>
                            </Box>
                          )
                        ) : null}
                        {responseValidation?.FacialResult === true ? (
                          <Box sx={{ display: 'flex', ml: 3, mt: .8, alignItems: 'left', alignContent: 'left', justifyContent: "left" }}>
                            <Icon icon="formkit:happy" fontSize={34} color='green' style={{ marginRight: 6 }} />
                            <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                              Detección facial
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', ml: 3, mr: 2, mt: .8, alignItems: 'left', alignContent: 'left', justifyContent: "left" }}>
                            <Icon icon="formkit:happy" fontSize={34} color='#C20E30' style={{ marginRight: 6 }} />
                            <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                              Detección facial; Asegurate que te visualizas de manera clara en el video
                            </Typography>
                          </Box>
                        )}
                      </div>
                    )
                  }
                </Grid>
              </Grid>
            ) : validacionStatus === true ? (
              <Grid container justifyContent={'center'}>
                <Grid sm={12} xs={12} lg={12}>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="titulo">
                      Verificación de consentimiento
                    </Typography>
                  </Box>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 3, maxHeight: 300 }}>
                  <img height={'100%'} alt="imagen de biometricos" src={saveIcon} />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                      <Typography variant="titulo">
                        Aprobado
                      </Typography>
                    </Box>
                    {fileAuthentication?.ConfigurationVideoValidates.ValidateLocked === true ? (
                      <Box sx={{ display: 'flex', mt: .8, ml: 3, alignItems: 'center' }}>
                        <Icon icon="akar-icons:sound-on" fontSize={34} color='green' style={{ marginRight: 11 }} />
                        <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                          Leyenda de consentimiento
                        </Typography>
                      </Box>
                    ) : null}
                    <Box sx={{ display: 'flex', mt: .8, ml: 2, alignItems: 'center' }}>
                      <Icon icon="formkit:happy" fontSize={34} color='green' style={{ marginRight: 6 }} />
                      <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                        Detección facial
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container justifyContent={'center'}>
                  <Grid sm={12} xs={12} lg={12}>
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant="titulo">
                        Consentimiento de firma
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid sm={12} xs={12} lg={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 3, height: 300 }}>
                      <img height={'100%'} alt="imagen de biometricos" src={statusUploadVideo === true ? imgBio : CargaVideo} />
                    </Box>
                  </Grid>
                  <Box sx={{ textAlign: 'left' }}>
                    <Box sx={{ mt: 3 }}>
                      <Box sx={{ mb: 1, mt: 1 }}>
                        {statusUploadVideo === true ? (
                          <Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                              <Typography variant="titulo">
                                Analizando
                              </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                              Estamos procesando tu videograbación
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            <Typography variant="titulo">
                              Estamos cargando tu videograbación
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {statusUploadVideo === true ? (
                        <>
                          <Box sx={{ display: 'flex', mt: 2, ml: 3, alignItems: 'center' }}>
                            <CheckCircleOutlinedIcon sx={{ fontSize: 32, mr: 1.7, color: 'green' }} />
                            <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                              Video guardado
                            </Typography>
                          </Box>
                          {fileAuthentication?.ConfigurationVideoValidates.ValidateLocked === true ? (
                            <Box sx={{ display: 'flex', mt: .8, ml: 3, alignItems: 'center' }}>
                              <Icon icon="akar-icons:sound-on" fontSize={34} color='#FBBF28' style={{ marginRight: 11 }} />
                              <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                                Leyenda de consentimiento
                              </Typography>
                            </Box>
                          ) : null}
                          <Box sx={{ display: 'flex', mt: .8, ml: 3, mb: 2, alignItems: 'center' }}>
                            <Icon icon="formkit:happy" fontSize={34} color='#FBBF28' style={{ marginRight: 6 }} />
                            <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                              Detección Facial
                            </Typography>
                          </Box>
                        </>
                      ) :
                        <Box sx={{ display: 'flex', mt: 3, ml: 3, alignItems: 'center' }}>
                          <CheckCircleOutlinedIcon sx={{ fontSize: 32, mr: 1.7, color: '#FBBF28' }} />
                          <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                            Video cargado
                          </Typography>
                        </Box>
                      }
                      <Box sx={{ mt: 1, mb: 1 }}>
                        <Typography variant="h6" align="justify" sx={{ fontWeight: 450 }}>
                          Esto podría demorar unos minutos, gracias por tu comprensión
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
        </Box>
        {validacionStatus === false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box className={classes.botonReponsive} >
              <Box sx={{ p: 1 }}>
                {validacionFinal === true ? (
                  <Button
                    fullWidth
                    sx={{ height: 38 }}
                    variant="contained"
                    size="medium"
                    onClick={() => navigate("/inbox/files")}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>Cerrar</Typography>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    sx={{ height: 38 }}
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      if (ValidateStatus === true) {
                        dispatch(getFile({ FileId: documentData?.item?.FileData?.Id }));
                        setValue(2)
                      } else {
                        setVetanaVideo(0)
                      }
                    }}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>Reintentar</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : validacionStatus === true ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box className={classes.botonReponsive}>
              <Box sx={{ p: 1 }}>
                <Button
                  fullWidth
                  sx={{ height: 38 }}
                  variant="contained"
                  size="medium"
                  onClick={handleSignature}
                // loading={loadingSignature}
                >
                  <Typography sx={{ letterSpacing: 1.2 }}>Finalizar</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

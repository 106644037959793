import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTable } from "react-table";
import BackdropComponent from "../../components/BackDrop";
import { GetBilling, setTitleGral } from "../../store/actions";
import { B64toBlob, numeroFormateado } from "../../utils";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { FileFlowService } from "../../services/FileFlowService";
import { PaymentsService } from "../../services/PaymentsService";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { Alert, AlertConfirm } from "../../components/Alert";

const columns = [
  {
    Header: "Número de pedido",
    accessor: "uid",
  },
  {
    Header: "Estado",
    accessor: "status",
  },
  {
    Header: "Monto",
    accessor: "amount",
  },
  {
    Header: "Fecha",
    accessor: "billingDate",
  },
  {
    Header: "Planes",
    accessor: "planName",
  },
  {
    Header: "Recibo de compra",
    accessor: "ticket",
  },
  {
    Header: "Factura",
    accessor: "billing",
  },
];
const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function Billings() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const dataClient = useSelector((state) => state.DataPaymentClient);
  const dataBilling = useSelector((state) => state.DataPaymentGetBillings);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable({
      columns,
      data,
      title: "",
      initialState: { pageIndex: 0, hiddenColumns: ["stage"] },
    });

  useEffect(() => {
    dispatch(setTitleGral("Facturación"));
    dispatch(
      GetBilling({
        referenceData: "",
        clientId: dataClient?.items?.client_Id,
      })
    );
  }, []);

  const downloadTicket = async (dataTicket) => {
    setLoading(true);
    try {
      const downloadTicket = FileFlowService.getInstance();
      const response = await downloadTicket.GetTicketPayment({
        Body: dataTicket,
      });
      const blob = B64toBlob(response.Body, "application/pdf");

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Recibo_Compra_${dataTicket.descripcionProducto}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const downloadBiling = async (dataBilling) => {
    setLoading(true);
    try {
      const downloadBilling = PaymentsService.getInstance();
      const response = await downloadBilling.CrearCFDI(dataBilling);

      if (response.data.status == "Success") {
        const byteCharacters = atob(response.data.zip);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${response.data.uuid}.zip`); // Puedes ajustar el nombre del archivo según sea necesario
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } else {
        AlertConfirm(
          {
            icon: "error",
            title: "Error",
            okbtntext: "Aceptar",
            text: response.data.message,
            showCancelButton: false,
          },
          (result) => {
            navigation("/inbox/BillingData");
          }
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const openAlert = (text) => {
    Alert({
      text: text,
      icon: "warning",
    });
  };

  useEffect(() => {
    if (dataBilling.items !== undefined && dataBilling.items !== null) {
      const filesTable = dataBilling.items.data.billings.map((file) => {
        console.log("file", file);
        return {
          uid: file.uid,
          status: file.planName,
          amount: file.amount,
          billingDate: file.billingDate,
          planName: file.planName + " / " + file.ticketData.periodo,
          ticket: file.ticketData,
          billing:
            file.activeCfdi == false ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  title={"Factura no disponible"}
                  placement="top"
                  arrow
                  enterTouchDelay={0}
                >
                  <Icon
                    icon="heroicons-outline:download"
                    fontSize={28}
                    color={"gray"}
                  />
                </WhiteTooltip>
              </Box>
            ) : file.errorInvoice == true ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  title={file.messageInvoice}
                  placement="top"
                  arrow
                  enterTouchDelay={0}
                >
                  <Icon
                    fontSize={30}
                    onClick={() => downloadBiling(file.id)}
                    icon="mdi:reload"
                  />
                </WhiteTooltip>
              </Box>
            ) : file.dataFiscal ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="heroicons-outline:download"
                  fontSize={28}
                  onClick={() => downloadBiling(file.id)}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  title={"Por favor actualiza tus datos fiscales"}
                  placement="top"
                  arrow
                  enterTouchDelay={0}
                >
                  <Button
                    variant="contained"
                    sx={{ height: 35, width: 170, fontSize: '12px!important' }}
                    color="primary"
                    onClick={() => {
                      navigation("/inbox/BillingData");
                    }}
                  >
                    Llenar datos
                  </Button>
                </WhiteTooltip>
              </Box>
            ),
        };
      });
      return setData(filesTable);
    }
  }, [dataBilling.items]);

  return (
    <Grid container alignContent={"center"} alignItems={"center"}>
      <BackdropComponent loading={dataBilling?.loading || loading} />
      <Grid container>
        <Grid item lg={9}>
          <Box>
            <Typography variant="titulo" sx={{ fontWeight: 550 }}>
              Revisa tu historial de facturación
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 3 }}>
            <Typography variant="subtitle1">
              Te informamos que solo tienes 5 días después de tu compra para
              generar tu factura.
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{ height: 45, width: "100%"}}
              color="primary"
              onClick={() => {
                navigation("/inbox/BillingData");
              }}
            >
              Actualizar datos fiscales
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        {data.length > 0 ? (
          <TableContainer component={Paper}>
            <Table {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell sx={{ padding: 0.8, paddingLeft: 3 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            ml: 1,
                            minWidth: column.Header === "Folio" ? 30 : 100,
                          }}
                        >
                          <Typography
                            variant="subtitle"
                            sx={{
                              fontSize: 14,
                              minWidth: 80,
                              fontWeight: 500,
                            }}
                          >
                            {column.render("Header")}
                          </Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRowStyled
                      {...row.getRowProps()}
                      sx={{
                        borderBottom: "none !important",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            sx={{
                              padding: 1.5,
                              paddingLeft: 4,
                              borderBottom: "none",
                              fontSize: "14px !important",
                            }}
                          >
                            {cell.column.id === "amount" ? (
                              <>
                                {numeroFormateado(
                                  cell.render("Cell").props.value
                                )}
                              </>
                            ) : cell.column.id === "billingDate" ? (
                              moment(cell.render("Cell").props.value).format(
                                "DD-MM-YYYY"
                              )
                            ) : cell.column.id === "status" ? (
                              <Chip
                                sx={{ background: "#DFF1E6", color: "#31B566" }}
                                label="Pagado"
                              />
                            ) : cell.column.id === "ticket" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  icon="heroicons-outline:download"
                                  fontSize={28}
                                  onClick={() =>
                                    downloadTicket(
                                      cell.render("Cell").props.value
                                    )
                                  }
                                />
                              </Box>
                            ) : (
                              cell.render("Cell")
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import { Box, Tab as Tb, styled, Grid, Tabs, Typography, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Brightness1Icon from "@mui/icons-material/Brightness1";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: 30,
    flexGrow: 1,
    "& .MuiTabs-flexContainer button": {
      minHeight: 25,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Tab = styled(Tb)(({ theme }) => ({
  minWidth: 105,
  borderBottom: `1px solid #767D89`,
  "&.MuiTab-root": {
    padding: "0 5px",
    fontSize: 14,
    fontWeight: 500,
    color: "black",
  },
  "&.Mui-selected": {
    borderBottom: `3px solid ${theme.palette.primary.selectMenu}`,
    fontWeight: 700,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 5,
  marginBottom: 1,
  borderBottom: "1px solid #D9D9D9",
  display: "flex",
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "60%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
}));

export default function InboxSignatureResponsive(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [newData, setNewData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setNewData(props.data);
  }, [props.data]);

  useEffect(() => {
    props.ClickSelectedRows(selectedRows)
  }, [selectedRows]);

  const filterData = (stage) => {
    const dataFilter = props.data?.filter(
      (item) => item.statusVigencia === stage
    );
    setNewData(dataFilter);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid
          item
          lg={12}
          xs={12}
          sx={{
            display: { xs: "flex", md: "center" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="disabled tabs example"
          >
            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("EnTiempo")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#6DB625" }}
                  />
                  Activos
                </Box>
              }
            />
            <Tab
              sx={{
                borderRight: `1px solid #767D89`,
                borderLeft: `1px solid #767D89`,
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("PorVencer")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#FBBF28" }}
                  />
                  Por vencer
                </Box>
              }
            />
            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => filterData("Vencido")}
                >
                  <Brightness1Icon
                    sx={{ fontSize: 15, mr: 1, color: "#C20E30" }}
                  />
                  Vencidos
                </Box>
              }
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        {newData?.map((item) => (
          <CardResponsiveFile data={item} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        ))}
      </Grid>
    </Box>
  );
}

const CardResponsiveFile = (props) => {

  const colorReturn = () => {
    if (props.data.statusVigencia === "EnTiempo") {
      return "#6DB625";
    } else if (props.data.statusVigencia === "PorVencer") {
      return "#ffdb7e";
    } else {
      return "#C20E30";
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          p: 1,
          m: 0.8,
          border: 1.5,
          borderColor: "#D9D9D9",
          borderRadius: 3,
        }}
      >
        <Box>
          <Checkbox
            onChange={(event) => {
              const { checked } = event.target;
              if (checked) {
                props.setSelectedRows([...props.selectedRows, props.data]);
              } else {
                props.setSelectedRows((prevSelectedItems) => prevSelectedItems.filter((item) => item.invoice !== props.data.invoice));
              }
            }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>
        <Box>
          <Typography variant="h6" sx={{ color: colorReturn() }}>
            Folio: {props.data?.invoice}
          </Typography>
        </Box>
        <Statements title={"Nombre documental"} text={props.data.product} />
        <Statements
          title={"Nombre del solicitante"}
          text={props.data.applicant}
        />
        <Statements
          title={"Fecha de creación"}
          text={props.data.creationDate}
        />
        <Statements title={"Fecha límite"} text={props.data.validity} />
        <Statements title={"Estatus"} text={props.data.status} />
      </Box>
    </Grid>
  );
};

const Statements = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle1" textAlign={"right"}>
          {props.text}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};

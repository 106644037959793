import { authenticationConstants } from "../constants";
import { AuthService } from "../../services/AuthService";
import { Alert, AlertConfirm } from "../../components/Alert";


export const UnAuthenticateWithRefer = (authData) => {
  console.log('authenticationData', authData)
  return async (dispatch) => {
    try {
      const authService = AuthService.getInstance();
      const authenticationData = await authService.UnAuthenticateWithRefer(
        authData
      );
      console.log('authenticationData', authenticationData)
      if (authenticationData.data.data.IsOK == true) {
        console.log('authenticationData 1', authData, authenticationData)
        dispatch(authentication(authData));
      } else {
        dispatch(failure(authenticationData.data.data.Messages));
        Alert({
          icon: "error",
          title: "Datos incorrectos",
          text: `Error en inicio de sesión. ${authenticationData.data.data.Messages}`
          //'El usuario o contraseña son incorrectos. Si no tienes una cuenta en DocSolutions, puedes registrarte ahora.',
        }, () => { window.grecaptcha.reset() });
      }
    } catch (error) {
      Alert({
        icon: "error",
        title: '',
        text: error.message,
      });
    }
  };

  function failure(error) {
    //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error }; // => carpeta constants => AuthConstants.js
  };
};

export const authentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      console.log('authData', authData)
      const authService = AuthService.getInstance();
      const verificationLogin = await authService.VerifyLogInValidity(
        {
          "Username": authData.Username,
          "Email": authData.Username
        },
      );
      console.log('verificationLogin', verificationLogin)
      if (verificationLogin.Body == false) {
        const authenticationData = await authService.authenticationEncripted(
          authData
        );
        console.log('authenticationData', authenticationData)
        dispatch(success(authenticationData.Body));
        sessionStorage.clear();
        sessionStorage.setItem(
          "token",
          authenticationData.Body.Token
        );
        sessionStorage.setItem(
          "userName",
          `${authenticationData.Body.UserLoginData.Name} ${authenticationData.Body.UserLoginData.FatherLastName ?? ''} ${authenticationData.Body.UserLoginData?.MotherLastName ?? ''}`
        );
        sessionStorage.setItem(
          "userNameDisplay",
          `${authenticationData.Body?.UserLoginData?.Name}`
        );
        sessionStorage.setItem(
          "Rol",
          `${authenticationData.Body.UserLoginData?.SecurityLoginData?.Roles[0]?.Name}`
        );
      } else if (verificationLogin.Body == true) {
        dispatch(resetAuth());
        AlertConfirm(
          {
            icon: "warning",
            title: "Ya cuentas con una sesion activa, ¿Deseas cerrarla e iniciar una nueva?",
            okbtntext: "Aceptar",
          },
          (result) => {
            if (result.isConfirmed) {
              console.log('authData', result)
              dispatch(request());
              dispatch(UnAuthenticateWithRefer(authData));
            } else if (result.dismiss === "backdrop" && result.isDismissed) {
            }
          }
        );
      }
    } catch (error) {
      console.log('errorLogin', error)
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Datos incorrectos",
        text: `Error en inicio de sesión. ${error.message}`
        //'El usuario o contraseña son incorrectos. Si no tienes una cuenta en DocSolutions, puedes registrarte ahora.',
      }, () => { window.grecaptcha.reset() });
    }
  };

  function request() {
    //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authenticationConstants.AUTHENTICATION_REQUEST }; // => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) {
    //Respuesta correcta de petición
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    }; // => carpeta constants => AuthConstants.js
  }

  function failure(error) {
    //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error }; // => carpeta constants => AuthConstants.js
  };

};


export const resetAuth = () => {
  return {
    type: authenticationConstants.AUTHENTICATION_RESET,
  };
};

export const noAuthentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      dispatch(success(authData));
    } catch (error) {
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Datos incorrectos",
        text: 'El usuario o contraseña son incorrectos. Si no tienes una cuenta en DocSolutions, puedes registrarte ahora.',
      });
    }
  };
  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }
  function success(authenticationData) {
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    };
  }
  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };

};

export const resetPassword = (newPass) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const resetPass = await authService.resetPassword(newPass);
      dispatch(success(resetPass));
      dispatch(user_pass(newPass));
      Alert({
        icon: "success",
        title: "Código enviado"
      });
    } catch (error) {
      console.log(error)
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };

  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }

  function success(resetData) {
    return {
      type: authenticationConstants.RESSET_PASSWORD_SUCCES,
      payload: resetData,
    };
  }

  function user_pass(user) {
    return {
      type: authenticationConstants.RESSET_PASSWORD_SUCCES,
      payload: user,
    };
  }

  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };

};

export const validatePIN = (pin) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const validate = await authService.validatePIN(pin);
      dispatch(success(validate));
      sessionStorage.removeItem('userPass');
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }

  function success(resetData) {
    return {
      type: authenticationConstants.VALIDATE_PIN,
      payload: resetData,
    };
  }

  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };
};

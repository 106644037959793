import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Box,
    styled,
    Typography,
    Grid,
    Tooltip,
    IconButton
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import VisorPDF from "../components/Viewer/VisorPDF";
// import VisorPDF from "../components/Viewer/VisorPDF";
import { resetPdf, DisableTemplate } from "../store/actions";
import { FileService } from "../services/FileService";
import { MassiveService } from "../services/MassiveService";
import { Alert } from "../components/Alert";
import IconUploadFile from "../assets/icons/IconUploadFile.png";
import BackdropComponent from "../components/BackDrop";

const RootStyle = styled(Box)(({ theme }) => ({
    margin: "0 auto",
    width: "100%",
    maxWidth: "95%",
    transition: "height 500ms ease",
}));

const DropZoneContainer = styled(Box)(({ theme }) => ({
    minHeight: 300,
    border: `dashed 2px ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
        opacity: 0.9,
        cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
        minHeight: 200,
    },
}));

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const b64toUrl = async (base64Data) => {
    if (base64Data === undefined || base64Data === null) {
        console.log("base64Data----------*-*-*-*-*-*-: ", base64Data)
        return null
    }
    const r = await fetch(base64Data);
    const blob = await r.blob();
    return URL.createObjectURL(blob);
};

const convertWordToPdf = async (wordB64) => {
    const fileService = FileService.getInstance();
    console.log("convertWordToPdf: Service****Request**await**wordB64: ", wordB64);
    const response = await fileService.convertWordToPdf(wordB64);
    console.log("convertWordToPdf: Service****response**await**: ", response);
    return response;
};

const GetMetadataUploadTemplate = async (File_Id, base64, extension, fileName) => {
    const request = {
        File_Id: File_Id,
        B64Content: base64,
        Extension: extension,
        FileName: fileName,
        ConvertDocumentToImage: true,
    }
    const massiveService = MassiveService.getInstance();
    console.log("GetMetadataUploadTemplate: Service****request**await**: ", request);
    const response = await massiveService.UploadTemplate(request);
    console.log("GetMetadataUploadTemplate: Service****response**await**: ", response);
    return response.Body;
};

const convertWordToB64 = async (file) => {
    const fileConverted = await toBase64(file);
    const base64result = fileConverted.split(",")[1];
    const wordToPdf = await convertWordToPdf(base64result);
    const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
    const wordUrl = await b64toUrl(wordPdfB64);
    return { base64result, wordUrl };
};

const convertFileToB64 = async (file) => {
    const fileConverted = await toBase64(file);
    const base64result = fileConverted.split(",")[1];
    const UrlResult = URL.createObjectURL(file);
    return { base64result, UrlResult };
};

const UploadTemplateFileToB64 = async (file, File_Id, extension) => {
    try {
        let fileName = file.name
        const fileConverted = await toBase64(file);
        let base64result = fileConverted.split(",")[1];
        let UrlResult = null;
        let MetaData = await GetMetadataUploadTemplate(File_Id, base64result, `.${extension}`, fileName).then((response) => {
            // console.log(".:GetMetadataUploadTemplate:. response: ", response)
            return response
        }).catch((error) => {
            console.log(".:GetMetadataUploadTemplate:. error: ", error)
            return error
        })
        console.log(".:UploadTemplateFileToB64:. MetaData : ", MetaData)
        if (MetaData.message !== undefined && MetaData.message !== null && MetaData.message !== "") {
            Alert({
                icon: "error",
                text: MetaData.message,
                okbtntext: "Aceptar",
            });
            return { base64result: null, UrlResult: null, MetaData: null }
        }
        // UrlResult = await b64toUrl(MetaData.Response.PdfB64ContentConvert);
        // base64result = MetaData.Response.PdfB64ContentConvert
        // return { base64result, UrlResult, MetaData };

        if (extension === "docx") {
            // console.log(".:UploadTemplateFileToB64:. peticion  convertWordToPdf: ")
            // const wordToPdf = await convertWordToPdf(base64result);
            // const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
            // UrlResult = await b64toUrl(wordPdfB64);
            console.log(".:UploadTemplateFileToB64:. MetaData : extension", extension, "MetaData.Response.PdfB64ContentConvert: ", MetaData.Response.PdfB64ContentConvert)
            const wordPdfB64 = `data:application/pdf;base64,${MetaData.Response.PdfB64ContentConvert}`;
            UrlResult = await b64toUrl(wordPdfB64);
        } else {
            UrlResult = URL.createObjectURL(file);
        }
        return { base64result, UrlResult, MetaData };

    } catch (e) {
        return { base64result: null, UrlResult: null, MetaData: null };
    }
};

export const UploadGenericControl = (props) => {
    const [file, setfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileUrl, setfileUrl] = useState(null);
    const [fileBase64, setfileBase64] = useState(null);
    const [parsingWordDoc, setparsingWordDoc] = useState(false)
    const [extensionCurrent, setExtensionCurrent] = useState(false);
    const [documentMetaDataCurrent, setDocumentMetaDataCurrent] = useState(null);
    const [removeSend, setRemoveSend] = useState(false);

    const fileLoader = useRef(null);
    const titleDocRef = useRef();

    const dispatch = useDispatch();
    const disableTemplateState = useSelector((state) => state.DisableTemplateData);

    const {
        register,
        formState,
        clearErrors,
        reset,
    } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        RemoveFileChange(false)
    }, [])

    useEffect(() => {
        console.log("disableTemplateState********** : ", disableTemplateState)
        if (disableTemplateState.items !== undefined && disableTemplateState.items !== null && removeSend) {
            RemoveFileChange(false)
            setLoading(false)
            setRemoveSend(false)
        } else if (disableTemplateState.error !== undefined && disableTemplateState.error !== null && removeSend) {
            setLoading(false)
            setRemoveSend(false)
            Alert({
                icon: "error",
                text: disableTemplateState.error,
                okbtntext: "Aceptar",
            });
        }
    }, [disableTemplateState]);

    const setWordToB64 = React.useCallback(async () => {
        try {
            setparsingWordDoc(true);
            if (file) {
                const { base64result, wordUrl } = await convertWordToB64(file);
                setfileBase64(base64result);
                console.log("convertWordToB64: ", wordUrl, base64result);
                setfileUrl(wordUrl);
                setLoading(false)
            }
        } catch (error) {
            setfileBase64(new Error("Error al convertir word"));
            console.log(error);
            setLoading(false)
        } finally {
            setparsingWordDoc(false);
        }
    }, [file]);

    const setPdfToB64 = React.useCallback(async () => {
        try {
            if (file) {
                const { base64result, UrlResult } = await convertFileToB64(file);
                setfileBase64(base64result);
                setfileUrl(UrlResult);
                console.log("convertFileToB64: ", UrlResult, base64result);
                setLoading(false)
            }
        } catch (error) {
            setfileBase64(new Error(`Error al convertir archivo .${extensionCurrent}`));
            console.log(error);
            setLoading(false)
        }
    }, [file]);

    const setUploadTemplate = React.useCallback(async () => {
        try {
            if (file) {
                const folioIdSolicitud = sessionStorage.getItem("FileId");
                const { base64result, UrlResult, MetaData } = await UploadTemplateFileToB64(file, folioIdSolicitud, extensionCurrent);
                if (base64result === null) {
                    setfile(null);
                    setExtensionCurrent(null)
                } else {
                    console.log(".:setUploadTemplate:. MetaData.Response: ", MetaData.Response)
                    let response = {
                        FileName: file.name?.replace(`.${extensionCurrent}`, ""),
                        Extension: extensionCurrent,
                        ...MetaData.Response
                    }
                    console.log(".:setUploadTemplate:. MetaData.Response: object custom response: ", response)
                    setDocumentMetaDataCurrent(response)
                    setfileBase64(base64result);
                    setfileUrl(UrlResult);
                }
                setLoading(false)
            }
        } catch (error) {
            setfileBase64(new Error(`setUploadTemplate Error .${extensionCurrent}`));
            console.log(error);
            setLoading(false)
        }
    }, [file]);

    const onDrop = (files) => {
        console.log("DOCUMENTO");
        console.log("files*******", files);
        if (files.length === 0) {
            Alert({
                icon: "error",
                text: `Por favor seleccione un tipo de documento válido ${props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt"}`,
                okbtntext: "Aceptar",
            });
            return null;
        } else {
            const extension = files[0]?.name?.slice((files[0]?.name.lastIndexOf(".") - 1 >>> 0) + 2)
            setExtensionCurrent(extension)
            console.log("Extension line 200: ", extension)
            if (props.Accept?.includes(extension)) {
                console.log("DOCUMENTO OK Accept ");
                setfile(files[0]);
            }
            else if ((props.Accept === undefined || props.Accept === null) &&
                (extension === "docx" || extension === "png" || extension === "pdf" || extension === "jpeg" || extension === "jpg")) {
                console.log("DOCUMENTO OK ");
                setfile(files[0]);
            } else {
                console.log("DOCUMENTO MALO ");
                Alert({
                    icon: "error",
                    text: `Por favor seleccione un tipo de documento válido ${props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt"}`,
                    okbtntext: "Aceptar",
                });
                return null;
            }
        }
    };

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        onDrop,
        noClick: true,
        accept: props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt",
    });

    let openFileChange = (event) => {
        fileLoader.current.value = null;
        fileLoader.current.click();
    };

    let getFileLoaded = (event) => {
        if (event.target.files && event.target.files[0]) {
            onDrop(event.target.files);
        }
    };

    useEffect(() => {
        if (!acceptedFiles) {
            Alert({
                icon: "error",
                text: `Por favor seleccione un tipo de documento válido  ${props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt"}`,
                okbtntext: "Aceptar",
            });
            return null;
        } else {
            setfile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (file) {
            const extension = file?.name?.slice((file?.name.lastIndexOf(".") - 1 >>> 0) + 2)
            console.log("Extension line 459: ", extension)
            setExtensionCurrent(extension)
            setLoading(true)
            if (props.UploadTemplate === true) {
                setUploadTemplate()
                clearErrors("pdf");
            }
            else if (extension === "docx") {
                setWordToB64();
                clearErrors("pdf");
            } else {
                setPdfToB64();
                clearErrors("pdf");
            }
            window.scrollTo(0, titleDocRef.current.offsetTop);
        }
    }, [file, clearErrors, setPdfToB64, setWordToB64]);

    useEffect(() => {
        if (props.onChange !== undefined && fileBase64 !== undefined && fileBase64 !== null && fileBase64 !== ""
            && fileUrl !== undefined && fileUrl !== null && fileUrl !== "") {
            props.onChange(file, fileBase64, extensionCurrent, fileUrl, documentMetaDataCurrent)
        } else {
            if (props.onChange !== undefined && props.onChange !== null) {
                props.onChange(null, null, null, null, null)
            } else if (props.onChange === undefined || props.onChange === null) {
                Alert({
                    icon: "error",
                    text: `No se encontró evento onChange en el control UploadGenericControl`,
                    okbtntext: "Aceptar",
                });
            }
        }
    }, [fileBase64, fileUrl]);

    const RemoveFileChange = (disableTemplateActive = true) => {
        console.log("RemoveFileChange disableTemplateActive,props.UploadTemplate: ", disableTemplateActive, props.UploadTemplate)
        if (props.UploadTemplate === true && disableTemplateActive) {
            // Manda la peticion para la eliminacion del archivo
            let request = {
                BatchUploadDocument_Id: documentMetaDataCurrent?.BatchUploadDocument_Id
            }
            console.log("DisableTemplate request:  ", request)
            dispatch(DisableTemplate(request))
            setLoading(true)
            setRemoveSend(true)
        } else {
            dispatch(resetPdf());
            setfile(null);
            setfileUrl(null);
            setfileBase64(null);
            reset();
        }
    };

    const RenderViewControl = (props) => {
        if (props.ShowDocumentPreview == false) {
            return (<></>)
        }
        else if (extensionCurrent == "pdf" || extensionCurrent == "docx") {
            return (<Grid item xs={12}>
                <VisorPDF
                    parsingDoc={parsingWordDoc}
                    pdfUrl={fileUrl}
                    pdfName={file?.name}
                    changeFile={RemoveFileChange}
                />
            </Grid>)
        }
        else if (extensionCurrent?.includes("png") || extensionCurrent?.includes("jpg") || extensionCurrent?.includes("jpeg")) {
            return (<Box sx={{ width: "100%", display: "flex", flexDirection: "column", position: 'relative', backgroundColor: "transparent", mt: 3 }}>
                <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingRight: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                }}>
                    <Tooltip title="Eliminar archivo" >
                        <Icon onClick={() => {
                            setfileUrl(null);
                            setfileBase64(null);
                            setfile(null);
                        }} height={19} width={19} color="#FFFFFF" icon="material-symbols:delete-outline-rounded" />
                    </Tooltip>
                </Box>
                {console.log("url de image view: ", fileUrl)}
                <img style={{ width: '100%' }}
                    src={fileUrl}
                    alt="imagen" />
            </Box>)
        } else {
            return (<Box sx={{ width: "100%", display: "flex", flexDirection: "column", position: 'relative', backgroundColor: "transparent", mt: 3 }}>
                <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingRight: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                }}>
                    <Tooltip title="Eliminar archivo" >
                        <Icon onClick={() => {
                            setfileUrl(null);
                            setfileBase64(null);
                            setfile(null);
                        }} height={19} width={19} color="#FFFFFF" icon="material-symbols:delete-outline-rounded" />
                    </Tooltip>
                </Box>
                <Typography variant="titulo" >
                    Documento cargado
                </Typography>
                <Typography align="center" variant="body2" sx={{ backgroundColor: "secondary.warningAlert", }} >
                    {`Documento ${file?.name} con extensión .${extensionCurrent} no soportado para vista previa.`}
                </Typography>
            </Box>)
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <RootStyle>
                <BackdropComponent loading={loading} />
                <Box ref={titleDocRef}>
                    <input
                        type="file"
                        hidden
                        ref={fileLoader}
                        accept={props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt"}
                        onChange={getFileLoaded}
                    />
                    <Grid container spacing={2}>
                        {file && fileUrl ?
                            RenderViewControl(props)
                            : (
                                <>
                                    <Grid item xs={12} /* sm={6} */>
                                        <DropZoneContainer
                                            {...getRootProps()}
                                            up
                                            sx={{
                                                mb: 5,
                                                mt: 3,
                                                outline: formState.errors.pdf
                                                    ? "2px solid #C20E30"
                                                    : "none",
                                                outlineOffset: formState.errors.pdf ? -2 : 0,
                                            }}
                                        >
                                            <input
                                                {...getInputProps()}
                                                accept={".docx, .pdf, .sfdt"}
                                                style={{ display: "none" }}
                                                {...register("pdf", { required: true })}
                                            />
                                            <Box sx={(theme) => ({
                                                display: 'flex', flexDirection: 'row', alignContent: 'center',
                                                alignItems: 'center', alignSelf: 'center', [theme.breakpoints.down("sm")]: {
                                                    flexDirection: 'column'
                                                }
                                            })}>
                                                <IconButton style={{ padding: 2, width: 50, height: 50, margin: 8 }}
                                                    onClick={openFileChange}
                                                    title="Descargar plantilla" color="secondary" >
                                                    <img alt="Descargar plantilla" src={IconUploadFile} />
                                                </IconButton>
                                                <Typography
                                                    align="center"
                                                    variant="body2"
                                                >
                                                    Arrastre su documento con extensión <b>{props.Accept ?? ".pdf, .docx, .png, .jpg, .jpeg, .sfdt"}</b> o
                                                </Typography>
                                                <Button
                                                    onClick={openFileChange}
                                                    color="primary"
                                                    variant="text"
                                                    sx={{
                                                        maxWidth: 380,
                                                        fontWeight: "normal",
                                                        minheight: 38,
                                                        padding: "5px 10px",
                                                        m: "10px 5px",
                                                    }}
                                                >
                                                    da clic aquí para seleccionar uno
                                                </Button>
                                            </Box>
                                            {formState.errors.pdf && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    color="#C20E30"
                                                >
                                                    Documento necesario*
                                                </Typography>
                                            )}
                                        </DropZoneContainer>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </Box>
            </RootStyle>
        </LocalizationProvider>
    );
};
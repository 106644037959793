import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { UTCtoLocal } from "../../utils/Tools";
import IconToolTip from "../../components/IconToolTip";

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontWeight: 400,
    padding: "8px",
    width: 300,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 300,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontWeight: 400,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: `1px solid ${theme.palette.primary.main60}`,
    },
  },
}));

const headCells = [
  {
    id: "CheckOrder",
    numeric: true,
    disablePadding: false,
    label: "Configuración",
    width: 70,
  },
  {
    id: "CheckOrder",
    numeric: true,
    disablePadding: false,
    label: "Configuración",
    width: 60,
  },
  {
    id: "CheckOrder",
    numeric: true,
    disablePadding: false,
    label: "Configuración",
    width: 60,
  },
  {
    id: "SignOrder",
    numeric: true,
    disablePadding: false,
    label: "Orden",
    width: 40,
  },
  {
    id: "SignDeadlineFormat",
    numeric: false,
    disablePadding: false,
    label: "Configuración",
    width: 75,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell1
          rowSpan={2}
          key="FullName"
          align="center"
          sortDirection={orderBy === "FullName" ? order : false}
        >
          <TableSortLabel
            style={{ textAling: "center" }}
            sx={{ minWidth: 180 }}
            active={orderBy === "FullName"}
            direction={orderBy === "FullName" ? order : "asc"}
            onClick={createSortHandler("FullName")}
          >
            <b>Nombre del participante</b>
          </TableSortLabel>
        </StyledTableCell1>
        <StyledTableCell2 colSpan={1} align="center">
          <b>Requisitos Previos</b>
        </StyledTableCell2>
        <StyledTableCell2 colSpan={1} align="center">
          <b>Revisión</b>
        </StyledTableCell2>
        <StyledTableCell2 colSpan={1} align="center">
          <b>Captura</b>
        </StyledTableCell2>
        <StyledTableCell2 colSpan={2} align="center">
          <b>Firmado</b>
        </StyledTableCell2>
      </TableRow>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell1
            key={headCell.id}
            align={"center"}
            sx={{ minWidth: headCell.width }}
          >
            <b>{headCell.label}</b>
          </StyledTableCell1>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ParticipantSortTable(props) {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [templateActiveData, setTemplateActiveData] = useState(false);
  const typoSing = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
  );
  const typoSingEdit = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  const dataPrerre = useSelector((state) => state.File?.item?.Prerequisites);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setRows(props.listRows);
  }, [props.listRows]);

  const filtroPreviosUpload = props.listRows?.filter((item) =>
    dataPrerre?.UploadPrerequisitesUserEmail !== null
      ? dataPrerre?.UploadPrerequisitesUserId === item?.UserId
      : null
  );

  const filtroPreviosApproval = props.listRows?.filter((item) =>
    dataPrerre.PrerequisiteApprovalUserEmail !== null
      ? dataPrerre?.PrerequisiteApprovalUserId === item?.UserId
      : null
  );

  useEffect(() => {
    if (rows.length > 0) {
      const statusTemplate = rows.some((objeto) => objeto.Capture === true);
      setTemplateActiveData(statusTemplate);
    }
  }, [rows]);

  console.log("rowsrows", rows);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            className={classes.table}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              templateActiveData={templateActiveData}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.index}>
                  <StyledTableCell1 component="th" scope="row" align="center">
                    {row.FullName || row.Email}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        {dataPrerre?.PrerequisiteApprovalUserId ===
                        row?.UserId ? (
                          <IconToolTip
                            src={"Aprobador.png"}
                            title={"Usuario Aprobador."}
                            width={25}
                          />
                        ) : dataPrerre?.UploadPrerequisitesUserId ===
                          row?.UserId ? (
                          <IconToolTip
                            src={"Cargador.png"}
                            title={"Usuario a subir documentos."}
                            width={25}
                          />
                        ) : (
                          <b>-</b>
                        )}
                      </Box>
                      <Box>
                        {dataPrerre?.PrerequisiteApprovalUserId ===
                        row?.UserId ? (
                          UTCtoLocal(
                            dataPrerre?.UploadPrerequisitesDeadline,
                            false
                          )
                        ) : dataPrerre?.UploadPrerequisitesUserId ===
                          row?.UserId ? (
                          UTCtoLocal(
                            dataPrerre?.UploadPrerequisitesDeadline,
                            false
                          )
                        ) : (
                          <b>-</b>
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        {row.Reviewer === true ? (
                          <IconToolTip
                            src={"AprobadorRevision.png"}
                            title={"Usuario Aprobador."}
                            width={25}
                          />
                        ) : row.Check === true ? (
                          <IconToolTip
                            src={"Revisor.png"}
                            title={"Usuario Editor."}
                            width={25}
                          />
                        ) : (
                          <b>-</b>
                        )}
                      </Box>
                      <Box>
                        {UTCtoLocal(
                          row.CheckDeadline || row.ReviewerDeadLine,
                          false
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        {row.Capture === true ? (
                          <IconToolTip
                            src={"CaptureIcon.png"}
                            title={"Usuario Capturador."}
                            width={23}
                          />
                        ) : (
                          <b> - </b>
                        )}
                      </Box>
                      <Box>
                        {row.Capture === true ? (
                          row.CaptureDeadlineFormat
                        ) : (
                          <b> - </b>
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    {row.SignOrder}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        {row.SignatureOptions ? (
                          row.SignatureOptions.SignatureType === "Simple" ? (
                            <Box
                              sx={{
                                minWidth: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconToolTip
                                icon={true}
                                src={"teenyicons:otp-outline"}
                                title={"Contraseña de un solo uso."}
                                width={19}
                              />
                              <IconToolTip
                                src={"SignSimple.png"}
                                title={"Firma Autógrafa en Mensaje de Datos."}
                                width={24}
                              />
                              <IconToolTip
                                src={
                                  row.SignatureOptions?.VideoRecording
                                    ? "VideoIcon.svg"
                                    : "VideoIconDisabled.svg"
                                }
                                title={"Videograbación de consentimiento."}
                                width={24}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                minWidth: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconToolTip
                                icon={true}
                                iconColor={
                                  row?.SignatureOptions?.OTP
                                    ? "#D65E74"
                                    : "#979797"
                                }
                                src={"teenyicons:otp-outline"}
                                title={"Contraseña de un solo uso."}
                                width={19}
                              />
                              <IconToolTip
                                src={"SignAvanzada.png"}
                                title={"Firma Electrónica Avanzada."}
                                width={22}
                              />
                              <IconToolTip
                                src={
                                  row?.SignatureOptions?.VideoRecording
                                    ? "VideoIcon.svg"
                                    : "VideoIconDisabled.svg"
                                }
                                title={"Videograbación de consentimiento."}
                                width={24}
                              />
                            </Box>
                          )
                        ) : row.Sign ? (
                          typoSing?.SignatureType === "Simple" ||
                          typoSingEdit?.SignatureType === "Simple" ? (
                            <Box
                              sx={{
                                minWidth: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconToolTip
                                icon={true}
                                src={"teenyicons:otp-outline"}
                                title={"Contraseña de un solo uso."}
                                width={19}
                              />
                              <IconToolTip
                                src={"SignSimple.png"}
                                title={"Firma Autógrafa en Mensaje de Datos."}
                                width={24}
                              />
                              <IconToolTip
                                src={
                                  typoSingEdit?.VideoRecording ||
                                  typoSing?.VideoRecording
                                    ? "VideoIcon.svg"
                                    : "VideoIconDisabled.svg"
                                }
                                title={"Videograbación de consentimiento."}
                                width={24}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                minWidth: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconToolTip
                                icon={true}
                                src={"teenyicons:otp-outline"}
                                title={"Contraseña de un solo uso."}
                                width={19}
                                iconColor={
                                  typoSingEdit?.OTP || typoSing?.OTP
                                    ? "#D65E74"
                                    : "#979797"
                                }
                              />
                              <IconToolTip
                                src={"SignAvanzada.png"}
                                title={"Firma Electrónica Avanzada."}
                                width={24}
                              />
                              <IconToolTip
                                src={
                                  typoSingEdit?.VideoRecording ||
                                  typoSing?.VideoRecording
                                    ? "VideoIcon.svg"
                                    : "VideoIconDisabled.svg"
                                }
                                title={"Videograbación de consentimiento."}
                                width={24}
                              />
                            </Box>
                          )
                        ) : (
                          <b>-</b>
                        )}
                      </Box>
                      <Box>{UTCtoLocal(row.SignDeadline, false)}</Box>
                    </Box>
                  </StyledTableCell1>
                </TableRow>
              ))}

              {dataPrerre.PrerequisiteApprovalUserEmail ===
              null ? null : filtroPreviosApproval?.length !== 0 ? null : (
                <TableRow hover tabIndex={-1}>
                  <StyledTableCell1 component="th" scope="row" align="center">
                    {dataPrerre?.PrerequisiteApprovalUserFullName}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        <IconToolTip
                          src={"Aprobador.png"}
                          title={"Usuario Aprobador."}
                          width={25}
                        />
                      </Box>
                      <Box>
                        {UTCtoLocal(
                          dataPrerre?.UploadPrerequisitesDeadline,
                          false
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                </TableRow>
              )}

              {dataPrerre.UploadPrerequisitesUserEmail ===
              null ? null : filtroPreviosUpload?.length !== 0 ? null : (
                <TableRow hover tabIndex={-1}>
                  <StyledTableCell1 component="th" scope="row" align="center">
                    {dataPrerre?.UploadPrerequisitesUserFullName}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        <IconToolTip
                          src={"Cargador.png"}
                          title={"Usuario a subir documentos."}
                          width={25}
                        />
                      </Box>
                      <Box>
                        {UTCtoLocal(
                          dataPrerre?.UploadPrerequisitesDeadline,
                          false
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

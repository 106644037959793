import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import VisorPDF from "../../components/Viewer/VisorPDF";
import BackdropComponent from "../../components/BackDrop";
import { FileService } from "../../services/FileService.js";
import { DocumentService } from "../../services/DocumentService.js";
import { Icon } from "@iconify/react";
import { B64toBlob } from "../../utils/Tools.js";

export const MassiveFlowDocumentViewer = (props) => {
  const [listDocumentFieldData, setlistDocumentFieldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urlImagen, setUrlImagen] = useState(null);

  // Control de estados
  const fileState = useSelector((state) => state.File);
  const RelatedMassiveFileDataState = useSelector(
    (state) => state.File?.item?.RelatedMassiveFileData
  );

  useEffect(() => {
    console.log("RelatedMassiveFileDataState: ", RelatedMassiveFileDataState);
    if (RelatedMassiveFileDataState?.length > 0) {
      let listDocuments = [];
      try {
      async function fetchData() {
        await RelatedMassiveFileDataState.forEach(async (item) => {
          const documentServiceInstance = DocumentService.getInstance();
          let comentData =
            await documentServiceInstance.GetDocumentToSignContent({
              File_Id: item.Id,
            });
          if (comentData.Body) {
            const blob = await B64toBlob(
              comentData?.Body?.B64Content,
              "application/pdf"
            );
            listDocuments.push({
              urlBob: URL.createObjectURL(blob),
              Name: `${comentData?.Body?.FileName} - ${item.Id}`,
            });
          }
        });
      }
      fetchData();
      setlistDocumentFieldData(listDocuments);
    } catch (error) {
        console.log('errorerror', error )
    }
    }
  }, [RelatedMassiveFileDataState]);

  useEffect(() => {
    if (fileState?.item?.FileData?.Id > 0) {
      traerCodigoQR();
    }
  }, [fileState?.item?.FileData?.Id]);

  const traerCodigoQR = async () => {
    const fileService = FileService.getInstance();
    const comentData = await fileService.getFileQR({
      idFile: fileState?.item?.FileData?.Id,
      token: sessionStorage.token,
    });
    console.log('comentData 1', comentData )
    const url = window.URL.createObjectURL(new Blob([comentData.data.data]));
    setUrlImagen(url);
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            //border: "1px solid #E4E4E4",
            p: 1,
            mt: 0,
          }}
        >
          <Grid item sx={{ mt: 0 }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Documentos a firmar
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 300 }}>
              Los siguientes documentos representan la versión final, si estás
              de acuerdo presiona el botón “firmar”.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            //border: "1px solid #E4E4E4",
            p: 0,
            mt: 0,
          }}
        >
          {listDocumentFieldData?.map((itemData, index) => (
            <Accordion
              disableGutters
              defaultExpanded={index == 0} /// onChange={() => { handleAccordionChange(itemData) }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  p: 0,
                  "&.Mui-expanded": {
                    color: "#C20E30" /* Cambia este color por el que desees */,
                    borderRadius: "3px",
                    border: "1px solid #C20E30",
                    background: "rgba(194, 14, 48, 0.15)",
                  },
                  "& .MuiAccordionSummary-content": {
                    m: 0,
                  },
                }}
              >
                <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
                  <Icon
                    icon="solar:document-broken"
                    fontSize={23}
                    style={{ padding: 1, marginRight: 2 }}
                  />
                  <Typography
                    variant="titulo"
                    sx={{ fontSize: "20px !important" }}
                  >
                    {itemData.Name}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 0,
                    flexWrap: { xs: "wrap" },
                  }}
                >
                  {itemData.urlBob ? (
                    <VisorPDF
                      pdfUrl={itemData.urlBob ?? ""}
                      pdfName={itemData.Name ?? ""}
                    />
                  ) : (
                    <Spinner />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};

import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import noresult from "../../assets/noresults.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "50vh",
  width: "100%",
  display: "grid",
  placeContent: "center",
  padding: 15,
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: 200,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: 130,
  },
}));

export const NoResults = () => {
  const navigate = useNavigate();
  const stateRedux = useSelector((state) => state);

  let rol =
    stateRedux?.Authentication?.items?.UserLoginData?.SecurityLoginData
      ?.Roles[0]?.Name;

  return (
    <RootStyle>
      <Image alt="lupa" src={noresult} />
      <Typography align="center" mt={3} variant="subtitle1">
        No tienes pendientes
      </Typography>
      <Typography align="center" variant="body1">
        No hay expedientes que requieran de tu participación para completar su
        proceso.
      </Typography>
      {rol === "Usuario Autoconsumo" || rol === "Administrador" ? (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{height: 38, minWidth: 300}}
            onClick={() => navigate("/inbox/new-file")}
          >
            Crea un expediente
          </Button>
        </Box>
      ) : null}
    </RootStyle>
  );
};

import { Card, CardContent, Divider, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { numeroFormateado } from "../../../utils";

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 18,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    marginTop: 10,
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "50%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  textAlign: "right",
  width: "50%",
  mt: 0.3,
  mr: 5,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "left",
    fontWeight: 400,
  },
}));

export default function PurchaseData() {
  const statePlanSelect = useSelector(
    (state) => state.GetPlansPayment?.planUserSeleted
  );

  const [fechaActual, setFechaActual] = useState(moment());

  useEffect(() => {
    if (statePlanSelect?.periodicity == "Mensual") {
      setFechaActual(moment().clone().add(1, "months"));
    } else if (statePlanSelect?.periodicity == "Anual") {
      setFechaActual(moment().clone().add(1, "years"));
    }
  }, [statePlanSelect]);

  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        background: "#858585",
        borderRadius: "10px",
      })}
    >
      <CardContent sx={{ height: "100%", padding: "1em" }}>
        <Typography
          variant="titulo"
          sx={{ color: "#FFFFFF", padding: "1.5em 0em" }}
        >
          {statePlanSelect?.name}
        </Typography>
        <br />
        <Typography
          variant="caption"
          sx={{ color: "#FFFFFF", fontWeight: 400 }}
        >
          Tu plan tiene una vigencia de {statePlanSelect?.validityDays} días
          a partir de hoy
        </Typography>
        <br />

        <BoxRexponsiveText
          Title={"Vigencia de tu plan:"}
          Subtitle={`${fechaActual.format("DD-MM-YYYY")}`}
        />
        <br />

        <BoxRexponsiveText
          Title={"Número de solicitudes:"}
          Subtitle={statePlanSelect?.maxTransactions}
        />
        <br />

        <BoxRexponsiveText
          Title={"Precio del plan:"}
          Subtitle={`${numeroFormateado(statePlanSelect?.price)}/MXN`}
        />
        <br />
        <BoxRexponsiveText
          Title={"IVA:"}
          Subtitle={`${numeroFormateado(statePlanSelect?.iva)}/MXN`}
        />

        <br />
        <Box sx={{ mt: 1.3 }}>
          <Divider style={{ color: "#FFFFFF" }} />
        </Box>
        <BoxRexponsiveText
          Title={"Total a pagar:"}
          Subtitle={`${numeroFormateado(statePlanSelect?.totalPay)}/MXN`}
        />
      </CardContent>
    </Card>
  );
}

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography
          variant="subtitle"
          sx={{ color: "#FFFFFF", fontWeight: 450 }}
        >
          {props.Title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography
          variant="subtitle"
          sx={{ color: "#FFFFFF", fontWeight: 450 }}
        >
          {props.Subtitle}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Button, Box, styled, Typography, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { useFormContext } from "react-hook-form";
import { Alert } from "../../components/Alert";
import { b64toUrl, convertWordToPdf } from "../../utils";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: 1000,
}));

const DropZoneContainer = styled(Box)(({ theme }) => ({
  minHeight: 300,
  border: `dashed 2px ${theme.palette.primary.main}`,
  borderRadius: 10,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "&:hover": {
    opacity: 0.9,
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 200,
  },
}));

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const convertWordToB64 = async (file) => {
  const fileConverted = await toBase64(file);
  const base64result = fileConverted.split(",")[1];
  const wordToPdf = await convertWordToPdf(base64result);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return { base64result, wordUrl };
};

const convertPdfToB64 = async (file) => {
  const fileConverted = await toBase64(file);
  const base64result = fileConverted.split(",")[1];
  const wordUrl = URL.createObjectURL(file);
  return { base64result, wordUrl };
};

export const UploadDcuments = (props) => {
  const [file, setfile] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [fileBase64, setfileBase64] = useState(null);
  const [parsingWordDoc, setparsingWordDoc] = useState(false);
  const [extensionChange, setExtensionChange] = useState(null);
  //init, select, template, upload

  const fileLoader = useRef(null);
  const titleDocRef = useRef();

  const { register, formState, clearErrors, reset } = useFormContext();

  const onDrop = (files) => {
    if (files.length === 0) {
      Alert({
        icon: "error",
        text: `Por favor seleccione un tipo de documento válido  .jpeg, .jpg, .png, .docx o .pdf`,
        okbtntext: "Aceptar",
      });
      return null;
    } else {
      const extension = files[0]?.name?.slice((files[0]?.name.lastIndexOf(".") - 1 >>> 0) + 2)
      if (extension === "pdf" || extension === "png" || extension === "jpg" || extension === "docx" || extension === "jpeg") {
        setfile(files[0]);
      } else {
        props.setOpenModalFile(false)
        Alert({
          icon: "error",
          text: `Por favor seleccione un tipo de documento válido .jpeg, .jpg, .png, .docx o .pdf`,
          okbtntext: "Aceptar",
        });
        return null;
      }
    }
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    noClick: true,
    accept: ".png,.jpg,.jpeg,.pdf,.docx"
    ///".pdf, .docx, .sfdt",
  });

  const setWordToB64 = React.useCallback(async () => {
    try {
      setparsingWordDoc(true);
      if (file) {
        const { base64result, wordUrl } = await convertWordToB64(file);
        setfileBase64(base64result);
        setfileUrl(wordUrl);
      }
    } catch (error) {
      setfileBase64(new Error("Error al convertir word"));
      console.log(error);
    } finally {
      setparsingWordDoc(false);
    }
  }, [file]);

  const setPdfToB64 = React.useCallback(async () => {
    try {
      if (file) {
        const { base64result, wordUrl } = await convertPdfToB64(file);
        setfileBase64(base64result);
        setfileUrl(wordUrl);
      }
    } catch (error) {
      setfileBase64(new Error("Error al convertir pdf"));
      console.log(error);
    }
  }, [file]);

  useEffect(() => {
    if (!acceptedFiles) {
      Alert({
        icon: "error",
        text: `Por favor seleccione un tipo de documento válido válido  .jpeg, .jpg, .png, .docx o .pdf`,
        okbtntext: "Aceptar",
      });
      return null;
    } else {
      setfile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (file) {
      const splitted = file.name.split(".");
      const extention = splitted[splitted.length - 1];
      setExtensionChange(extention);
      if (["docx"].find((e) => e === extention)) {
        setWordToB64();
        clearErrors("pdf");
      } else {
        setPdfToB64();
        clearErrors("pdf");
      }
      window.scrollTo(0, titleDocRef.current.offsetTop);
    }
  }, [file, clearErrors, setPdfToB64, setWordToB64]);

  useEffect(() => {
    if (fileBase64 !== undefined && fileBase64 !== null && fileBase64 !== "") {
      props.onChange(fileBase64, extensionChange);
    } else {
      if (props.onChange !== undefined && props.onChange !== null) {
        props.onChange(null, null);
      }
    }
  }, [fileBase64]);

  let openFileChange = (event) => {
    fileLoader.current.value = null;
    fileLoader.current.click();
  };

  let getFileLoaded = (event) => {
    if (event.target.files && event.target.files[0]) {
      onDrop(event.target.files);
    }
  };

  const changeFile = () => {
    setfile(null);
    setfileUrl(null);
    setfileBase64(null);
    reset();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        <input
          type="file"
          hidden
          ref={fileLoader}
          accept=".docx,.png,.jpg,.jpeg,application/pdf"
          onChange={getFileLoaded}
        />
        <>
          <Box ref={titleDocRef}>
            <Grid container spacing={2}>
              {file ? (
                <Grid item xs={12}>
                  {extensionChange === "pdf" || extensionChange === "docx" ?
                    <VisorPDF
                      parsingDoc={parsingWordDoc}
                      pdfUrl={fileUrl}
                      pdfName={file?.name}
                      changeFile={changeFile}
                      height="422px"
                    /> : <img style={{ width: '100%' }}
                      src={fileUrl}
                      alt="documento" />}

                </Grid>
              ) : (
                <Grid item xs={12}>
                  <DropZoneContainer
                    {...getRootProps()}
                    up
                    sx={{
                      mb: 5,
                      mt: 3,
                      outline: formState.errors.pdf
                        ? "2px solid #C20E30"
                        : "none",
                      outlineOffset: formState.errors.pdf ? -2 : 0,
                    }}
                  >
                    <input
                      {...getInputProps()}
                      accept={".pdf, .png, .jpg, .jpeg"}
                      style={{ display: "none" }}
                      {...register("pdf", { required: true })}
                    />
                    <Typography align="center" variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
                      Arrastra tu documento con extensión <b>.png, .jpeg, .docx </b> o{" "}
                      <b>.pdf</b>
                    </Typography>
                    <Typography align="center" variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
                      o
                    </Typography>
                    <Button
                      onClick={openFileChange}
                      variant="contained"
                      sx={{
                        maxWidth: 380,
                        fontWeight: "normal",
                        minheight: 38,
                        padding: "5px 10px",
                        m: "10px auto",
                      }}
                    >
                      Selecciónalo desde tu equipo
                    </Button>
                    {formState.errors.pdf && (
                      <Typography variant="body1" align="center" color="#C20E30">
                        Documento necesario*
                      </Typography>
                    )}
                  </DropZoneContainer>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      </RootStyle>
    </LocalizationProvider>
  );
};

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useDispatch, useSelector } from "react-redux";
import { getStageRecords } from '../../store/actions/RecordActions';
import { useTable, useSortBy } from "react-table";
import { styled, TableHead, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useEffect } from 'react';
import { UTCtoLocal } from '../../utils';

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  {
    Header: "Proceso",
    accessor: "process",
  },
  {
    Header: "Usuario Asignado",
    accessor: "assignedUser",
  },
  {
    Header: "Estatus de ejecución",
    accessor: "status",
  },
  {
    Header: "Fecha de vencimiento",
    accessor: "validity",
  },
  {
    Header: "Resultado",
    accessor: "result",
  },
  {
    Header: "Fecha y hora",
    accessor: "date",
  },
];

export default function HistoryFile() {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8)
  const stateFile = useSelector((state) => state.File);
  const historyData = useSelector((state) => state.File.record);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    dispatch(getStageRecords({ 'File_Id': stateFile?.item?.FileData?.Id }));
  }, [])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, hiddenColumns: ["stage"] },
      },
      useSortBy
    );

  useEffect(() => {
    if (historyData) {
      const filesTable = historyData?.map((file) => {
        return {
          process: file.StageName,
          assignedUser: file.CreationUser,
          status: file.FlowState,
          validity: UTCtoLocal(file.Vigencia, false) ,
          result: file.State,
          date: UTCtoLocal(file.CreationDateFormatted, true),
        };
      });
      setData(filesTable);
    } else {
      setData([]);
    }
  }, [historyData]);

  return (
    <TableContainer component={Paper}>
      <Table  {...getTableProps()} sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
        <TableHead sx={{ backgroundColor: "primary.main" }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{ padding: .8, paddingLeft: 3 }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px"
                    }}
                  >
                    <span>
                      {column.isSorted ? (
                        !column.isSortedDesc ? (
                          <ArrowUpward
                            fontSize="small"
                            sx={{ mr: 1, mt: '5px' }}
                          />
                        ) : (
                          <ArrowDownward
                            fontSize="small"
                            sx={{ mr: 1, mt: '5px' }}
                          />
                        )
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{
                            mr: 1,
                            mt: '5px',
                            color: 'primary.mainLyrics'
                          }}
                        />
                      )}
                    </span>
                    <Typography
                      variant="subtitle1"
                      sx={{ minWidth: 80, fontWeight: 500, color: 'primary.mainLyrics' }}
                    >
                      {column.render("Header")}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => {
            prepareRow(row);
            return (
              <TableRowStyled
                {...row.getRowProps()}
                sx={{
                  borderBottom: "none !important",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        padding: 1.5,
                        paddingLeft: 3,
                        borderBottom: "none",
                        fontSize: "14px !important",
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRowStyled>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[0]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

import { combineReducers } from "redux";
import { Authentication } from "./AuthenticationReducer"; //Importar reducer
import { Employees } from "./EmployeesReducer";
import {
  Files, TextEvidence, ReorderParticipantData, RegisterPrerequisitesData, IntegrateDocumentToSignData, RegisterDocumentFieldsData,
  GetFilesCustomMasiveData, RegisterBatchUploadSignedProcessData, UploadMetadataBatchFileFCData
} from "./FileReducer";
import { File } from "./ExpedienteReducer";
import { NewFile } from "./NewFileReducer";
import { FileFlow, UploadVideoUser } from "./FileFlowReducer";
import { WorkFlowData, CancelWFData } from "./WorkFlowReducer";
import { SetDocValidationData } from "./ValidationReducer";
import { GenericData } from "./GenericReducer";
import {
  DataPaymentClient, GetPlansPayment,
  DataPaymentGetBillings, RegisterBillingInformation,
  GetRegimenFiscal, CancelSuscription,
  GetUseCfdi,
} from './PaymentsReducer'
import { ConfigStripe, RegisterProjectClientPlan } from "./StripeReducer"
import {
  ParticipantsMassive, SkipPrerequisitesData, UploadTemplateData, UploadPrerequisitesUsers, UploadTemplateMetaData,
  DisableTemplateData, UploadMetadataTemplateData, UploadMetadataBatchFileData, ListDocStackMassiveData, ReorderParticipantMassive
} from "./MassiveReducer"
import { QuestionsTypesData } from "./ProductReducer"
import {
  RegDocFieldData, UploadTemplateManagerData, InheritTraceSignDocuments
} from "./DocumentReducer"
import { UsersData } from "./UserReducer"
import {
  TemplatesData, GetContentTemplateData, GetTemplatesData, GetTemplatesActiveData, GetCategoriesTemplateData,
  DeleteLogicTemplate, DeleteLogicCategoriesTemplate
} from "./TemplatesReducer"

import {
  GetGraphicsByLastMonth, GetGraphicsByApplicant,
  GetGraphicsByTypeSign, GetGraphicsByStatusDeadLine,
  GetFilesByStatus, GetFilesChartCurrentMonth
} from "./GraphicsReducer"

const rootReducer = combineReducers({
  Authentication,
  Employees,
  Files,
  NewFile,
  File,
  FileFlow,
  TextEvidence,
  ReorderParticipantData,
  RegisterPrerequisitesData,
  WorkFlowData,
  SetDocValidationData,
  CancelWFData,
  GenericData,
  DataPaymentClient,
  GetPlansPayment,
  CancelSuscription,
  ConfigStripe,
  RegisterProjectClientPlan,
  DataPaymentGetBillings,
  RegisterBillingInformation,
  GetRegimenFiscal,
  ParticipantsMassive,
  SkipPrerequisitesData,
  UploadTemplateData,
  UploadTemplateMetaData,
  DisableTemplateData,
  UploadMetadataTemplateData,
  UploadMetadataBatchFileData,
  ListDocStackMassiveData,
  QuestionsTypesData,
  UploadPrerequisitesUsers,
  RegDocFieldData,
  IntegrateDocumentToSignData,
  RegisterDocumentFieldsData,
  ReorderParticipantMassive,
  GetFilesCustomMasiveData,
  RegisterBatchUploadSignedProcessData,
  UploadMetadataBatchFileFCData,
  UsersData,
  UploadTemplateManagerData,
  GetTemplatesData,
  GetContentTemplateData,
  GetTemplatesActiveData,
  GetCategoriesTemplateData,
  UploadVideoUser,
  TemplatesData,
  GetGraphicsByLastMonth,
  GetGraphicsByApplicant,
  GetGraphicsByTypeSign,
  GetGraphicsByStatusDeadLine,
  GetFilesChartCurrentMonth,
  GetFilesByStatus,
  DeleteLogicTemplate, DeleteLogicCategoriesTemplate,
  InheritTraceSignDocuments,
  GetUseCfdi, 
});

export default rootReducer;

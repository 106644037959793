import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import { List, arrayMove } from "react-movable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  ReorderParticipantMassive,
  resetReorderParticipantMassive,
  setlistSigners,
} from "../../store/actions";
import { Alert } from "../../components/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.TableHeader,
    color: theme.palette.primary.mainLyrics,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const ParticipantDraggableMassiveTable = (propsComponent) => {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const [listTypeUpdateOrder, setListTypeUpdateOrder] = useState("");
  const [userIdOrder, setUserIdOrder] = useState(null);
  const [newOrderCurent, setNewOrderCurent] = useState(null);
  const [loadUpdateOrder, setLoadUpdateOrder] = useState(false);

  const [oldItem, setOldItem] = useState();

  const reorderPartData = useSelector(
    (state) => state?.ReorderParticipantMassive
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      listTypeUpdateOrder !== null &&
      listTypeUpdateOrder !== "" &&
      userIdOrder !== null &&
      userIdOrder !== "" &&
      newOrderCurent !== null &&
      newOrderCurent !== ""
    ) {
      const oldItemChange = propsComponent.data[oldItem];
      const newItemChange = propsComponent.data[newOrderCurent];

      if (newItemChange.invoice === oldItemChange.invoice) {
        const request = {
          File_Id: folioIdSolicitud,
          BatchUploadItem: oldItemChange.BatchUploadItem_Id,
          BatchUploadParticipant_Id: oldItemChange.BatchUploadParticipant_Id,
          OrderType: "sign",
          NewOrder: newItemChange.order,
          CleanDate: false,
        };
        console.log("REQUETS", request);
        dispatch(ReorderParticipantMassive(request));
        setLoadUpdateOrder(true);
        setUserIdOrder(null);
        setNewOrderCurent(null);
      } else {
        Alert({
          icon: "warning",
          title: "¡Lo sentimos!",
          okbtntext: "Aceptar",
          text: "No puedes reordenar entre claves de solicitudes",
        });
      }
    }
  }, [listTypeUpdateOrder, userIdOrder, newOrderCurent]);

  useEffect(() => {
    if (
      loadUpdateOrder === true &&
      reorderPartData.items !== undefined &&
      reorderPartData.items !== null
    ) {
      dispatch(setlistSigners(reorderPartData?.items?.Response?.Participants));
    } else if (
      reorderPartData?.error !== undefined &&
      reorderPartData?.error !== null
    ) {
      Alert({
        icon: "error",
        title: "¡Lo sentimos!",
        okbtntext: "Aceptar",
        text: reorderPartData?.error,
      });
      dispatch(resetReorderParticipantMassive());
    }
  }, [reorderPartData]);

  return (
    <Box sx={{ width: "100%", overflowX: "auto", maxheight: 380 }}>
      <List
        values={propsComponent.data}
        onChange={({ oldIndex, newIndex }) => {
          setOldItem(oldIndex);

          let varUserId = propsComponent.data[oldIndex]?.UserId;
          setUserIdOrder(varUserId);
          setNewOrderCurent(newIndex);
          setListTypeUpdateOrder(propsComponent.listType);
          arrayMove(propsComponent.data, oldIndex, newIndex);
        }}
        renderList={({ children, props }) => (
          <Table>
            <TableHead sx={{ color: "primary.main80" }}>
              <TableRow>
                {propsComponent.Columns.map((head) => (
                  <StyledTableCell>{head.Header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody {...props}>{children}</TableBody>
          </Table>
        )}
        renderItem={({ value, props, isDragged }) => {
          const rowcontent = (
            <>
              <StyledTableCell sx={{ pointerEvents: "auto", width: 50 }}>
                {value.invoice}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto" }}>
                {value.rol}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto", width: 30 }}>
                {value.order}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto" }}>
                {value.emailUser}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto" }}>
                {value.alias}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto", fontSize: 10, padding: 0, paddingLeft: 1}}>
                {value.validity}
              </StyledTableCell>
              {/* <StyledTableCell sx={{ pointerEvents: "auto", width: 50 }}>
                {value.typeSign}
              </StyledTableCell> */}
              <StyledTableCell sx={{ pointerEvents: "auto" }}>
                {value.funtions}
              </StyledTableCell>
              <StyledTableCell sx={{ pointerEvents: "auto", width: 60 }}>
                {value.statusSigner}
              </StyledTableCell>
            </>
          );
          if (isDragged) {
            return (
              <Box {...props} sx={{ cursor: "grabbing" }}>
                <Table>
                  <TableBody>
                    <StyledTableRow>{rowcontent}</StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            );
          }
          return (
            <StyledTableRow {...props} sx={{ cursor: "grab" }}>
              {rowcontent}
            </StyledTableRow>
          );
        }}
      />
    </Box>
  );
};

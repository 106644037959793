import React, { Fragment, useEffect, useState } from "react";
import { CircularProgress, Button, Typography, styled, Divider, Alert as AlertMUI, } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import videoMicro from "../../../../assets/ActivaCamaraMicro.mp4"
import Marquee from "react-fast-marquee";
import IconREC from "../../../../assets/icons/IconREC.png";
import imagenVideo from "../../../../assets/ImagenVideo.PNG";
import { Alert } from "../../../../components/Alert";
import { CustomCameraVideo } from "../../../../components/Camera/CustomCameraVideo";
import { StopWatch } from "../../../../components/Cronometro/StopWatch";
import { VideoPlayer } from "../../../../components/VideoPlayer/VideoPlayer";
import { TimerCountdown } from "../../../../components/Cronometro/TimerCountdown";
import {
  uploadVideoEvidenceContent,
  getTextEvidence,
} from "../../../../store/actions";
import { SignatureService } from "../../../../services/SignatureService";
import ValidacionBiometrica from "./FirmaVideo/ValidacionBiometrica";
import './styles.css'
import moment from "moment/moment";
import { GeoLocationGeneric } from "../../../../components/GeoLocationGeneric";
import { ToastNotification } from "../../../../components/toastNotification";
import { LoadingButton } from "@mui/lab";
import { renderToString } from "react-dom/server";
import { UseImage } from "../../../../components/UseImage";
import { CustomModalDialogGeolocation } from "../../../../components/CustomModalDialogGeolocation";
import { Icon } from "@iconify/react";

const useStylesInt = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  paperResponsive: {
    display: "flex",
    padding: 8,
    flexWrap: "wrap",
    alignContent: "flex-start",
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 7,
      minHeight: 'auto',
    },
  },
  botonReponsive: {
    zIndex: 50,
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  marqueeCustom: {
    backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
    padding: '5px',
    borderrounded: '12px !important',
    fontSize: '20px !important',
    height: '40px !important',
    overflowY: 'hidden !important',
    ['@media only screen and (max-width: 812px)']: {
      width: '100% !important',
      fontSize: '17px !important',
      height: '34px !important',
    },
    width: "100%",
  },
}));

const ContainerCamera = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  margin: "0px auto",
  marginBottom: '0px',
  width: '88%',
  [theme.breakpoints.down("sm")]: {
    minHeight: '39vh',
  },
  overflowY: 'hidden !important',
  border: '1px solid green',
  borderRadius: '5px',
}));

export default function GrabacionVideo({ isActiveMassiveSignature }) {
  const theme = useTheme();
  const validateVideoPlay = true;
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStylesInt();
  const ProgressBarRef = React.useRef(null);
  const buttonRecRef = React.useRef(null);

  const [remaining, setRemaining] = useState(0);
  const [playMarquee, setPlayMarquee] = useState(false); //descmntr
  const [loadMarquesina, setLoadMarquesina] = useState(false);
  const [marqueeText, setMarkeeText] = useState("INSTRUCCIONES");
  const [evidenceDate, setEvidenceDate] = useState("");
  const [auditoText, setAudioText] = useState("");

  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");

  const [vetanaVideo, setVetanaVideo] = useState(0);

  const dateCurrent = new Date();
  const [loadingDetection, setLoadingDetection] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [playingActive, setPlayingActive] = useState(false);
  const [recording, setRecording] = useState(false);
  const [blobVideo, setBlobVideo] = useState(null)
  const [nameDoc, setNameDoc] = useState(null)
  const [stopRecording, setStopRecording] = useState(false);
  const [lengtVideo, setLengtVideo] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [errorMessageInternal, setErrorMessageInternal] = useState("");
  const [secondsTimerCountDown, setSecondsTimerCountDown] = useState(null);
  const [secondsTimerCountDownResponse, setSecondsTimerCountDownResponse] = useState(null);
  const [dateDisplayCurrent, setDateDisplayCurrent] = useState(null)
  const [reloadGeolocation, setReloadGeolocation] = useState(true)
  const [hasCamera, setHasCamera] = useState(false);
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const [detectionCompleted, setDetectionCompleted] = useState(null);
  const [detectionCompletedEmerged, setDetectionCompletedEmerged] = useState(null);
  const [render, setRender] = useState(null);
  const [cameraActive, setCameraActive] = useState(null);
  const [microphoneActive, setMicrophoneActive] = useState(null);
  const [geolocationIP, setGeolocationIP] = useState(false);
  const [openAlerte, setOpenAlerte] = useState(false);
  const [validatePlay, setValidatePlay] = useState(false);

  const documentData = useSelector((state) => state.File);
  const textEvidenceData = useSelector((state) => state.TextEvidence);

  const dispatch = useDispatch();

  useEffect(() => {
    if (documentData.item?.FileData?.Id) {
      dispatch(getTextEvidence({ Body: documentData.item?.FileData?.Id }));
    }
  }, []);

  useEffect(() => {
    if (documentData.item?.FileData?.Id) {
      dispatch(getTextEvidence({ Body: documentData.item?.FileData?.Id }));
    }
  }, [documentData.item?.FileData?.Id]);

  useEffect(() => {
    if (
      textEvidenceData.items !== undefined &&
      textEvidenceData.items !== null
    ) {
      setEvidenceDate(textEvidenceData.items.EvidenceDate);
      setAudioText(textEvidenceData.items.TextAudioEvidence);
      setMarkeeText(textEvidenceData.items.TextVideoEvidence);
      setLoadMarquesina(true);
      setPlayMarquee(false) //descmntr
    } else if (
      textEvidenceData.error !== undefined &&
      textEvidenceData.error !== null
    ) {
      setLoadMarquesina(false);
    }
  }, [textEvidenceData]); //Ejecutará el contenido de useEffect cada vez que 'file' se actualice

  useEffect(() => {
    if (stopRecording) {
      actionsButtons('Detener')
    }
  }, [stopRecording]);

  useEffect(() => {
    if (vetanaVideo === 1) {
      setPlayMarquee(false)
    }
  }, [vetanaVideo]);

  useEffect(() => {
    if (secondsTimerCountDownResponse === 0) {
      setSecondsTimerCountDownResponse(null)
      setSecondsTimerCountDown(null)
      actionsButtons('Grabar')
    }
  }, [secondsTimerCountDownResponse]);

  // FUNCION PARA PODER ENVIAR LOS DATOS DE LA GRABACION
  const enviarDatos = async () => {
    if ((documentData.item?.CheckGPS === true || documentData.item?.CheckGPS === null || documentData.item?.CheckGPS === undefined) && !lat && !lon) {
      setReloadGeolocation(true)
      return null;
    };

    const isoDate = new Date().toISOString();
    const utcMoment = moment.utc(isoDate);
    const localMoment = utcMoment.local();
    const localTime = localMoment.format("DD/MM/YYYY HH:mm:ss");

    try {
      const signatureService = SignatureService.getInstance();
      let Body = {
        AudioEvidence: null,
        ContinueWorkFlow: false,
        ObjectId: documentData.item?.FileData?.Id,
        VideoEvidence: { B64Content: "", Extension: "", DeferLoad: true },
        Geolocation: {
          Latitude: (documentData.item?.CheckGPS === null || documentData.item?.CheckGPS === undefined) ? lat : documentData.item?.CheckGPS === false ? null : lat,
          Longitude: (documentData.item?.CheckGPS === null || documentData.item?.CheckGPS === undefined) ? lon : documentData.item?.CheckGPS === false ? null : lon
        },
        OriginLocation: (documentData.item?.CheckGPS === null || documentData.item?.CheckGPS === undefined) ? 'GPS' : documentData.item?.CheckGPS === false ? null : geolocationIP === true ? 'IP' : 'GPS',
        TextVideoEvidence: marqueeText,
        TextAudioEvidence: auditoText,
        VideoEvidenceDate: evidenceDate, //Se obteiene de la configuracion del stage
        AudioEvidenceDate: null,
        LivenessEvidence: null,
        DateShowEvidence: localTime,
        TrackingData: {
          IMEI: "FC:01:7C:45:48:3F",
          Reference: "",
        },
        IsMasive: isActiveMassiveSignature,
      };

      const resSign = await signatureService.uploadEvidence(Body);

      if (resSign.Body) {
        /* const response = await fetch(videoPrueba);
        const videoBlob = await response.blob(); */
        dispatch(
          uploadVideoEvidenceContent({
            DocumentSection_Id:
              resSign?.Body?.Result?.VideoEvidence?.DocumentSection_Id,
            ContinueWorkFlow: '1',
            B64Data: blobVideo,
            // blobVideo,
            fileName: nameDoc,
            IsMasive: isActiveMassiveSignature === true ? "1" : "0",
          })
        );
        setVetanaVideo(2);
      }
    } catch (e) {
      Alert({
        icon: "error",
        text: `${e.message}`,
        okbtntext: "Aceptar"
      });
    }
  };

  useEffect(() => {
    async function detectDevices() {
      try {
        setDetectionCompletedEmerged(true);
        if (
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/Android/i)
        ) {
          setHasMicrophone(true);
          setHasCamera(true);
        } else {
          const devices = await navigator.mediaDevices.enumerateDevices();
          devices.forEach(device => {
            if (device.kind === 'videoinput') {
              setHasCamera(true);
            } else if (device.kind === 'audioinput') {
              setHasMicrophone(true);
            }
          });
        }
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          setCameraActive(true);
        } catch {
          setCameraActive(false);
        }
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setMicrophoneActive(true);
        } catch {
          setMicrophoneActive(false);
        } 
        // await navigator.mediaDevices.getUserMedia({ video: true })
        //   .then(() => setCameraActive(true))
        //   .catch(() => setCameraActive(false));

        // await navigator.mediaDevices.getUserMedia({ audio: true })
        //   .then(() => setMicrophoneActive(true))
        //   .catch(() => setMicrophoneActive(false));

        setDetectionCompletedEmerged(true);
      } catch (error) {
        setLoadingDetection(false)
        ToastNotification({
          icon: "warning",
          text: "Un error al detectar los dispositivos.",
        });
      }
    }
    detectDevices();
  }, []);

  useEffect(() => {
    if (detectionCompletedEmerged) {
      if (hasCamera === false || hasMicrophone === false) {
        setDetectionCompletedEmerged(false);
        Alert(
          {
            okbtntext: "Aceptar",
            html: renderToString(
              <Box>
                <Box sx={{ display: 'flex', justifyItems: "center", justifyContent: "center" }}>
                  <Box sx={{ p: 2 }}>
                    <UseImage type="Image" height={80} src={hasMicrophone === false ? "microGifPermiso.gif" : "microGifPermisoGreen.gif"} />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <UseImage type="Image" height={80} src={hasCamera === false ? "camaraGifPermiso.gif" : "camaraGifPermisoGreen.gif"} />
                  </Box>
                </Box>
                <Typography
                  textAlign={"center"}
                  variant="h4"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  ¡Importante!
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  Para este proceso, es necesario que tengas conectada una cámara y micrófono externo.
                </Typography>
              </Box>
            ),
          });
      } else if (hasCamera === true && hasMicrophone === true) {
        if (cameraActive === false || microphoneActive === false) {
          setDetectionCompletedEmerged(false);
          Alert(
            {
              icon: "error",
              okbtntext: "Aceptar",
              title: "Verifica tu cámara y tu micrófono",
              html: renderToString(
                <Box>
                  <Box sx={{ width: "100%" }}>
                    <video
                      className="videoUbi"
                      src={videoMicro}
                      autoPlay
                      loop
                      muted
                    />
                  </Box>
                  <Divider sx={{ maxWidth: 300, mt: 1, mx: "auto" }} />
                </Box>
              ),
            });
        }
      }

    }
  }, [detectionCompletedEmerged]);



  const handleSignature = async () => {
    try {
      setLoadingDetection(true);
      setHasCamera(false);
      setHasMicrophone(false);
      setCameraActive(false);
      setMicrophoneActive(false)
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/Android/i)
      ) {
        setHasMicrophone(true);
        setHasCamera(true);
      } else {
        const devices = await navigator.mediaDevices.enumerateDevices();
        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            setHasCamera(true);
          } else if (device.kind === 'audioinput') {
            setHasMicrophone(true);
          }
        });
      }
      await navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => setCameraActive(true))
        .catch(() => setCameraActive(false));

      await navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => setMicrophoneActive(true))
        .catch(() => setMicrophoneActive(false));

      setDetectionCompleted(true);

    } catch (error) {
      setLoadingDetection(false)
      ToastNotification({
        icon: "warning",
        text: "Un error al detectar los dispositivos.",
      });
    }
  };

  useEffect(() => {
    if (detectionCompleted) {
      if (hasCamera === false || hasMicrophone === false) {
        setRender(false);
        setDetectionCompleted(false);
        setLoadingDetection(false);
         Alert(
          {
            okbtntext: "Aceptar",
            html: renderToString(
              <Box>
                <Box sx={{ display: 'flex', justifyItems: "center", justifyContent: "center" }}>
                  <Box sx={{ p: 2 }}>
                    <UseImage type="Image" height={80} src={hasMicrophone === false ? "microGifPermiso.gif" : "microGifPermisoGreen.gif"} />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <UseImage type="Image" height={80} src={hasCamera === false ? "camaraGifPermiso.gif" : "camaraGifPermisoGreen.gif"} />
                  </Box>
                </Box>
                <Typography
                  textAlign={"center"}
                  variant="h4"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  ¡Importante!
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  Para este proceso, es necesario que tengas conectada una cámara y micrófono externo.
                </Typography>
              </Box>
            ),
          });
      } else if (hasCamera === true && hasMicrophone === true) {
        if (cameraActive === false || microphoneActive === false) {
          setRender(false);
          setDetectionCompleted(false);
          setLoadingDetection(false);
          Alert(
            {
              icon: "error",
              okbtntext: "Aceptar",
              title: "Verifica tu cámara y tu micrófono",
              html: renderToString(
                <Box>
                  <Box sx={{ width: "100%" }}>
                    <video
                      className="videoUbi"
                      src={videoMicro}
                      autoPlay
                      loop
                      muted
                    />
                  </Box>
                  <Divider sx={{ maxWidth: 300, mt: 1, mx: "auto" }} />
                </Box>
              ),
            });
        } else {
          setRecording(false)
          setPlaying(false)
          setBlobVideo(null)
          setRemaining(0)
          setPlayMarquee(false)
          setStopRecording(null)
          setPlayingActive(false)
          setSecondsTimerCountDownResponse(null)
          setSecondsTimerCountDown(null)
          setLoadingDetection(false)
          setDetectionCompleted(false);
          setRender(true);
          setVetanaVideo(1);
        }
      }

    }
  }, [detectionCompleted]);

  const actionsButtons = (type) => {
    switch (type) {
      case 'Reproducir':
        disabledSendVideo(true);
        setPlayingActive(!playingActive)
        setPlayMarquee(false)
        break;
      case 'Detener':
        setRecording(false)
        setPlaying(true)
        setRemaining(1)
        setPlayMarquee(false)
        break;
      case 'Grabar':
        disabledSendVideo(false);
        setRecording(true)
        setPlaying(false)
        setBlobVideo(null)
        setRemaining(0)
        setPlayMarquee(true)
        setStopRecording(null)
        setPlayingActive(false)
        break;
      default:
        setRecording(true)
        setPlaying(false)
        setBlobVideo(null)
        setRemaining(0)
        setPlayMarquee(true)
        setPlayingActive(false)
    }
  }

  const disabledSendVideo = (state) => {
    setTimeout(() => {
      setValidatePlay(state);
    }, 5000);
  }

  const [secs, setSeconds] = useState(300);
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (secs > 0) {
        let currentSecTime = secs - 1;
        setSeconds(currentSecTime);
        setDateDisplayCurrent(dateCurrent.toLocaleString())
      }
      if (secs === 0) {
        if (secs === 0) {
          clearInterval(sampleInterval);
        }
        else {
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
    };
  });

  useEffect(() => {
    if (geolocationIP === true) {
      setReloadGeolocation(true)
    }
  }, [geolocationIP])

  return (
    <Fragment>
      {((documentData.item?.CheckGPS === true || documentData.item?.CheckGPS === null || documentData.item?.CheckGPS === undefined) && reloadGeolocation) &&
        <GeoLocationGeneric SuccessFN={(latitude, longitude) => {
          setLat(latitude);
          setLon(longitude);
        }}
          ErrorFN={(error) => console.log('error', error)}
          setOpenAlerte={setOpenAlerte}
          ReloadGeoLocation={reloadGeolocation}
          setGeolocationIP={setGeolocationIP}
          geolocationIP={geolocationIP}
          setReloadGeoLocation={setReloadGeolocation} />}
      <CustomModalDialogGeolocation Open={openAlerte} setOpenAlerte={setOpenAlerte} setGeolocationIP={setGeolocationIP} />


      {vetanaVideo === 1 || vetanaVideo === 0 ? <>
        {/* Display de ayuda    */}
        <Box sx={{ display: vetanaVideo === 0 ? 'flex !important' : 'none !important' }} className={classes.paperResponsive} >
          <Box>
            <Typography variant="h6">
              Instrucciones de Videograbación
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1" align="justify">
                Deberás centrar tu rostro y asegurarte que te visualizas claramente.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                alignItems: "center",
                minHeight: 150,
              }}
            >
              <img alt="IMAGEN DE VIDEO" src={imagenVideo} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box className={classes.botonReponsive}>
              <Box>
                <LoadingButton
                  fullWidth
                  sx={{ height: 38 }}
                  variant="contained"
                  size="medium"
                  onClick={() => handleSignature()}
                  loading={loadingDetection}
                >
                  <Typography sx={{ letterSpacing: 1.2 }}>Siguiente</Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Display despues de ayuda */}
        <Box sx={{ position: 'relative', display: vetanaVideo === 1 ? 'flex !important' : 'none !important' }}
          className={classes.paperResponsive}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6">Videograbación</Typography>
            <Typography variant="subtitle1" align="justify">
              Para comenzar la videograbación, da clic en el botón <b>Iniciar videograbación</b>,
              enseguida, aparecerá el texto del consentimiento que debes leer.
            </Typography>
          </Box>
          <Box sx={{ p: 1, width: '100%', overflow: 'auto' }}>
            <ContainerCamera>
              <Box sx={{
                display: "grid !important",
                gridTemplateColumns: "50% 50%",
                width: '100%',
                alignItems: "center",
                backgroundColor: '#000000',
                top: '0px',
                paddingTop: '5px',
                paddingBottom: '3px',
                position: 'sticky',
                zIndex: 25,
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: "flex-start",
                  alignItems: "center",
                  zIndex: 25,
                  paddingLeft: '15px',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '130%',
                  color: '#FFFFFF',
                }}>
                  {dateDisplayCurrent}
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: '20px',
                  zIndex: 25,
                }}>
                  {recording ?
                    <img style={{ height: '2vh' }} alt="rec" src={IconREC} />
                    : null}
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: (secondsTimerCountDown || !recording && blobVideo === null && secondsTimerCountDown === null) ? "flex-start" : "center",
                alignItems: "center",
                height: '30vh',
                overflow: 'hidden',
                backgroundColor: '#000000',
              }}>
                {vetanaVideo === 1 || vetanaVideo === 0 ?
                  <>
                    {secondsTimerCountDown && <Box sx={{
                      display: "flex",
                      width: '100%',
                      backgroundColor: '#000000',
                      minHeight: "30vh",
                    }}>
                      <TimerCountdown startingSeconds={secondsTimerCountDown}
                        onlySeconds={true} returnSeconds={setSecondsTimerCountDownResponse}
                      />
                    </Box>
                    }
                    {!recording && blobVideo === null && secondsTimerCountDown === null ?
                      <Box sx={{
                        display: "flex",
                        width: '100%',
                        backgroundColor: '#000000',
                        minHeight: "50vh",
                      }}>

                      </Box>
                      : null}
                    {navigator.userAgent.match(/iPhone/i) ||
                      navigator.userAgent.match(/Android/i) ? (
                      <CustomCameraVideo width={700}
                        isDisplay={playing ? false : true}
                        render={render}
                        onCapture={blob => {
                          setBlobVideo(blob)
                          let dateNow = new Date()
                          let name = `${dateNow.getDate()}${dateNow.getMonth()}${dateNow.getFullYear()}_${dateNow.getHours()}${dateNow.getMinutes()}${dateNow.getSeconds()}_capture.webm`
                          setNameDoc(name)
                        }} Recording={recording} Stopped={playing}
                        myVal={playing}
                        setError={setErrorMessageInternal}
                      />
                    ) :
                      render === true ?
                        <CustomCameraVideo width={700}
                          isDisplay={playing ? false : true}
                          render={render}
                          onCapture={blob => {
                            setBlobVideo(blob)
                            let dateNow = new Date()
                            let name = `${dateNow.getDate()}${dateNow.getMonth()}${dateNow.getFullYear()}_${dateNow.getHours()}${dateNow.getMinutes()}${dateNow.getSeconds()}_capture.webm`
                            setNameDoc(name)
                          }} Recording={recording} Stopped={playing}
                          myVal={playing}
                          setError={setErrorMessageInternal}
                        />
                        : null

                    }
                  </>
                  : null}

                {playing && blobVideo !== null ?
                  <VideoPlayer Playing={playingActive} src={URL.createObjectURL(blobVideo)}
                    setLengtVideo={setLengtVideo} setCurrentTime={setCurrentTime}
                    ProgressBar={ProgressBarRef.current}
                  /> : playing && !navigator.userAgent.match(/iPhone/i) ?
                    <CircularProgress
                      size={60}
                      sx={{ margin: "auto", display: "flex" }}
                    /> : recording === false ? <div>{`No se encontró un dispositivo de Cámara.`}</div> : null}

                {errorMessageInternal &&
                  <div style={{ fontSize: '10px', color: 'red', padding: '2em', textAlign: 'justify' }}>{errorMessageInternal}</div>
                }
              </Box>
              {/* botones middle*/}
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <Box>
                  {remaining === 0 && loadMarquesina && playMarquee ? (
                    <Marquee
                      className={classes.marqueeCustom}
                      direction="left"
                      gradient="false"
                      // speed={105}
                      speed={85}
                      delay="3"
                      gradientWidth="9"
                      play={playMarquee}
                      pauseOnHover={false}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${marqueeText}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`,
                        }}
                      ></div>
                    </Marquee>
                  ) : null}
                </Box>
              </Box>
              {/* botones bottom */}
              <Box sx={{
                display: "grid !important",
                gridTemplateColumns: "50% 50%",
                width: '100%',
                alignItems: "center",
                position: 'sticky',
                zIndex: 25,
                backgroundColor: '#D9D9D9',
                bottom: '0px',
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: "flex-start",
                  paddingLeft: '15px',
                  alignItems: "center",
                  zIndex: 25,
                }}>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: "center",
                  zIndex: 25,
                  paddingRight: '20px',
                  fontFamily: 'Montserrat',
                  justifyContent: "flex-end",
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '10px',
                  lineHeight: '130%',
                }}>
                  {recording ?
                    <StopWatch Recording={recording} Stopped={!recording}
                      MaxLengthRecording={12} setStopRecording={setStopRecording} />
                    : <progress id='progress-bar' ref={ProgressBarRef} min='0'
                      max='100' value='0'>
                    </progress>}
                </Box>
              </Box>
            </ContainerCamera>
          </Box>

          <Box sx={{
            margin: "0px auto",
            display: 'flex'
          }}>
            {!recording && blobVideo === null && secondsTimerCountDown === null ?
              <Button
                fullWidth
                sx={{ height: 38, width: !matches ? '90%' : '100%' }}
                variant="contained"
                size="medium"
                onClick={() => {
                  setSecondsTimerCountDown(3)
                }}
              >
                Iniciar videograbación
              </Button>
              : null
            }
            {playing ?
              <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', }}>
                <Icon
                  icon="tdesign:play-circle"
                  style={{ color: theme.palette.primary.main, width: !matches ? 28 : 45, height: !matches ? 28 : 45 }}
                  onClick={() => {
                    actionsButtons('Reproducir')
                  }}
                />
                <span style={{ fontWeight: 500, fontSize: 12, lineHeight: '15px', textAlign: 'center' }}>Reproducir</span>
              </Box>
              : null
            }
            {!recording && blobVideo !== null ?
              <Box
                sx={{ display: "flex", pl: 2, alignItems: "center", justifyContent: "center", flexDirection: 'column', }}>
                <Icon
                  icon="fluent:replay-16-filled"
                  style={{ color: theme.palette.primary.main, width: !matches ? 28 : 45, height: !matches ? 28 : 45 }}
                  onClick={() => {
                    actionsButtons('Grabar')
                  }}
                />
                <span style={{ fontWeight: 500, fontSize: 12, lineHeight: '13px', textAlign: 'center' }}>Comenzar de nuevo</span>
              </Box>
              : null
            }
            {recording ?
              <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', }}>
                <Icon
                  icon="tdesign:stop-circle"
                  style={{ color: theme.palette.primary.main, width: !matches ? 28 : 45, height: !matches ? 28 : 45 }}
                  onClick={() => {
                    actionsButtons('Detener')
                  }}
                />
                <span style={{ fontWeight: 500, fontSize: 12, lineHeight: '15px', textAlign: 'center' }}>Detener</span>
              </Box>
              : null
            }
          </Box>

          <Box>
            {validateVideoPlay === true && validateVideoPlay ? (
              <Box display={'flex'} justifyItems={'center'}>
                <AlertMUI
                  iconMapping={{
                    warning: (
                      <Icon
                        icon="mdi:information-variant-circle"
                        fontSize={30}
                        style={{ color: "#FBBF28" }}
                      />
                    ),
                  }}
                  severity="warning"
                  sx={{
                    mt: 1,
                    backgroundColor: "secondary.warningAlert",
                    textAlign: 'justify',
                    ".MuiAlert-icon": {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  Para continuar con el proceso, es necesario que reproduzcas tu videograbación.
                  Asegurate que tu videograbación se escuche y visualice de manera correcta, de lo contrario deberás comenzar de nuevo.
                </AlertMUI>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {remaining !== 0 && blobVideo !== null ? (
              <Box className={classes.botonReponsive}>
                <Box sx={{ mt: 0.8 }}>
                  <Button
                    fullWidth
                    sx={{ height: 38 }}
                    variant="contained"
                    size="medium"
                    disabled={validateVideoPlay === true ? !validatePlay : null}
                    onClick={() => enviarDatos()}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>
                      Enviar videograbación
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </> : <ValidacionBiometrica setVetanaVideo={setVetanaVideo} isActiveMassiveSignature={isActiveMassiveSignature} />
      }
    </Fragment >
  );
}
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import { FormDynamic, Alert } from "../../../components";
import BackdropComponent from "../../../components/BackDrop";
import {
  RegisterDocumentFields,
  IntegrateDocumentToSign,
} from "../../../store/actions";
import { TemplatesServices } from "../../../services/TemplatesServices";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const DynamicQuestionnaire = (props) => {
  const [listDocumentFieldData, setlistDocumentFieldData] = useState([]);
  const userLogoutClose = true;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );

  // Control de estados
  const DataStage = useSelector((state) => state.File?.item);
  const ListDocumentsMetadataState = useSelector(
    (state) => state.File?.item?.DocumentFields
  );
  const RegisterDocumentFieldsState = useSelector(
    (state) => state.RegisterDocumentFieldsData
  );
  const folioIdSolicitudState = useSelector(
    (state) => state.File?.item?.FileData?.Id
  );
  const IntegrateDocumentToSignState = useSelector(
    (state) => state.IntegrateDocumentToSignData
  );

  const folioIdSolicitud =
    folioIdSolicitudState ?? sessionStorage.getItem("FileId");

  const dispatch = useDispatch();

  const validationUserSign = () => {
    let userCurso = DataStage.FileData.CurrentUser;

    // Usuarioo en curso si sea firmante
    const usuarioCursoSignCapture = DataStage.Participants.filter(
      (usuario) => usuario.Capture && userCurso == usuario.Email
    );

    // Para validar el maximo numero de firmante
    const maxCaptureOrder = Math.max(
      ...DataStage.Participants.map((participante) => participante.CaptureOrder)
    );

    // Para validar el minimo numero de firmante
    const minSignOrder = Math.min(
      ...DataStage.Participants.map((participante) => participante.SignOrder)
    );

    // Validar si el usuario en curso en el primer firmante
    const usuarioEnCursoPrimerFirmante =
      usuarioCursoSignCapture[0]?.SignOrder == minSignOrder;

    // Validar que el usuario en curso sea el ultimo capturista en caso de si no es el ultimno sacarlo alv
    const usuarioEnCursoUltimoCapturista =
      usuarioCursoSignCapture[0]?.CaptureOrder == maxCaptureOrder;

    //LOGICA DE SWTICH
    // Es el ultimo usuario en capturar y el primero en firmar, si es asi asi, tambien valida que sea el primero en firmar en caso contrario botalo alv

    if (
      usuarioEnCursoUltimoCapturista == true &&
      usuarioEnCursoPrimerFirmante == true
    ) {
      return props.setValidateReload(true);
    } else {
      // Botalo pa fuera papito no es el ultimo en capturar, y valida el tema de si es operador o es un super admin
      if (rolUser === "Operador" && userLogoutClose === true) {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/auth/login");
        window.location.reload();
      } else {
        navigate("/inbox/files");
      }
    }

    // Escnario 1 y simple
    // La condicion es si es == 1 por que es unico en ambos lados, una por que es unico y dos por que si coincidio con el tema de ser firmante... , pero debo validar si es el proximo firmante  por que podria firmar en
    // pocision numero dos en lugar de ser numero

    // Escenario 2
    // Es el ultimo usuario en capturar de 3 detectar que efectivameente sea el ultimo en capturar, al mismo tiempo detectar que sea el primero en firmar por ejemplo
    // Dos validaciones, saber si es el ultimo en capturar con el order, y saber si es el primero en firmar con el signOrder
  };

  useEffect(() => {
    if (props.TemplateData === true) {
      traerMetadatosTemplate();
    } else {
      if (ListDocumentsMetadataState?.length > 0) {
        let newArrayDocs = ListDocumentsMetadataState?.map((item) => {
          return {
            ...item,
            Id: `${item.Name}_${item.DocumentField_Id}`,
          };
        });
        setlistDocumentFieldData(newArrayDocs);
      }
    }
  }, [ListDocumentsMetadataState, props.TemplateData]);

  const traerMetadatosTemplate = async () => {
    const templateService = TemplatesServices.getInstance();
    const response = await templateService.GetFieldsCaptureParticipant({
      File_Id: folioIdSolicitudState,
    });
    if (response.Body !== null && response.Body.DocumentFields.length > 0) {
      let newArrayDocs = response.Body.DocumentFields?.map((item) => {
        return {
          ...item,
          Id: `${item.Name}_${item.DocumentField_Id}`,
        };
      });
      setlistDocumentFieldData(newArrayDocs);
    }
  };

  useEffect(() => {
    if (
      RegisterDocumentFieldsState.items !== undefined &&
      RegisterDocumentFieldsState.items !== null &&
      loading
    ) {
      if (props.TemplateData === true) {
        dispatch(RegisterDocumentFields("", true));
        setLoading(false);
        Alert({
          icon: "success",
          text: "Datos guardados correctamente.",
          okbtntext: "Aceptar",
        });
        validationUserSign();
      } else {
        dispatch(RegisterDocumentFields("", true));
        dispatch(
          IntegrateDocumentToSign({
            File_Id: folioIdSolicitud,
            IsMasive: false,
          })
        );
      }
    } else if (
      RegisterDocumentFieldsState.error !== undefined &&
      RegisterDocumentFieldsState.error !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(RegisterDocumentFields("", true));
      Alert({
        icon: "error",
        text: RegisterDocumentFieldsState.error,
        okbtntext: "Aceptar",
      });
    }
  }, [RegisterDocumentFieldsState]);

  useEffect(() => {
    if (props.TemplateData === true) {
      return;
    }
    if (
      IntegrateDocumentToSignState.items !== undefined &&
      IntegrateDocumentToSignState.items !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(IntegrateDocumentToSign("", true));
      Alert({
        icon: "success",
        text: "Datos guardados correctamente.",
        okbtntext: "Aceptar",
      });
      validationUserSign();
    } else if (
      IntegrateDocumentToSignState.error !== undefined &&
      IntegrateDocumentToSignState.error !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(IntegrateDocumentToSign("", true));
      Alert({
        icon: "error",
        text: IntegrateDocumentToSignState.error,
        okbtntext: "Aceptar",
      });
    }
  }, [IntegrateDocumentToSignState]);

  const handleConfirm = (formData) => {
    setLoading(true);
    const MetaDataFormat = listDocumentFieldData.map((itemField) => {
      return {
        Code: itemField.Code,
        Value: reformateData(itemField, formData),
      };
    });
    let request = {
      File_Id: folioIdSolicitud,
      DocumentFieldsUpdate: MetaDataFormat,
      IsMasive: false,
      ContinueWorkFlow: props.TemplateData === true ? true : false,
    };

    dispatch(RegisterDocumentFields(request));
  };

  const reformateData = (itemField, formData) => {
    let dataValue = formData[`${itemField.Name}_${itemField.DocumentField_Id}`];

    if (itemField.DataType === "Currency") {
      return "$" + dataValue;
    } else if (
      itemField.DataType === "DateTime" &&
      dataValue !== null &&
      dataValue !== "" &&
      dataValue !== undefined
    ) {
      return moment.utc(dataValue).local().format("DD-MM-YYYY hh:mm:ss A");
    } else if (
      itemField.DataType === "Date" &&
      dataValue !== null &&
      dataValue !== "" &&
      dataValue !== undefined
    ) {
      return moment.utc(dataValue).local().format("DD-MM-YYYY");
    } else {
      return dataValue;
    }
  };

  const handleCancel = (item) => {
    props.setIsCuestionaryActive(false);
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid
        container
        justifyContent={"rigth"}
        sx={{ mb: 1, pb: 1, borderBottom: "1px solid #898B8D4D" }}
      >
        <Grid item xs={12} md={10} lg={10}>
          <Box sx={{ mt: 1.5 }}>
            <Typography variant="caption" sx={{ fontWeight: 500, mb: 2 }}>
              Estatus:{" "}
              <span style={{ color: " #C20E30" }}>Firma de documentos </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} md={10} sx={{ mt: 1, ml: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 300 }}>
            La información capturada será utilizada para llenar el documento que
            será firmado
          </Typography>
        </Grid>
        {listDocumentFieldData?.length > 0 ? (
          <FormDynamic
            DataControls={listDocumentFieldData}
            handleConfirm={handleConfirm}
            ConfirmButtonText={"Enviar"}
            handleCancel={handleCancel}
          />
        ) : null}
      </Grid>
    </Fragment>
  );
};

export const templatesConstants = {
    UPLOAD_TEMPLATE_REQUEST: "UPLOAD_TEMPLATE_REQUEST",
    UPLOAD_TEMPLATE_SUCCESS: "UPLOAD_TEMPLATE_SUCCESS",
    UPLOAD_TEMPLATE_FAILURE: "UPLOAD_TEMPLATE_FAILURE",
    UPLOAD_TEMPLATE_RESET: "UPLOAD_TEMPLATE_RESET",
    GET_ROLES_DATA_USERS: "GET_ROLES_DATA_USERS",
    RESET_ROLES_DATA_USERS: "RESET_ROLES_DATA_USERS",


    GET_TEMPLATES_REQUEST: "GET_TEMPLATES_REQUEST",
    GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
    GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",
    GET_TEMPLATES_EMPTY: "GET_TEMPLATES_EMPTY",

    GET_TEMPLATES_ACTIVE_REQUEST: "GET_TEMPLATES_ACTIVE_REQUEST",
    GET_TEMPLATES_ACTIVE_SUCCESS: "GET_TEMPLATES_ACTIVE_SUCCESS",
    GET_TEMPLATES_ACTIVE_FAILURE: "GET_TEMPLATES_ACTIVE_FAILURE",
    GET_TEMPLATES_ACTIVE_EMPTY: "GET_TEMPLATES_ACTIVE_EMPTY",

    GET_CATEGORIES_TEMPLATE_REQUEST: "GET_CATEGORIES_TEMPLATE_REQUEST",
    GET_CATEGORIES_TEMPLATE_SUCCESS: "GET_CATEGORIES_TEMPLATE_SUCCESS",
    GET_CATEGORIES_TEMPLATE_FAILURE: "GET_CATEGORIES_TEMPLATE_FAILURE",
    GET_CATEGORIES_TEMPLATE_EMPTY: "GET_CATEGORIES_TEMPLATE_EMPTY",

    GET_CONTENT_TEMPLATES_REQUEST: "GET_CONTENT_TEMPLATES_REQUEST",
    GET_CONTENT_TEMPLATES_SUCCESS: "GET_CONTENT_TEMPLATES_SUCCESS",
    GET_CONTENT_TEMPLATES_FAILURE: "GET_CONTENT_TEMPLATES_FAILURE",
    GET_CONTENT_TEMPLATES_EMPTY: "GET_CONTENT_TEMPLATES_EMPTY",
    
    DELETE_CATEGORY_TEMPLATE_REQUEST: "DELETE_CATEGORY_TEMPLATE_REQUEST",
    DELETE_CATEGORY_TEMPLATE_SUCCESS: "DELETE_CATEGORY_TEMPLATE_SUCCESS",
    DELETE_CATEGORY_TEMPLATE_FAILURE: "DELETE_CATEGORY_TEMPLATE_FAILURE",
    DELETE_CATEGORY_TEMPLATE_EMPTY: "DELETE_CATEGORY_TEMPLATE_EMPTY",

    DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
    DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
    DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",
    DELETE_TEMPLATE_EMPTY: "DELETE_TEMPLATE_EMPTY",

};
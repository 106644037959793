import { templatesConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

export const TemplatesData = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.UPLOAD_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        item: null,
        error: null,
      };

    case templatesConstants.UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload,
        error: null,
      };

    case templatesConstants.UPLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        item: null,
        error: action.payload,
      };
    case templatesConstants.UPLOAD_TEMPLATE_RESET:
      return {
        ...state,
        loading: false,
        item: null,
        error: null,
      };

    case templatesConstants.GET_ROLES_DATA_USERS:
      return {
        ...state,
        rolesUsers: action.payload
      };
    case templatesConstants.RESET_ROLES_DATA_USERS:
      return {
        ...state,
        rolesUsers: []
      };
    default:
      return state;
  }
};

export const GetTemplatesData = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.GET_TEMPLATES_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const GetTemplatesActiveData = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.GET_TEMPLATES_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.GET_TEMPLATES_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.GET_TEMPLATES_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.GET_TEMPLATES_ACTIVE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const GetCategoriesTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.GET_CATEGORIES_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.GET_CATEGORIES_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.GET_CATEGORIES_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.GET_CATEGORIES_TEMPLATE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};


export const GetContentTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.GET_CONTENT_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.GET_CONTENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.GET_CONTENT_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.GET_CONTENT_TEMPLATES_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const DeleteLogicCategoriesTemplate = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.DELETE_CATEGORY_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.DELETE_CATEGORY_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.DELETE_CATEGORY_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.DELETE_CATEGORY_TEMPLATE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const DeleteLogicTemplate = (state = initialState, action) => {
  switch (action.type) {
    case templatesConstants.DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case templatesConstants.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case templatesConstants.DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case templatesConstants.DELETE_TEMPLATE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};
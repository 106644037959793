import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import BackdropComponent from "../../../components/BackDrop";
import { Alert } from "../../../components/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../../services/AuthService";
import { LoadingButton } from "@mui/lab";
import { InputField } from "../../../components/InputField";
import { makeStyles } from "@mui/styles";
import imageFondo from '../../../assets/imageFondoPass.svg'
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  contenedorImage: {
    height: '100vh',
    backgroundImage: `url(${imageFondo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  containerData: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    boxShadow: 'inset 18.0333px -18.0333px 18.0333px rgba(194, 194, 194, 0.1), inset -18.0333px 18.0333px 18.0333px rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(18.0333px)',
    borderRadius: "3%",
  }
}))

export default function ResetPasswordComponent() {
  const { control, handleSubmit, setValue } = useForm();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const emailId = query.get("mail");
  const navigate = useNavigate();
  const stateRedux = useSelector((state) => state.Authentication);
  const [reloadSing, setReloadSing] = useState(false);
  const [showPassDos, setshowPassDos] = useState(true);
  const [showPass, setshowPass] = useState(true);
  const [validationPass, setValidationPass] = useState(null);
  const [validSpace, setValidSpace] = useState(null);


  console.log('stateRedux', stateRedux)

  const handleService = async (data) => {
    setReloadSing(true);

    const ochocaracteres = /.{8,}/.test(data.Password);
    const mayymin =
      /(?:[A-Z])/.test(data.Password) && /(?:[a-z])/.test(data.Password);
    const numeros = /(?:\d)/.test(data.Password);
    const noespecial = /[^!?A-Za-z\d]/.test(data.Password);
    const valida = ochocaracteres && mayymin && numeros && noespecial;
    const espacios = /\s/.test(data.Password);
    setValidationPass(valida);
    setValidSpace(espacios)

    if (valida === false || espacios) {
      setReloadSing(false);
      return null;
    }
    if (data.Password !== data.RepeatPassword) {
      setReloadSing(false);
      Alert({
        icon: "error",
        text: "Las contraseñas no coinciden,. verifica de nuevo.",
      });
      return null;
    }

    console.log(data.username || emailId)
    const authService = AuthService.getInstance();
    const resetPass = await authService.resetPassword({
      UserName: emailId || data.username,
      NewPassword: data.Password,
    });
    console.log(resetPass)
    if (resetPass.data.data.Body) {
      setReloadSing(false);
      sessionStorage.setItem(
        "userPass",
        JSON.stringify({
          UserName: emailId || stateRedux?.items?.UserLoginData?.Email,
          NewPassword: data.Password,
        })
      );
      Alert({
        icon: "success",
        text: "Se ha enviado un código de verificación a tu cuenta de correo electrónico.",
      });
      console.log(emailId)
      if (emailId !== undefined || emailId !== null || emailId !== '' || location.pathname === '/auth/resetPassword') {
        console.log('ENTRO AQUI')
        navigate("/auth/securityCode");
      } else {
        console.log('ENTRO AQUI DOS')
        navigate("/inbox/securityCode");
      }
    } else {
      setReloadSing(false);
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + resetPass.data.data.Messages,
      });
    }
  };

  useEffect(() => {
    if (!emailId) {
      setValue('username', stateRedux?.items?.UserLoginData?.Email)
    }
  }, [])



  return (
    <Fragment>
      <BackdropComponent loading={stateRedux.loading} />
      <Grid container justifyContent={"center"} alignItems={'center'} className={emailId ? classes.contenedorImage : null}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              width: emailId ? '80%' : null,
              alignItems: "center",
              justifyContent: "center",
            }}
            className={emailId ? classes.containerData : null}
          >
            <form onSubmit={handleSubmit(handleService)}>
              <Box sx={{ p: 2, mt: 1 }}>
                <Box textAlign={"center"}>
                  <Typography variant="titulo" sx={{ fontWeight: 700, letterSpacing: 1.6 }}>
                    Cambio de contraseña
                  </Typography>
                </Box>
                <Box textAlign={"justify"} sx={{ mt: 1 }}>
                  <Typography variant="subtitle" sx={{ mb: 1, fontWeight: 480 }}>
                    Ingresa tu nueva contraseña, utilizando los caracteres solicitados
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  {emailId ? null :
                    <InputField
                      name="username"
                      disabled={true}
                      id="username"
                      label="Correo electrónico"
                      iconSpam={true}
                      control={control}
                      fullWidth
                      sx={{ borderRadius: "12px" }}
                      validations={{ required: true }}
                    />
                  }
                  <InputField
                    name="Password"
                    label="Contraseña"
                    control={control}
                    fullWidth
                    sx={{ borderRadius: "12px" }}
                    validations={{ required: true }}
                    posIcon="end"
                    type="password"
                    isPassword={showPass}
                    icon={
                      showPass ? (
                        <IconButton onClick={() => setshowPass(false)}>
                          <VisibilityOff />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setshowPass(true)}>
                          <Visibility />
                        </IconButton>
                      )
                    }
                  />
                  <FormHelperText error={validationPass === false || validSpace ? true : false}>
                    La contraseña debe de contener al menos 8 caracteres, sin espacios, una mayúscula, una
                    minúscula, un número y un carácter especial
                  </FormHelperText>
                  <InputField
                    name="RepeatPassword"
                    label="Repetir contraseña"
                    control={control}
                    fullWidth
                    sx={{ borderRadius: "12px" }}
                    validations={{ required: true }}
                    posIcon="end"
                    type="password"
                    isPassword={showPassDos}
                    icon={
                      showPassDos ? (
                        <IconButton onClick={() => setshowPassDos(false)}>
                          <VisibilityOff />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setshowPassDos(true)}>
                          <Visibility />
                        </IconButton>
                      )
                    }
                  />
                  {/* <FormHelperText error={validationPass === false || validSpace ? true : false}>
                    Confirmar contraseña
                  </FormHelperText> */}
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
                <Box width={"90%"} sx={{ p: 1 }}>
                  <Button
                    fullWidth
                    sx={{ height: 38 }}
                    variant="outlined"
                    size="medium"
                    onClick={() => navigate("/inbox/files")}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>Cancelar</Typography>
                  </Button>
                </Box>
                <Box width={"90%"} sx={{ p: 1 }}>
                  <LoadingButton
                    fullWidth
                    sx={{ height: 38 }}
                    variant="contained"
                    size="medium"
                    onClick={handleSubmit(handleService)}
                    loading={reloadSing}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>Aceptar</Typography>
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

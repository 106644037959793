import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { AttachFileOutlined } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  signDocument,
  extractSignatureFromPem,
  verifySignature,
  toBase64,
} from "../../../../utils/signature";
import "./styles.css";
import { ToastNotification } from "../../../../components/toastNotification";
import { SignatureService } from "../../../../services/SignatureService";
import { Alert, AlertConfirm } from "../../../../components/Alert";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { getFile, trueLoading } from "../../../../store/actions";
import { useEffect } from "react";
import GrabacionVideo from "./GrabacionVideo";
import { makeStyles } from "@mui/styles";
import HelpVideoFirma from '../../../../assets/Firma avanzada.mp4';
import HelpPages from "../../../../components/HelpPages";
import { GeoLocationGeneric } from "../../../../components/GeoLocationGeneric";
import { Icon } from "@iconify/react";
import { obtenerDireccionIP } from "../../../../utils";
import { renderToString } from "react-dom/server";
import IndexVideo from "../indexVideo";
import { CustomModalDialogGeolocation } from "../../../../components/CustomModalDialogGeolocation";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  paperResponsive: {
    display: "flex",
    padding: 9,
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    flexWrap: "wrap",
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 7,
      minHeight: "auto",
    },
  },
  botonReponsive: {
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  }
}));

export default function FirmaAvanzada({ setValue, setOpenModal, setOpenModalReenvio, isActiveMassiveSignature }) {
  const document = useSelector((state) => state.File);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const stageFile = useSelector((state) => state.File.item);
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );
  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );
  const participant = stageFile?.Participants?.filter(
    (part) => part.UserId === userData.Id && part.Sign === true
  );
  const participantStage = useSelector(
    (state) => state.File.item?.SignatureOptions?.SignatureType
  );
  const stageFirma = useSelector((state) => state.File.item?.SignatureOptions);

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [signed, setsigned] = useState(false);
  const firmaLocal = sessionStorage.getItem("firmab64");
  const [statusFirma, setStatusFirma] = useState(false);
  const [statusOCSP, setStatusOCSP] = useState(false);
  const [statuSingBlocked, setStatuSingBlocked] = useState(false);
  const [openAlertSign, setOpenAlertSign] = useState(false);
  const [ipDirection, setIpDirection] = useState(null);
  const [reloadGeolocation, setReloadGeolocation] = useState(true)
  const [geolocationIP, setGeolocationIP] = useState(false);
  const [openAlerte, setOpenAlerte] = useState(false);

  const [keyfile, setkeyfile] = useState();
  const [cerfile, setcerfile] = useState();
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [loadingSignature, setloadingSignature] = useState(false);
  const keyref = useRef();
  const cerref = useRef();

  useEffect(() => {
    const obtenerIP = () => {
      obtenerDireccionIP((ip) => {
        if (ip) {
          setIpDirection(ip);
        } else {
          obtenerIP(); // Llamamos a la función de nuevo si la dirección IP es vacía o nula
        }
      });
    };
    obtenerIP();
  }, []);

  const handleKeyInput = () => {
    keyref.current.click();
  };
  const handleKeyFile = (e) => {
    setkeyfile(e.target.files[0]);
  };
  const handleCerInput = () => {
    cerref.current.click();
  };
  const handleCerFile = (e) => {
    setcerfile(e.target.files[0]);
  };

  useEffect(() => {
    setOpenAlertSign(true)
  }, []);

  const handleSignature = async () => {
    if (!lat && !lon && (stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined)) {
      setReloadGeolocation(true)
      return null;
    }

    if (keyfile && cerfile && values.password) {
      try {
        setloadingSignature(true);
        const cerb64 = await toBase64(cerfile);
        let firmab64 = "";
        const signatureService = SignatureService.getInstance();

        if (!firmaLocal) {
          // console.log("FIRMA DE DOCUMENTO EN PROCESO");
          let documentToSign = document.document_64.B64Content;
          try {
            const result = await signDocument({
              certfile: cerfile,
              keyfile: keyfile,
              password: values.password,
              document: documentToSign,
            });

            if (result.firma) {
              firmab64 = extractSignatureFromPem({
                pemSignature: result.firma,
              });

              if (firmab64) {
                setStatusFirma(true);
                setStatuSingBlocked(true);
              }

              await verifySignature({
                certfile: cerfile,
                document: document.document_64.B64Content,
                pemSignature: result.firma,
              });

            } else {
              Alert({
                icon: "error",
                text: result.status,
              });
              return null
            }
          } catch (error) {
            Alert({
              icon: "error",
              text: error,
            });
            return null
          }
        }
        if (navigator.onLine) {
          // ------------------- INICIA Verificacion de CERTIFICADO OCSP -------------------
          const splittedCerName = cerfile.name.split(".");
          const bodyVerifyOCSP = {
            CertificadoB64: cerb64.split(",")[1],
            //cerb64.split(",")[1],
            NombreCertificado: splittedCerName[0],
            ExtensionCertificado: `.${splittedCerName[splittedCerName.length - 1]}`,
            File_Id: document.item.FileData.Id,
            Document_Id: document.document.Id,
            IsMasive: isActiveMassiveSignature,
          };
          try {
            const resVerifyCer = await signatureService.verifyCertificateStatus(
              bodyVerifyOCSP
            );
            if (resVerifyCer.Body?.Status.toUpperCase() === "UNKNOW" || resVerifyCer.Body.Status.toUpperCase() === "UNKNOWN") {
              Alert({
                icon: "warning",
                text: resVerifyCer.Body.MessageUknowState || resVerifyCer.Body.MessageConfirm,
              });
              return null
            } else if (resVerifyCer.Body.Status == "vigente confirmado") {
              AlertConfirm(
                {
                  icon: "warning",
                  title: "Estatus de Firma Electrónica Avanzada",
                  text: resVerifyCer.Body.MessageConfirm,
                  okbtntext: "Aceptar",
                },
                (result) => {
                  if (result.isConfirmed) {
                    setStatusOCSP(true);
                    setStatuSingBlocked(false);
                    const bodySign = {
                      B64Certificado: cerb64.split(",")[1],
                      OriginLocation: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? geolocationIP === true ? 'IP' : 'GPS' : null,
                      B64Firma: firmaLocal ? firmaLocal : firmab64,
                      signedAtFEA: new Date().toISOString(),
                      Latitude: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? lat : null,
                      Longitud: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? lon : null,
                      MacAddress: ipDirection !== undefined && //Feliz cumple brayan  29/08/2023 
                        ipDirection !== null && ipDirection !== "" ?
                        ipDirection : "000.000.000.000",
                      File_Id: document.item.FileData.Id,
                      ContinueWorkFlow: true,
                      Document_Id: document.document.Id,
                      RespuestaOcsp: "good",
                      IsMasive: isActiveMassiveSignature,
                      ConfirmSignUserWithOutValidityOCSP: true
                    };
                    signDataAdvance(bodySign)
                  } else if (result.dismiss === "backdrop" && result.isDismissed) {
                    return console.log('resultDataPasa', result, resVerifyCer)
                  }
                }
              );
            } else if (resVerifyCer.Body.Status === "good" || resVerifyCer.Body.Status === "vigente sin validar") {
              setStatusOCSP(true);
              setStatuSingBlocked(false);
              const bodySign = {
                B64Certificado: cerb64.split(",")[1],
                OriginLocation: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? geolocationIP === true ? 'IP' : 'GPS' : null,
                B64Firma: firmaLocal ? firmaLocal : firmab64,
                signedAtFEA: new Date().toISOString(),
                Latitude: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? lat : null,
                Longitud: stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? lon : null,
                MacAddress: ipDirection !== undefined && //Feliz cumple brayan  29/08/2023 
                  ipDirection !== null && ipDirection !== "" ?
                  ipDirection : "000.000.000.000",
                // Section_Id: document.document.DocumentData.Sections[0].Id,
                File_Id: document.item.FileData.Id,
                ContinueWorkFlow: true,
                Document_Id: document.document.Id,
                RespuestaOcsp: "good",
                IsMasive: isActiveMassiveSignature,
              };
              signDataAdvance(bodySign)
            }
          } catch (error) {
            Alert({
              icon: "error",
              text: error.message,
            });
          }
        } else {
          setStatusOCSP(false);
          setStatuSingBlocked(true);
          Alert({
            title: "Lo sentimos",
            icon: "error",
            okbtntext: "Entendido",
            html: renderToString(
              <Box>
                <Typography
                  textAlign={"justify"}
                  sx={{ maxWidth: 400, mx: "auto" }}
                >
                  Por el momento no tienes conexion a internet, espera que tu
                  conexión se reestablesca para el proceso de verficación.
                </Typography>
                <Divider sx={{ maxWidth: 300, mt: 3, mx: "auto" }} />
              </Box>
            ),
          });
        }
      } catch (err) {
        setloadingSignature(false);
        setStatuSingBlocked(false);
        Alert({
          icon: "error",
          text: "Hubo un error al firmar el documento.",
        });
      } finally {
        setloadingSignature(false);
        if (navigator.onLine) {
          sessionStorage.removeItem("firmab64");
          setkeyfile("");
          setcerfile("");
          setValues({ ...values, password: "" });
          cerref.current.value = "";
          keyref.current.value = "";
          setStatusFirma(false);
          setStatusOCSP(false);
          setStatuSingBlocked(false);
        }
      }
    } else {
      if (!values.password) {
        ToastNotification({
          icon: "error",
          text: "La contraseña es requerida.",
        });
      }
      if (!keyfile || !cerfile) {
        ToastNotification({
          icon: "error",
          text: "Debes cargar los archivos solicitados.",
        });
      }
    }
    /* setValue(0); */
  };


  const signDataAdvance = async (bodySign) => {
    try {
      const signatureService = SignatureService.getInstance();
      const resSign = await signatureService.signDocument(bodySign);
      if (participantStage) {
        if (stageFirma.VideoRecording) {
          dispatch(getFile({ FileId: document.item.FileData.Id }));
          setOpenModal(true);
          if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true) {
            return <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
          } else if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === false) {
            return <IndexVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
          }
        } else {
          setOpenModal(true);
          setOpenModalReenvio(true);
        }
      } else {
        if (participant[0].SignatureOptions.VideoRecording === true) {
          dispatch(getFile({ FileId: document.item.FileData.Id }));
          setOpenModal(true);
          if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true) {
            return <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
          } else if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === false) {
            return <IndexVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
          }
        } else {
          setOpenModal(true);
          setOpenModalReenvio(true);
        }
      }
      return resSign;
    } catch (error) {
      Alert({
        icon: "error",
        text: error.message,
      });
      console.log(error)
    }
  }

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const reenviarVentana = () => {
    setsigned(false);
    dispatch(getFile({ FileId: document.item.FileData.Id }));
    dispatch(trueLoading());
    setTimeout(() => {
      navigate("/inbox/files");
    }, 2000);
  };

  useEffect(() => {
    if (geolocationIP === true) {
      setReloadGeolocation(true)
    }
  }, [geolocationIP])

  return (
    <>
      <HelpPages
        Body={
          <Box sx={{ mt: 2 }}>
            <video
              width={"100%"}
              className="HelpVideo"
              src={HelpVideoFirma}
              autoPlay
              loop
              muted
            />
          </Box>
        }
      />

      {(stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined && reloadGeolocation) &&
        <GeoLocationGeneric SuccessFN={(latitude, longitude) => {
          setLat(latitude);
          setLon(longitude);
        }}
          ErrorFN={(error) => console.log('error', error)}
          setOpenAlerte={setOpenAlerte}
          ReloadGeoLocation={reloadGeolocation}
          setGeolocationIP={setGeolocationIP}
          geolocationIP={geolocationIP}
          setReloadGeoLocation={setReloadGeolocation} />}
      <CustomModalDialogGeolocation Open={openAlerte} setOpenAlerte={setOpenAlerte} setGeolocationIP={setGeolocationIP} />

      <Box elevation={1} className={classes.paperResponsive}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="titulo">
              Firma Electrónica Avanzada
            </Typography>
            <HelpRoundedIcon
              onClick={() => setOpen(!open)}
              sx={{ ml: 1 }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle" sx={{ fontWeight: 500 }}>
              Estatus de firma
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            mt: 1,
            alignItems: "center",
            borderBottom: .9,
            borderColor: "secondary.main60",
          }}>
            <Box sx={{ mr: 2, mt: .8 }}>
              <Icon icon="akar-icons:wifi" fontSize={22} color={navigator.onLine ? "#5CCD75" : "red"} />
            </Box>
            <Box>
              <Typography variant="subtitle" >
                Conexión a internet
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            mt: 1,
            alignItems: "center",
            borderBottom: .9,
            borderColor: "secondary.main60",
          }}>
            <Box sx={{ mr: 2, mt: .8 }}>
              <Icon icon="fluent:info-28-regular" fontSize={24} color={statusOCSP ? "#5CCD75" : '#FFD233'} />
            </Box>
            <Box>
              <Typography variant="subtitle" >
                Documento firmado
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            mt: 1,
            alignItems: "center",
            borderBottom: .9,
            borderColor: "secondary.main60",
          }}>
            <Box sx={{ mr: 2, mt: .8 }}>
              <Icon icon="fluent:info-28-regular" fontSize={24} color={statusOCSP ? "#5CCD75" : '#FFD233'} />
            </Box>
            <Box>
              <Typography variant="subtitle" >
                Certificado validado
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              borderBottom: .9,
              mt: 1,
              borderColor: "secondary.main60",
            }}
          >
            <Box sx={{ mt: 2 }}>
              <Box>
                <Typography variant="subtitle" sx={{ fontWeight: 500 }}>
                  Ubicación de los archivos solicitados
                </Typography>
              </Box>
              <Button
                disabled={statuSingBlocked}
                fullWidth
                sx={{
                  mt: 1,
                  mb: 1,
                  justifyContent: "flex-start",
                  color: "#000",
                }}
                onClick={handleCerInput}
              >
                <input
                  ref={cerref}
                  disabled={statuSingBlocked}
                  onChange={handleCerFile}
                  style={{ visibility: "hidden", width: 0 }}
                  type="file"
                  accept=".cer,.cert"
                />
                <Box
                  sx={{
                    display: "grid",
                    placeContent: "center",
                    p: 2,
                    borderRadius: 2,
                    // opacity: cerfile ? 1 : 0.5,
                  }}
                >
                  <AttachFileOutlined
                    fontSize="large"
                    sx={{ color: cerfile ? "#5CCD75" : '#DADADA' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    ml: 3,
                  }}
                >
                  <Typography variant="subtitle1" component="p">
                    Archivo .CER<spam style={{ color: "#C20E30", marginLeft: 3 }}>{" "} * </spam>
                  </Typography>
                  <Typography variant="caption" component="p">
                    Certificado digital
                  </Typography>
                </Box>
                <ArrowForwardIosIcon />
              </Button>
              <Button
                fullWidth
                disabled={statuSingBlocked}
                sx={{ justifyContent: "flex-start", color: "#000" }}
                onClick={handleKeyInput}
              >
                <input
                  disabled={statuSingBlocked}
                  ref={keyref}
                  onChange={handleKeyFile}
                  style={{ visibility: "hidden", width: 0 }}
                  type="file"
                  accept=".key"
                />
                <Box
                  sx={{
                    display: "grid",
                    placeContent: "center",
                    p: 2,
                    borderRadius: 2,
                    // opacity: keyfile ? 1 : 0.5,
                  }}
                >
                  <AttachFileOutlined
                    fontSize="large"
                    sx={{ color: keyfile ? "#5CCD75" : '#DADADA' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    ml: 3,
                  }}
                >
                  <Typography variant="subtitle1" component="p">
                    Archivo .KEY<spam style={{ color: "#C20E30", marginLeft: 3 }}>{" "} * </spam>
                  </Typography>
                  <Typography variant="caption" component="p">
                    Clave privada
                  </Typography>
                </Box>
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth size="small" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Contraseña de la clave privada
              </InputLabel>
              <OutlinedInput
                disabled={statuSingBlocked}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    handleSignature();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña de la clave privada"
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box className={classes.botonReponsive}>
            <Box sx={{ mt: 1 }}>
              <LoadingButton
                fullWidth
                sx={{ height: 38 }}
                variant="contained"
                size="medium"
                disabled={cerfile && keyfile && values.password ? false : true}
                onClick={handleSignature}
                loading={loadingSignature}
              >
                <Typography sx={{ letterSpacing: 1.2 }}>
                  {!statuSingBlocked ? "Firmar" : "Reintentar Firma"}
                </Typography>
              </LoadingButton>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Button
                fullWidth
                sx={{ height: 38 }}
                variant="outlined"
                size="medium"
                onClick={() => navigate("/inbox/files")}
                disabled={loadingSignature}
              >
                <Typography sx={{ letterSpacing: 1.2 }}>
                  Cancelar
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={signed}
      /* onClose={() => setsigned(false)} */
      >
        <Box sx={{ p: 5 }}>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Documento firmado
          </Typography>
          <Typography variant="body2" align="center" sx={{ mb: 3 }}>
            La firma electrónica ha sido aplicada con éxito al documento. <br />
            Los participantes serán notificados de la aplicación de su firma.
          </Typography>
          <Box
            sx={{ mx: "auto", maxWidth: "70%", borderTop: "1px solid #ddd" }}
          >
            <Button
              color="primary"
              onClick={() => reenviarVentana()}
              sx={{ mx: "auto", display: "flex", mt: 1, minWidth: 100 }}
            >
              Cerrar
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClickOpen}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          Ayuda
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            <Typography variant="body1" sx={{ textAlign: 'justify' }}>
              Al ingresar tu contraseña privada y asociando los archivos .CER y .KEY,
              tu firma quedará registrada con los mismos efectos jurídicos que la
              firma autógrafa, de acuerdo al Art. 7 de la Ley de Firma Electrónica
              Avanzada. Te recordamos que tu información se encuentra segura y solo
              será utilizada para verificar el certificado y la coincidencia de
              datos ante el SAT. Por ningún motivo almacenaremos dicha información
              y la ejecución será de forma local.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                minWidth: 150,
              }}
            >
              Entendido
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={openAlertSign}
        onClose={() => setOpenAlertSign(false)}
      >
        <Box sx={{ p: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ErrorOutlineIcon fontSize="large" sx={{ mr: 1, color: 'primary.main60' }} />
            <Typography variant="titulo">
              Importante
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: "flex", textAlign: 'justify' }}>
            <Typography variant="subtitle" sx={{ mb: 3 }}>
              En cumplimiento con los principios rectores de la Ley de Firma Electrónica Avanzada respecto al curso y validez, se asegura la confidencialidad garantizando que la información que a continuación se solicita,
              <spam style={{ fontWeight: 550 }}> no será almacenada y su ejecución es de forma local.</spam>
            </Typography>
          </Box>
          <Box
            sx={{ mx: "auto" }}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => setOpenAlertSign(false)}
              sx={{ mx: "auto", display: "flex", mt: 1 }}
            >
              Entendido
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}


import { fileFlowConstants } from "../constants";
import { FileFlowService } from "../../services/FileFlowService";
import { SignatureService } from "../../services/SignatureService";
import { Alert } from "../../components/Alert.jsx";
import { Box, Divider, Typography } from "@mui/material";
import { renderToString } from "react-dom/server";
import { ToastNotification } from "../../components/toastNotification";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const resetFileFlowStack = () => {
  return {
    type: fileFlowConstants.FILE_FLOW_SUCCESS,
  };
};


export const resetDataUploadVideo = () => {
  return {
    type: fileFlowConstants.UPLOAD_VIDEO_RESET,
  };
};


export const resetFileFlow = () => {
  return {
    type: fileFlowConstants.RESET_FLOW_REQUEST,
  };
};

// Para poder iniciar el flujo
export const initializeFileFlow = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileFlowData = await fileFlowService.initializeFileFlow(filesData);
      dispatch(success(fileFlowData.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
      Alert({
        icon: "success",
        okbtntext: "Aceptar",
        title: "Expediente iniciado",
        html: renderToString(
          <Box>
            <Typography textAlign={'justify'} variant="body1" sx={{ mt: 1, textAlign: 'justify', maxWidth: 400, mx: "auto" }}>
              El expediente ha iniciado con las acciones configuradas. Los participantes recibirán una notificación cuando sea su turno.
            </Typography>
            <Typography textAlign={'justify'} variant="body1" sx={{ textAlign: 'justify', maxWidth: 400, mx: "auto", mt: 1 }}>
              Número de folio:
            </Typography>
            <Typography
              textAlign={'center'}
              variant="h5"
              component="p"
              sx={{ textAlign: "center", color: "#C20E30", mt: 1 }}
            >
              {fileFlowData.Body.Result.Id}
            </Typography>
            <Divider sx={{ maxWidth: 300, mt: 1, mx: "auto" }} />
          </Box>
        ),
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// Para poder aprobar documentos en revision o rechazarlos
export const validateDocuments = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.validateDocuments(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        title: "Expediente Revisado",
        text: "Aprobaste el expediente sin realizar ninguna modificación. El expediente continuará con las siguientes acciones.",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// Para poder rechazar documentos en firma
export const cancelWorkFlow = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.cancelWorkFlow(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        title: "Documento de firma rechazado con éxito",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// PARA PODER CERRAR ELPROCESO DE REVISION DEL DOCUMENTO
export const closeDocumentVersion = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.closeDocumentVersion(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        title: "Edición de documento cerrada",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// Para poder cargar nuevas versiones de documentos
export const uplodadNewDocumentVersion = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.uplodadNewDocumentVersion(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        title: "Guardado",
        text: "Tu documento se ha enviado con éxito",
      });
    } catch (error) {
      console.log('errorDcoumento', error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// Accion para poder reiniciar el flujo
export const restartFlow = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.restartFlow(filesData);
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        text: "Se a reiniciado el flujo con éxito",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// Accion para poder aceptar un documento rechazado por el usuario
export const acceptanceRejection = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.acceptanceRejection(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
      Alert({
        icon: "success",
        text: "Se aprobo exitosamente el documento",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

// XML con token
export const verifyXml = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const xmlDoc = await signatureService.verifyXml(data);
      dispatch(success(xmlDoc.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos. ${error.message}`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

// Para poder validar el PIN
export const ValidatePINStageSing = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const singPIN = await signatureService.ValidatePINStageSing(data);
      dispatch(success(singPIN.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos. ${error.message}`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

// Para poder enviar el PIN
export const notificationPINStageFile = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const singPIN = await signatureService.notificationPINStageFile(data);
      ToastNotification({
        icon: "success",
        text: "Se ha enviado una Contraseña de un solo uso (OTP) a tu correo electrónico.",
      });
      dispatch(success(singPIN.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos. ${error.message}`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

// API DE FIRMA DIGITAL SIMPLE
export const autographSignDocument = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const singAutograph = await signatureService.autographSignDocument(data);
      dispatch(
        success(singAutograph.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos error en el servidor`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

// PARA PODER VERIFICAR EL video
export const uploadEvidence = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const singEvidence = await signatureService.uploadEvidence(data);
      dispatch(success(singEvidence.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos. ${error.message}`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

// PARA LOS DATOS DE VIDEO
export const uploadVideoEvidenceContent = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.UPLOAD_VIDEO_REQUEST));
    try {
      const signatureService = SignatureService.getInstance();
      const singUploadVideo = await signatureService.uploadVideoEvidenceContent(data);
      dispatch(success(singUploadVideo, fileFlowConstants.UPLOAD_VIDEO_SUCCESS));
    } catch (error) {
      console.log('errorSignVideo',error);
      dispatch(failure(error.message, fileFlowConstants.UPLOAD_VIDEO_FAILURE));
    }
  };
};


export const registerMetadataView = (data) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const registerMetadataView = await fileFlowService.registerMetadataView(
        data
      );
      dispatch(
        success(registerMetadataView.Body, fileFlowConstants.FILE_FLOW_SUCCESS)
      );
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        text: `Lo sentimos. ${error.message}`,
      });
      dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
    }
  };
};

export const UpdateSection = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileFlowConstants.UPDATE_SECTION_REQUEST));
    try {
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.UpdateSection(
        filesData
      );
      dispatch(
        success(fileAprobatewData.Body, fileFlowConstants.UPDATE_SECTION_SUCCESS)
      );
      Alert({
        icon: "success",
        title: "Guardado",
        text: "Se actualizo el documento de manera correcta.",
      });
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, fileFlowConstants.UPDATE_SECTION_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Error al actualizar el documento: " + error.message,
      });
    }
  };
};
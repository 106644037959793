import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { InboxTable } from "./InboxTable";
import { FileInformation } from "./FileInformation";
import Dashboards from "../../pages/Dashboards/Dashboards.js";
import BackdropComponent from "../../components/BackDrop";
import DocumentUploadXML from "../flow/CheckXML/DocumentUploadXML";
import { falseLoading, getFiles, setTitleGral } from "../../store/actions";
import { InboxSignature } from "./InboxSignature";
import Users from "../Users/Users";
import { TemplateManager } from "../TemplateManager/TemplateManager";
import { NewTemplateSteps } from "../TemplateManager/NewTemplateSteps";
import DonwloadDocuments from "../DonwloadDocuments/DonwloadDocuments.jsx";

const RootStyle = styled(Box)(({ theme }) => ({
  /* backgroundColor: "lightgreen", */
}));

export const Inbox = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const fileState = useSelector((state) => state.File);
  const fileFlow = useSelector((state) => state.FileFlow);

  const stagesDocumentList = () => {
    switch (fileState?.item?.FileData.StageName) {
      case "Revisión de documentos":
        return "Revisión de documentos de la solicitud";
      case "Notificacion De Asignacion de Revisión":
        return "Revisión de documentos de la solicitud";
      case "Revisión de Administrador":
        return "Revisión de documentos de la solicitud";
      case "Nom151":
        return "Revisión de documentos de la solicitud";
      case "Asignando solicitud de revisión":
        return "Revisión de documentos de la solicitud";
      case "NotificacionAsignacion":
        return "Notificacion de Asignacion";
      case "SeleccionParticipantes":
        return "Revisión de documentos de la solicitud";
      case "Firma de documentos":
        return "Documentos por firmar";
      case "NotificacionFirma":
        return "Documentos por firmar";
      case "Asignando solicitud de firma":
        return "Documentos por firmar";
      case "Notificacion De Finalización de Firma":
        return "Documentos por firmar";
      case "Integración de documentos":
        return "Documentos por firmars";
      case "OTP Evidencias firma de documentos":
        return "Documentos por firmar";
      case "Evidencias":
        return "Documentos por firmar";
      case "EvidenciasValidacion":
        return "Documentos por firmar";
      case "FinalizacionXml":
        return "Finalizado";
      case "Finalizado":
        return "Finalizado";
      case "Carga Docflow":
        return "Finalizado";
      case "CargaPrerrequisitos":
        return "Carga de requisitos previos";
      case "ValidacionPrerrequisitos":
        return "Documentos previos";
      case "Resumen":
        return fileState?.item?.FileData?.Stage?.Description;
      case "CapturaInformacion":
        return fileState?.item?.FileData?.Stage?.Description;
      default:
        break;
    }
  };

  const getTitle = (loc) => {
    console.log('locloc', loc)
    if (loc.includes("/inbox/search")) {
      return "Buscar solicitudes";
    } else if (loc.includes("/inbox/file-information")) {
      return stagesDocumentList();
    } else if (loc.includes("/inbox/file-information-search")) {
      return stagesDocumentList();
    } else if (loc.includes("/inbox/files")) {
      return "Bandeja: Solicitudes por atender";
    } else if (loc.includes("inbox/verify-xml")) {
      return "Verificación vía XML ";
    } else if (loc.includes("/inbox/dashboards")) {
      return "Dashboards";
    } else if (loc.includes("/inbox/InboxSign")) {
      return "Bandeja";
    } else if (loc.includes("/inbox/users")) {
      return "Administración de usuarios";
    } else if (loc.includes("/inbox/TemplateManager")) {
      return "Administración de Plantillas";
    } else if (loc.includes("/inbox/TemplateUpload")) {
      return "Carga de Plantilla";
    } else if (loc.includes("/inbox/donwloadFlow")) {
      return "Descarga de documentos";
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(setTitleGral(getTitle(location.pathname)));
  }, [getTitle]);

  useEffect(() => {
    dispatch(
      getFiles({
        SearchText: "",
        FechaInicio: "",
        FechaFin: "",
        Page: 1,
      })
    );
    dispatch(falseLoading());
  }, [fileState.loading_data]);

  return (
    <RootStyle>
      <BackdropComponent loading={fileFlow.loading} />
      <Box>
        <Routes>
          <Route index element={<Navigate to="files" />} />
          <Route path="dashboards" element={<Dashboards />} />
          <Route path="files" element={<InboxTable />} />
          <Route path="search" element={<InboxTable searching />} />
          <Route path="file-information" element={<FileInformation />} />
          <Route path="InboxSign" element={<InboxSignature />} />
          <Route
            path="file-information-search"
            element={<FileInformation searching />}
          />
          <Route path="users" element={<Users />} />
          <Route path="verify-xml" element={<DocumentUploadXML />} />
          <Route path="verify-xml?uid=idUser" element={<DocumentUploadXML />} />
          <Route path="TemplateManager" element={<TemplateManager />} />
          <Route path="TemplateUpload" element={<NewTemplateSteps />} />
          <Route path="donwloadFlow" element={<DonwloadDocuments />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Box>
    </RootStyle>
  );
};

import React, { useState } from "react";
import { Box, Tab as Tb, styled, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { FileInformationTab } from "./FileInformationTab";
import { FileDocumentTab } from "./FileDocumentTab";
import HistoryFile from "../record/HistoryFile";
import FlowViews from "../flow/FlowViews";
import { FlowExpedient } from "./FlowExpedient";
import { CapturePreviousDocuments } from "../CollaborativeEditing/CapturePreviousDocuments.jsx";
import { ApprovalModule } from "../CollaborativeEditing/ApprovalModule.jsx";
import { getFile } from "../../store/actions";
import BackdropComponent from "../../components/BackDrop";
import { DynamicQuestionnaire } from "../flow/DynamicQuestion/DynamicQuestionnaire";
import { FlowViewsDocuments } from "../flow/DynamicQuestion/FlowViewsDocuments";
import { FileService } from "../../services/FileService.js";

const useStyles = makeStyles((theme) => ({
  tabs: {
    textTransform: "none",
    minHeight: 30,
    flexGrow: 1,
    "& .MuiTabs-flexContainer button": {
      minHeight: 30,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.primary.selectMenu}`,
    // borderColor: "primary.main",
  },
}));

const Tab = styled(Tb)(({ theme }) => ({
  // borderRight: "1px solid #C20E30",
  textTransform: "none",
  minWidth: 200,
  margin: 3,
  borderRadius: "5px",
  "&.MuiTab-root": {
    padding: "0 5px",
    fontSize: 14,
    fontWeight: 500,
    color: "black",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.selectMenu,
    fontWeight: 700,
    color: theme.palette.primary.mainLyrics,
  },
}));

export const FileInformation = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const AuthenticationState = useSelector((state) => state.Authentication);
  const fileState = useSelector((state) => state.File);
  const dispatch = useDispatch();

  const checkNotificationSignerDoc = useSelector(
    (state) => state.File.item?.NotificationOptions?.NotifySignedDocuments
  );

  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );

  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );
  const participantCurso =
    userData?.Username === fileState?.item?.FileData?.CurrentUser;

  const participantCreation =
    userData?.Username === fileState?.item?.FileData?.CreationUser;

  let rolCurrent =
    AuthenticationState?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
      ?.Name;

  const ActiveCaptureFieldsState = useSelector(
    (state) =>
      state.File?.item?.FileData?.Stage?.Properties?.find(
        (item) => item.Name === "ActiveCaptureFields"
      )?.Value
  );

  const CurrentStatusState = useSelector(
    (state) =>
      state.File?.item?.FileData?.Stage?.WorkflowInstance?.CurrentStatus
  );

  const IsContinueDocumentsMetadataState = useSelector((state) =>
    state.File?.item?.Documents?.filter(
      (item) => item.DocumentData?.DocumentFieldsCaptured === false
    )
  );

  const valueBlockedDocuments =
    participantCreation === true ||
    rolCurrent === "Super usuario" ||
    (participantCreation !== true &&
      checkNotificationSignerDoc === true &&
      (stageFileFlow === "Finalizado" ||
        stageFileFlow === "Carga a Docflow" ||
        stageFileFlow === "Finalizado Cancelado"));

  const [value, setValue] = useState();
  const [isCuestionaryActive, setIsCuestionaryActive] = useState(false);
  const [validateReload, setValidateReload] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isActiveMassiveSignature =
    location?.state?.ActiveMassiveSignature === undefined ||
    location?.state?.ActiveMassiveSignature === null
      ? false
      : true;

  const stagesDocumentList = () => {
    switch (stageFileFlow) {
      case "Nuevo expediente":
        return "Documentos por Revisar";
      case "Revisión de documentos":
        return "Documentos por Revisar";
      case "Notificacion De Asignacion de Revisión":
        return "Documentos por Revisar";
      case "Revisión de Administrador":
        return "Documentos por Revisar";
      case "Nom151":
        return "Documentos por Revisar";
      case "Asignando solicitud de revisión":
        return "Documentos por Revisar";
      case "Captura de Información":
        return "Captura de Información";
      case "Firma de documentos":
        return "Documentos por Firmar";
      case "Notificacion De Asignacion de Firma":
        return "Documentos por Firmar";
      case "Asignando solicitud de firma":
        return "Documentos por Firmar";
      case "Notificacion De Finalización de Firma":
        return "Documentos por Firmar";
      case "Integración de documentos":
        return "Integración de documentos";
      case "OTP Evidencias firma de documentos":
        return "Firma de documentos";
      case "Evidencias firma de documentos":
        return "Firma de documentos";
      case "Validación de evidencias":
        return "Validación de evidencias";
      case "FinalizacionXml":
        return "Finalizado";
      case "Finalizado":
        return "Documentos";
      case "Carga a Docflow":
        return "Documentos";
      case "Carga de prerrequisitos":
        return "Requisitos Previos";
      case "Validación de prerrequisitos":
        return "Validación Documentos Previos";
      case "Finalizado cancelado":
        return "Documentos";
      default:
        break;
    }
  };

  const CasosUsoTabs = () => {
    switch (stageFileFlow) {
      case "Nuevo expediente":
        return setValue(1);
      case "Revisión de documentos":
        return setValue(1);
      case "Notificacion De Asignacion de Revisión":
        return setValue(1);
      case "Revisión de Administrador":
        return setValue(1);
      case "Captura de Información":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Nom151":
        return setValue(1);
      case "Asignando solicitud de revisión":
        return setValue(1);
      case "Firma de documentos":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Notificacion De Asignacion de Firma":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Asignando solicitud de firma":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Notificacion De Finalización de Firma":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Integración de documentos":
        return setValue(0);
      case "OTP Evidencias firma de documentos":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Evidencias firma de documentos":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Validación de evidencias":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "FinalizacionXml":
        return setValue(0);
      case "Finalizado":
        return setValue(0);
      case "Carga a Docflow":
        return setValue(0);
      case "Carga de prerrequisitos":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Validación de prerrequisitos":
        return participantCurso === true ? setValue(1) : setValue(0);
      case "Finalizado cancelado":
        return setValue(0);
      default:
        break;
    }
  };

  useEffect(() => {
    CasosUsoTabs();
  }, [stageFileFlow]);

  const previousDate = () => {
    if (
      stageFileFlow === "Carga de prerrequisitos" &&
      fileState.item.FileData.Id
    ) {
      dispatch(getFile({ FileId: fileState.item.FileData.Id }));
    }
  };

  /* console.log('stageFileFlow', stageFileFlow )
  useEffect(() => {
    if (stageFileFlow != "Captura de Información") {
      // Se termina el timeOute
      setValidateReload(false);
    } else if (validateReload) {
      const timeoutId = setTimeout(() => {
        dispatch(getFile({ FileId: fileState.item.FileData.Id }));
        setValidateReload(false); // Reinicia validateReload si es necesario
      }, 10000); // 10 segundos

      return () => clearTimeout(timeoutId); // Limpiar el timeout si el componente se desmonta o los valores cambian
    }
  }, [validateReload, stageFileFlow]); */

  useEffect(() => {
    console.log("validateReloadData", validateReload, stageFileFlow);
    if (validateReload == true) {
      let timeoutId;
      const fetchDataWithRetry = async () => {
        const fileService = FileService.getInstance();
        const response = await fileService.getFile({
          FileId: fileState.item.FileData.Id,
        });
        dispatch(getFile({ FileId: fileState.item.FileData.Id }));
        console.log("ResponseDATAApi", response, stageFileFlow);
        // Suponiendo que tu acción retorna una promesa con la respuesta
        if (
          response.Body !== undefined &&
          response.Body &&
          response.Body.FileData.Stage.FlowState == "Firma de documentos"
        ) {
          console.log("validateReload 3", validateReload);
          setValidateReload(false); // Respuesta esperada, detener reintentos
          return () => clearTimeout(timeoutId);
        } else {
          console.log("validateReload 4", validateReload);
          timeoutId = setTimeout(fetchDataWithRetry, 10000); // Reintentar después de 10 segundos
        }
      };
      console.log("validateReload 0", validateReload);
      if (validateReload && stageFileFlow != "Firma de documentos") {
        console.log("validateReload 1", validateReload);
        fetchDataWithRetry();
      } else {
        console.log("validateReload 2", validateReload);
        setValidateReload(false);
        return () => clearTimeout(timeoutId);
      }

      return () => clearTimeout(timeoutId);
    }
  }, [validateReload]);

  return (
    <Box sx={{ width: "100%" }}>
      <BackdropComponent loading={fileState.loading || validateReload} />
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid
          item
          lg={12}
          xs={12}
          sx={{
            display: { xs: "flex", md: "center" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="disabled tabs example"
          >
            <Tab label="Información" />
            <Tab label={stagesDocumentList()} onClick={() => previousDate()} />
            <Tab label="Flujo de la Solicitud" />
            {/* {rolCurrent === "Super usuario" ? (
              <Tab label="Administración" />
            ) : null} */}
            {location.pathname === "/inbox/file-information-search" ? (
              <Tab label="Bitácora" />
            ) : null}
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <FileInformationTab />
      </TabPanel>
      {console.log("stageFileFlow", stageFileFlow)}
      <TabPanel value={value} index={1}>
        {stageFileFlow === "Finalizado" ||
        stageFileFlow === "Carga a Docflow" ||
        stageFileFlow === "Finalizado cancelado" ? (
          <FileDocumentTab />
        ) : stageFileFlow === "Carga de prerrequisitos" ? (
          <CapturePreviousDocuments />
        ) : stageFileFlow === "Validación de prerrequisitos" ? (
          <ApprovalModule />
        ) : isCuestionaryActive == true &&
          ActiveCaptureFieldsState === "1" &&
          !isActiveMassiveSignature &&
          (CurrentStatusState === "Inicializado" ||
            (CurrentStatusState.includes(
              "Se registraron los campos de captura para el documento"
            ) &&
              IsContinueDocumentsMetadataState?.length > 0)) ? (
          <DynamicQuestionnaire
            setValidateReload={setValidateReload}
            setIsCuestionaryActive={setIsCuestionaryActive}
          />
        ) : ActiveCaptureFieldsState === "1" &&
          !isActiveMassiveSignature &&
          (CurrentStatusState === "Inicializado" ||
            (CurrentStatusState.includes(
              "Se registraron los campos de captura para el documento"
            ) &&
              IsContinueDocumentsMetadataState?.length > 0)) ? (
          <FlowViewsDocuments setIsCuestionaryActive={setIsCuestionaryActive} />
        ) : stageFileFlow === "Captura de Información" &&
          isCuestionaryActive === false ? (
          <FlowViewsDocuments setIsCuestionaryActive={setIsCuestionaryActive} />
        ) : stageFileFlow === "Captura de Información" &&
          participantCurso === true ? (
          <DynamicQuestionnaire
            setValidateReload={setValidateReload}
            TemplateData={true}
            setIsCuestionaryActive={setIsCuestionaryActive}
          />
        ) : (
          <FlowViews
            isActiveMassiveSignature={isActiveMassiveSignature}
            TemplateData={true}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FlowExpedient />
      </TabPanel>
      {/*  {rolCurrent === "Super usuario" ? (
        <TabPanel value={value} index={3}>
          <FileConfigurationAdminTab />
        </TabPanel>
      ) : null} */}
      {location.pathname === "/inbox/file-information-search" ? (
        <TabPanel value={value} index={3}>
          <HistoryFile />
        </TabPanel>
      ) : null}
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
};

// {console.log(
//   "DATOSENVIADOS",
//   ActiveCaptureFieldsState === "1" &&
//     !isActiveMassiveSignature &&
//     (CurrentStatusState === "Inicializado" ||
//       (CurrentStatusState.includes(
//         "Se registraron los campos de captura para el documento"
//       ) &&
//         IsContinueDocumentsMetadataState?.length > 0))
// )}

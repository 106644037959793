import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  styled,
} from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import {
  DeleteLogicTemplate,
  GetTemplates,
  resetTemplate,
} from "../../store/actions";
import { GenericTable } from "../../components/GenericTable";
import sign from "../../assets/sign.svg";
import { GenericTableSelection } from "../../components/GenericTableSelection";
import { Alert, AlertConfirm } from "../../components/Alert";

moment.locale("es");

const columns = [
  {
    Header: "Id",
    accessor: "TemplateId",
  },
  {
    Header: "Plantilla",
    accessor: "TemplateName",
  },
  {
    Header: "Categoría",
    accessor: "CategoryName",
  },
  {
    Header: "Fecha de creación",
    accessor: "CreationDate",
  },
  {
    Header: "Extension",
    accessor: "Extension",
  },
  {
    Header: "CategoryId",
    accessor: "CategoryId",
  },
  {
    Header: "Estatus",
    accessor: "Active",
  },
  {
    Header: "Fecha de modificacion",
    accessor: "LastModification",
  },
];

const Image = styled("img")(({ theme }) => ({
  width: 170,
  marginTop: 30,
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    width: 150,
  },
}));

export const TemplateManager = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [folioSelectedSignature, setFolioSelectedSignature] = useState([]);

  const formatDefaultDate = new Date();

  const GetTemplatesState = useSelector((state) => state.GetTemplatesData);
  const DeleteTemplate = useSelector((state) => state.DeleteLogicTemplate);
  const rolUserTemplate = useSelector(
    (state) =>
      state.Authentication.items.UserLoginData.SecurityLoginData.Roles[0].Code
  );

  console.log("rolUserTemplate", rolUserTemplate);

  const dispatch = useDispatch();
  const formMethods = useForm();

  useEffect(() => {
    dispatch(GetTemplates(0));
  }, []);

  //Efecto para mapear la data de la api guardada en redux para la tabla
  useEffect(() => {
    if (GetTemplatesState?.items) {
      const filesTable = GetTemplatesState?.items?.map((itemData) => {
        return {
          TemplateId: itemData.Template_Id,
          TemplateName: itemData.FileName,
          CategoryName: itemData.CategoryName,
          CreationDate: `${moment(
            itemData.CreationDate ?? formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A")}`,
          FechaCreacion: itemData.CreationDate,
          Extension: itemData.Extension,
          CategoryId: itemData.Category_Id,
          Active: itemData.Active ? "Activo" : "Inactivo",
          LastModification: `${moment(
            itemData.LastModification
              ? itemData.LastModification
              : formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A")}`,
          Tag: itemData.Tag,
          ReplicateSignatureZones: itemData.ReplicateSignatureZones,
        };
      });
      setdata(filesTable);
    } else {
      setdata([]);
    }
  }, [GetTemplatesState]);

  useEffect(() => {
    formMethods.reset();
  }, [location.pathname, formMethods]);
//Ajuste de usuarios
  const deleteTempletes = () => {
    AlertConfirm(
      {
        icon: "warning",
        title:
          "¿Estas seguro que deseas eliminar esta plantilla de forma permanente?",
        okbtntext: "Aceptar",
        notbtntext: "Cancelar",
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          dispatch(DeleteLogicTemplate({ Body: folioSelectedSignature }));
        }
      }
    );
  };

  useEffect(() => {
    if (DeleteTemplate.items?.IsOK == true) {
      Alert({
        icon: "success",
        text: DeleteTemplate.items.Body,
        okbtntext: "Aceptar",
      });
      dispatch(DeleteLogicTemplate("", true));
      dispatch(GetTemplates(0));
    } else if (DeleteTemplate.items?.IsOK == false) {
      Alert({
        icon: "error",
        text: DeleteTemplate.items?.Messages,
        okbtntext: "Aceptar",
      });
      dispatch(DeleteLogicTemplate("", true));
    }
  }, [DeleteTemplate.loading]);

  const renderTable = () => {
    return (
      <>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {data?.length > 0 ? (
            rolUserTemplate == "Super usuario" ? (
              <GenericTableSelection
                Columns={columns}
                Data={data}
                onClickRow={(row) => {
                  navigate("/inbox/TemplateUpload", {
                    state: row.original,
                  });
                }}
                ShowPagination={false}
                ClickSelectedRows={(data) => {
                  console.log("itemitem", data);
                  let array = data.map((item) => {
                    return item.original.TemplateId;
                  }); // Se filtra solo el objeto original
                  setFolioSelectedSignature(array);
                }}
              />
            ) : (
              <GenericTable
                Columns={columns}
                Data={data}
                onClickRow={(row) => {
                  console.log("row: ", row);
                  navigate("/inbox/TemplateUpload", {
                    state: row.original,
                  });
                }}
                HiddenColumns={["TemplateId", "CategoryId", "LastModification"]}
                ShowPagination={false}
                ClickSelectedRows={(data) => {}}
              />
            )
          ) : null}
        </Box>
      </>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={3} sx={{ p: 1 }}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ height: 38, px: 10, p: 1 }}
              onClick={() => deleteTempletes()}
            >
              Eliminar plantilla
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ p: 1 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: 38, px: 10 }}
              onClick={() => {
                dispatch(resetTemplate());
                navigate("/inbox/TemplateUpload");
              }}
            >
              Nueva plantilla
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          {GetTemplatesState?.loading ? (
            <Box sx={{ my: 10, display: "grid", placeContent: "center" }}>
              <CircularProgress
                size={60}
                sx={{ margin: "auto", display: "flex" }}
              />
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1">Buscando</Typography>
              </Box>
            </Box>
          ) : (
            (function () {
              if (data === undefined || data?.length === 0) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Image alt="firma" src={sign} />
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: 600, my: 2 }}
                      align="center"
                    >
                      Sin plantillas cargadas
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ maxWidth: 600, color: "secondary.main80" }}
                      align="center"
                    >
                      No tienes plantillas cargadas para tus flujos
                    </Typography>
                  </Box>
                );
              }
              return renderTable();
            })()
          )}
        </Box>
      </LocalizationProvider>
    </FormProvider>
  );
};

import {
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomModalAlert } from "../../components/CustomModalAlert";
import { validateDocuments, cancelWorkFlow, getFile, restartFlow } from "../../store/actions";

export default function RevisarOtroMomento({ firma }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const file = useSelector((state) => state.File);
  const document = useSelector((state) => state.File?.document);
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );
  const [comentarios, setComentarios] = useState("");
  const [open, setOpen] = useState(false);

  const statusAdmin =
    userData?.Username === file?.item?.FileData?.CreationUser;

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const aprobarDocumento = (state) => {
    if (state) {
      // Aprobar un documento sin revisarlo
      if (statusAdmin) {
        // REINICIA EL ADMIN
        dispatch(
          restartFlow({
            Body: {
              Document_Id: document?.DocumentData?.Id
            }
          }),
          setOpen(!open));
        setComentarios("");
        dispatch(getFile({ FileId: file?.item?.FileData?.Id }));
      } else {
        // REINICIA EL USER
        dispatch(
          validateDocuments({
            Body: {
              Document_Id: document?.DocumentData?.Id,
              Section_Id: document?.DocumentData?.Sections[0]?.Section_Id,
              Approved: state,
              Comments: "",
              ValidateFileComplete: true,
            },
          }),
          setOpen(!open));
        setComentarios("");
      }
    } else {
      // Rechzar la firma del usuario
      dispatch(
        cancelWorkFlow({
          Body: {
            File_Id: file.item?.FileData?.Id,
            Status: null,
            Result: null,
            Stage_Id: null,
            Comments: comentarios
          }
        }), setOpen(!open));
      setComentarios("");
      dispatch(getFile({ FileId: file?.item?.FileData?.Id }));
      navigate('/inbox/files');
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyItems: "center",
          minWidth: 230,
          justifyContent: "center",
        }}
        onClick={handleClickOpen}
      >
        <Button
          fullWidth
          size="medium"
          variant={firma === true ? "outlined" : "contained"}
          color="primary"
        >
          <Typography sx={{ letterSpacing: 1.2 }}>
            {firma === true ? "Rechazar" : "Aprobar"}
          </Typography>
        </Button>
      </Box>

      <CustomModalAlert
        Open={open}
        Title={firma === true
          ? "Rechazar firma del documento"
          : "Aprobar documento"}
        SubTitle="Especifica el motivo de rechazo del documento."
        LeftButtonText="Cancelar"
        LeftButtonAction={handleClickOpen}
        RightButtonText="Aceptar"
        Comments={true}
        ValueComment={comentarios}
        CommentPlaceHolder="Motivo de rechazo"
        setValueComment={setComentarios}
        RightButtonAction={() => aprobarDocumento(firma === true ? false : true)}
      />

    </>
  );
}

import React from "react";
import { Tooltip, styled, tooltipClasses, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export const TypoTooltip = (props) => {
  return (
    <Typography
      variant={props.variant ?? "subtitle1"}
      sx={{
        mb: 1,
        display: "flex",
        alignItems: "center",
        ...(props.sxTypo ?? {}),
      }}
    >
      {props.text}
      {props.tooltipText && (
        <Tip title={props.tooltipText} placement="top" arrow enterTouchDelay={0}>
          {props.icon ? props.icon : <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />}
        </Tip>
      )}
    </Typography>
  );
};

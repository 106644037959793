import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import {
  FileUploadOutlined as IconFile,
} from "@mui/icons-material";
import { Circle } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { UTCtoLocal } from "../../utils";
import { Box } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.table.secondary} !important`,
    color: theme.palette.primary.text,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const DocumentListTable = (props) => {
  const fileState = useSelector((state) => state.File);
  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );
  const participantCurso =
    userData?.Username === fileState?.item?.FileData?.CurrentUser;

  const dataPrevious = useSelector((state) => state.File.item.Prerequisites);

  return (
    <TableContainer sx={{ textAling: "center" }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nombre del documento</StyledTableCell>
            <StyledTableCell align="center">
              Fecha límite de carga
            </StyledTableCell>
            <StyledTableCell align="center">Estatus</StyledTableCell>
            <StyledTableCell colSpan={1} align="center">
              Acciones
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.Rows?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {`${row.Name} ${row.Required ? "*" : ""}`}
              </StyledTableCell>
              <StyledTableCell align="center">
                {UTCtoLocal(dataPrevious.UploadPrerequisitesDeadline, false)}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Circle
                    sx={{ mb: "-1px", mr: 1, fontSize: 14 }}
                    style={{
                      color:
                        row.Approved === null
                          ? row.Status === "Pendiente"
                            ? "#898B8D"
                            : "#FBBF28"
                          : row.Approved === true
                          ? "#4CAF50"
                          : "#C20E30",
                    }}
                  />
                  <Typography
                    align="center"
                    sx={(theme) => ({
                      fontWeight: 550,
                      fontSize: "12px !important",
                      color:
                        row.Approved === null
                          ? row.Status === "Pendiente"
                            ? "#898B8D"
                            : "#FBBF28"
                          : row.Approved === true
                          ? "#4CAF50"
                          : "#C20E30",
                    })}
                  >
                    {row.Approved === null
                      ? row.Status === "Pendiente"
                        ? "Sin Archivo"
                        : "Cargado"
                      : row.Approved === true
                      ? "Aprobado"
                      : "Rechazado"}
                  </Typography>
                </Box>
              </StyledTableCell>

              {/* <StyledTableCell width={50} align="center">
                <IconButton id={row.Name}
                  disabled={row.Approved}
                  sx={{ pointerEvents: "auto" }}
                  onClick={props.OnCamera}
                >
                  <IconCamera id={row.Name} />
                </IconButton>
              </StyledTableCell> */}
              <StyledTableCell width={50} align="center">
                {participantCurso === true ? (
                  <IconButton
                    id={row.Name}
                    disabled={row.Approved}
                    sx={{ pointerEvents: "auto" }}
                    onClick={props.OnFile}
                  >
                    <IconFile className="intermitente" id={row.Name} />
                  </IconButton>
                ) : null}
              </StyledTableCell>
              {/* <StyledTableCell width={50} align="center">
                <IconButton id={row.Name}
                  sx={{ pointerEvents: "auto" }}
                  onClick={props.OnDelete}
                >
                  <IconDelete id={row.Name} />
                </IconButton>
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { Button, Box, Typography, Grid, CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisorPDF from "../../../components/Viewer/VisorPDF";
import BackdropComponent from "../../../components/BackDrop";
import { FileService } from "../../../services/FileService.js";
import { Icon } from "@iconify/react";
import { B64toBlob, convertWordB64ToPdfB64 } from "../../../utils/Tools.js";

export const FlowViewsDocuments = (props) => {
  const [listDocumentFieldData, setlistDocumentFieldData] = useState([]);
  const [currentlySelectedDocument, setCurrentlySelectedDocument] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const [urlImagen, setUrlImagen] = useState(null);

  // Control de estados
  const ListDocumentsMetadataState = useSelector(
    (state) => state.File?.item?.Documents
  );
  const fileState = useSelector((state) => state.File);

  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );
  const participantCurso =
    userData?.Username === fileState?.item?.FileData?.CurrentUser;

  //Peticiones a apis
  useEffect(() => {
    if (ListDocumentsMetadataState?.length > 0) {

      let newArrayDocs = ListDocumentsMetadataState?.map((item) => {
        let ListDocumentsFormat = item.DocumentData?.DocumentFields?.map(
          (itemField) => {
            return {
              ...itemField,
              Required: true,
              Id: `${itemField.Name}_${itemField.DocumentField_Id}_${item.DocumentData.Id}`,
              DocumentDataId: item.DocumentData.Id,
            };
          }
        );
        return {
          ...item.DocumentData,
          ListFormatDocument: ListDocumentsFormat,
        };
      });

      setlistDocumentFieldData(newArrayDocs);
    }
  }, [ListDocumentsMetadataState]);

  useEffect(() => {
    if (listDocumentFieldData?.length > 0) {
      setCurrentlySelectedDocument(listDocumentFieldData[0]);
    }
  }, [listDocumentFieldData]);
  
  useEffect(() => {
    if (fileState?.item?.FileData?.Id > 0) {
      traerCodigoQR();
    }
  }, [fileState?.item?.FileData?.Id]);

  const traerCodigoQR = async () => {
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getFileQR({
        idFile: fileState?.item?.FileData?.Id,
        token: sessionStorage.token,
      });
      const url = window.URL.createObjectURL(new Blob([comentData.data.data]));
      setUrlImagen(url);
    } catch (error) {
    }
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid container>
        <Grid container item xs={12} sm={participantCurso === true ? 8 : 12}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              p: 2,
              mt: 0,
              borderRadius: 2,
            }}
          >
            <Grid
              container
              justifyContent={"rigth"}
              sx={{ mb: 1, pb: 1, borderBottom: "1px solid #898B8D4D" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={8}>
                <Box sx={{ mt: 1.5 }}>
                  <Typography variant="caption" sx={{ fontWeight: 500, mb: 2 }}>
                    Estatus:{" "}
                    <span style={{ color: " #C20E30" }}>
                      Firma de documentos{" "}
                    </span>
                  </Typography>
                </Box>
              </Grid>
              {participantCurso === true ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ justifyItems: "end", mt: 1 }}
                >
                  <Button
                    fullWidth
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      console.log("event button inciar proceso");
                      props.setIsCuestionaryActive(true);
                    }}
                  >
                    <Typography sx={{ letterSpacing: 1.2 }}>
                      Iniciar proceso
                    </Typography>
                  </Button>
                </Grid>
              ) : null}
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  Documento a firmar
                </Typography>
              </Box>
              {participantCurso === true ? (
                <Typography variant="body1" sx={{ fontWeight: 300 }}>
                  Los siguientes documentos serán firmados, antes de realizar el
                  firmado, deberás capturar la información requerida para llenar
                  los campos marcados como #campo#
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ fontWeight: 300 }}>
                  {`La solicitud se encuentra en proceso de captura de datos por ${fileState?.item?.FileData?.CurrentUser}`}
                </Typography>
              )}
            </Grid>
            <Box sx={{ pt: 2, pr: 2 }}>
              {listDocumentFieldData?.map((itemData, index) => (
                <AccordeonDocument itemData={itemData} index={index} />
              ))}
            </Box>
          </Grid>
        </Grid>
        {participantCurso === true ? (
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Box
              textAlign="center"
              sx={{ ml: 1, mt: 7, borderLeft: "1px solid #898B8D", p: 1 }}
            >
              <Typography variant={"h6"}>
                Si lo prefieres, puedes escanear el siguiente código QR desde tu
                dispositivo móvil para continuar con el flujo.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <img src={urlImagen} alt="ImagenQR" style={{ width: "75%" }} />
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const AccordeonDocument = ({ itemData, index }) => {
  const [blobUrl, setBlobUrl] = useState("");

  const traerDocumentos = async (documentCurrentId) => {
    const fileServiceInstance = FileService.getInstance();
    const comentData = await fileServiceInstance.getDownloadDocument({
      document_Id: documentCurrentId,
      ConvertSfdt: false,
    });

    if (comentData.Body) {
      if (itemData?.Extension === "docx" || itemData?.Extension === ".docx") {
        console.log("PASO 6");
        const docConvertido = convertWordB64ToPdfB64(
          comentData?.Body?.B64Content
        );
        docConvertido.then((value) => {
          setBlobUrl(value);
        });
      } else {
        const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
        setBlobUrl(URL.createObjectURL(blob));
      }
    }
  };

  console.log("index", index);

  useEffect(() => {
    if (index == 0) {
      traerDocumentos(itemData?.Id);
    }
  }, [index]);

  return (
    <Accordion defaultExpanded={index == 0 ? true : null} disableGutters>
      <AccordionSummary
        onClick={() => traerDocumentos(itemData?.Id)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          p: 0,
          "&.Mui-expanded": {
            height: 50,
            color: "#C20E30" /* Cambia este color por el que desees */,
            borderRadius: "3px",
            border: "1px solid #C20E30",
            background: "rgba(194, 14, 48, 0.15)",
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
          },
        }}
      >
        <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
          <Icon
            icon="solar:document-broken"
            fontSize={23}
            style={{ padding: 1, marginRight: 2 }}
          />
          <Typography variant="titulo" sx={{ fontSize: "20px !important" }}>
            {itemData.Name}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0,
            flexWrap: { xs: "wrap" },
          }}
        >
          {blobUrl ? (
            <VisorPDF pdfUrl={blobUrl ?? ""} pdfName={itemData.Name ?? ""} />
          ) : (
            <Spinner />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};

// const ButtonViewDocument = (props) => {
//   console.log("ButtonActionDocument props: ", props?.documentSeleted);
//   const active = (item) => {
//     return props?.documentSeleted?.Id === item?.Id;
//   };

//   return (
//     <Box sx={{ display: "flex", flexDirection: "row", overflowX: "auto" }}>
//       {props.ListDocuments?.map((item, index) => (
//         <ButtonViewer
//           item={item}
//           active={active}
//           index={index}
//           selectedDocument={props.selectedDocument}
//         />
//       ))}
//     </Box>
//   );
// };

// const ButtonViewer = ({ item, active, selectedDocument }) => {
//   return (
//     <Button
//       sx={(theme) => ({
//         color: active(item) === true ? "#08905C" : theme.palette.secondary.dark,
//         width: "100%",
//         borderBottom: active(item) === true ? "1.6px solid #08905C" : "none",
//         margin: "0px",
//         padding: "0px",
//         m: 1,
//         "&:hover": {
//           background: active(item) ? "#DEFBD4" : "#F7F8FA",
//         },
//         [theme.breakpoints.down("md")]: {
//           width: "100%",
//         },
//         borderRadius: 0,
//         maxWidth: 200,
//         fontSize: "14px !important",
//         fontWeight: 600,
//         minWidth: 180,
//         // maxHeight: 22
//       })}
//       onClick={() => {
//         selectedDocument(item);
//       }}
//     >
//       {item.Name}
//     </Button>
//   );
// };

import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class FileFlowService {
  static instance;

  constructor() {
    this.baseUrl = "FileCustom";
  }

  static getInstance() {
    if (!FileFlowService.instance) {
      FileFlowService.instance = new FileFlowService();
    }
    return FileFlowService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    let respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      let responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  // API PARA PODER INICIALIZAR EL FLUJO
  async initializeFileFlow(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/InitializeWorkflowFileCustom`,
      data: data,
      config: Config.configAuth(),
    });
    // const files = await this.handleResponseForceEncripted(response);
    return response;
  }

  // PARA PODER APROBAR O RECHAZAR UN DOCUMENTO EN REVISION
  async validateDocuments(data) {
    const response = await GenericService.post({
      endpoint: `validation/RegisterDocumentValidation`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  };

  // PARA PODER CERRAR EL DOCUMENTO EN PROCESO DE VALIDACION
  async closeDocumentVersion(data) {
    const response = await GenericService.post({
      endpoint: `validation/CloseDocumentVersion`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  };

  // PARA PODER RECHAZAR UN DOCUMENTO EN FIRMA
  async cancelWorkFlow(data) {
    const response = await GenericService.post({
      endpoint: `workflow/CancelWorkFlow`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

   // PARA PODER RECHAZAR UN EXPEDIENTES MASIVOS
   async cancelWorkFlowMasive(data) {
    const response = await GenericService.post({
      endpoint: `workflow/CancelWorkFlowMasive`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

    // PARA PODER ELIMINAR UN EXPEDIENTES MASIVOS
    async logicDeleteFile(data) {
      const response = await GenericService.post({
        endpoint: `FileCustom/LogicDeleteFile`,
        data: data,
        config: Config.configAuth(),
      });
      const files = await this.handleResponseForceEncripted(response);
      return files;
    }

  // PARA PODER CARGAR UNA NUEVA VERSION DE DOCUMENTOS EN REVISION
  async uplodadNewDocumentVersion(data) {
    const response = await GenericService.post({
      endpoint: `document/UploadSection`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async UpdateSection(data) {
    const response = await GenericService.post({
      endpoint: `document/UpdateSection`,
      data: data,
      config: Config.configAuth(),
    });
    // const files = await this.handleResponseForceEncripted(response);
    return response;
  }

  //Para poder tomar un documento y convertilro de .doc a .pdf
  async convertDocumentWordToPdf(data) {
    const response = await GenericService.post({
      endpoint: `document/ConvertDocumentWordToPdf`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const roles = await this.handleResponseForceEncripted(response);
    return roles;
  }

  // REINICIAR EL FLUJO DENTRO DE ADMINISTRADOR PARA RETOMAR EL DOCUMENTO
  async restartFlow(data) {
    const response = await GenericService.post({
      endpoint: `document/RestartFlow`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  // ACEPTAR EL RECHAZO DE PARTE DEL ADMIN PARA PODER CONTINUAR CON EL FLUJO
  async acceptanceRejection(data) {
    const response = await GenericService.post({
      endpoint: `document/AcceptanceRejection`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  // Para poder descargar el video
  async downloadDocumentByReference(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadDocumentByReference`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  // Para poder actualiza la ventana en la que se encuentra
  async registerMetadataView(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterMetadataView`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  // PARA PODER REGISTRAR
  async registerStatusStage(data) {
    const response = await GenericService.post({
      endpoint: `workflow/RegisterStatusStage`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async SetDocumentValidation(data) {
    const response = await GenericService.post({
      endpoint: `validation/SetDocumentValidation`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  };

  
  async SendMailNotification(data) {
    const response = await GenericService.post({
      endpoint: `notification/NotificationAsignacion`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async NotificationReenvioFinalizacionFirma(data) {
    const response = await GenericService.post({
      endpoint: `notification/NotificationReenvioFinalizacionFirma`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }
  
  async GetTicketPayment(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetTicketPayment`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async CloneFileById(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/CloneFileById`,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }



}

import Parser from "html-react-parser";
import { newFileConstants } from "../constants";
import { FileService } from "../../services/FileService";
import { Alert, AlertConfirm } from "../../components/Alert.jsx";
import { Box, Typography } from "@mui/material";
import { FileFlowService } from "../../services/FileFlowService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (newFileData, actionType) => {
  return {
    type: actionType,
    payload: newFileData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const createFile = (fileData, callbackSuccess = () => { }) => {

  return async (dispatch) => {
    dispatch(request(newFileConstants.NEW_FILE_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileCreated = await fileService.registerFile(fileData);

      if (fileCreated.Body) {
        const fileFlowService = FileFlowService.getInstance();
        await fileFlowService.registerStatusStage({
          File_id: fileCreated?.Body?.Id,
          Status: fileData.FileConfiguration.Prerequisites.ActivePrerequisites === true ? "Requisitos Previos" : "Carga de documentos",
          Result: null,
        });
      };

      sessionStorage.setItem("FileId", fileCreated?.Body?.Id);

      dispatch(
        success(
          { ...fileCreated.Body, fileForm: fileData },
          newFileConstants.NEW_FILE_SUCCESS
        )
      );

      Alert(
        {
          icon: "success",
          title: "Solicitud registrada",
          okbtntext: "Aceptar",
          // okbtnclas-s: "confirmButtonSweetCustom",
          html: (
            <Box>
              <Typography sx={{
                maxWidth: 400, mx: "auto",
                display: 'inline',
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: '21px',
                lineHeight: '37px',
                fontFamily: '"Montserrat",sans-serif',
                ['@media only screen and (max-width: 812px)']: {
                  fontSize: '15px',
                  lineHeight: '20.72px',
                }
              }} >
                {Parser(`Número de folio: <span style="color: #8CE982"}}>${fileCreated.Body.Folio}</span>`)}
              </Typography>
            </Box>
          ),
        },
        callbackSuccess
      );
    } catch (error) {
      const rolUser = sessionStorage.getItem('Rol')
      if (error.code === 102 || error.code === 101) {
        if (rolUser === "Super usuario") {
          AlertConfirm(
            {
              icon: "warning",
              text: error.message,
              notbtntext: "Entendido",
              okbtntext: "Ir a mis planes",
            },
            (action) => {
              if (action.isConfirmed === true || action.value === true) {
                dispatch(failure(error.message, newFileConstants.NEW_FILE_FAILURE));
                window.location.href = '/inbox/payments';
              } else {
                dispatch(failure(error.message, newFileConstants.NEW_FILE_FAILURE));
              }
            }
          );
        } else {
          Alert({
            icon: "warning",
            title: error.message,
            okbtntext: "Entendido"
          }, () => {
            dispatch(failure(error.message, newFileConstants.NEW_FILE_FAILURE));
          });
        }
      } else {
        dispatch(failure(error.message, newFileConstants.NEW_FILE_FAILURE));
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + error.message,
        });
      }

    }
  };
};


export const updateFileCustom = (fileData, callbackSuccess = () => { }) => {
  return async (dispatch) => {
    dispatch(request(newFileConstants.NEW_FILE_REQUEST));
    try {

      const fileService = FileService.getInstance();
      const fileCreated = await fileService.updateFileCustom(fileData);

      if (fileCreated.Body) {
        const fileFlowService = FileFlowService.getInstance();
        await fileFlowService.registerStatusStage({
          File_id: fileCreated?.Body?.Id,
          Status: fileData.FileConfiguration.Prerequisites.ActivePrerequisites === true ? "Requisitos Previos" : "Carga de documentos",
          Result: null,
        });
      };

      dispatch(
        success(
          { ...fileCreated.Body, fileForm: fileData },
          newFileConstants.NEW_FILE_SUCCESS
        )
      );

      Alert(
        {
          icon: "success",
          title: "Solicitud editada",
          okbtntext: "Aceptar",
          // okbtnclass: "confirmButtonSweetCustom",
          html: (
            <Box>
              <Typography sx={{
                maxWidth: 400, mx: "auto",
                display: 'inline',
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: '21px',
                lineHeight: '37px',
                fontFamily: '"Montserrat",sans-serif',
                ['@media only screen and (max-width: 812px)']: {
                  fontSize: '15px',
                  lineHeight: '20.72px',
                }
              }} >
                {Parser(`Número de folio: <span style="color: #8CE982"}}>${fileCreated.Body.Folio}</span>`)}
              </Typography>
            </Box>
          ),
        },
        callbackSuccess
      );
    } catch (error) {
      console.log(error);
      dispatch(failure(error.message, newFileConstants.NEW_FILE_FAILURE));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };
};

export const successFormExist = (newFileData) => {
  return {
    type: newFileConstants.NEW_FILE_SUCCESS,
    payload: newFileData,
  };
};


export const resetNewFile = () => {
  return {
    type: newFileConstants.RESET_NEW_FILE,
  };
};

export const resetNewDocument = () => {
  return {
    type: newFileConstants.RESET_NEW_DOCUMENT,
  };
};

export const setNewDocument = (documentData) => {
  return {
    type: newFileConstants.SET_NEW_DOCUMENT,
    payload: documentData,
  };
};

export const resetPdf = () => {
  return {
    type: newFileConstants.RESET_PDF,
  };
};

export const setForm = (formData) => {
  return {
    type: newFileConstants.SET_FORM,
    payload: formData,
  };
};

export const unsetForm = () => {
  return {
    type: newFileConstants.UNSET_FORM,
  };
};

export const setlistSigners = (list) => {
  return {
    type: newFileConstants.SET_SIGN_PARTICIPANTS,
    payload: list,
  };
};

export const setlistCheckers = (list) => {
  return {
    type: newFileConstants.SET_CHECK_PARTICIPANTS,
    payload: list,
  };
};

export const setlistCapturet = (list) => {
  return {
    type: newFileConstants.SET_CAPTURE_PARTICIPANTS,
    payload: list,
  };
};

export const setlistViewers = (list) => {
  return {
    type: newFileConstants.SET_VIEW_PARTICIPANTS,
    payload: list,
  };
};

export const deleteAllParticipants = () => {
  return {
    type: newFileConstants.UNSET_ALL_PARTICIPANTS,
  };
};

export const addToDocsStack = (doc) => {
  return {
    type: newFileConstants.ADD_TO_DOCS_STACK,
    payload: doc,
  };
};

export const resetDocsStack = () => {
  return {
    type: newFileConstants.RESET_DOCS_STACK,
  };
};

export const setDocsStack = (stack) => {
  return {
    type: newFileConstants.SET_DOCS_STACK,
    payload: stack,
  };
};

export const setPrerrequisites = (stack) => {
  return {
    type: newFileConstants.SET_PRERREQUISITES,
    payload: stack,
  };
};

export const resetPrerrequisites = (stack) => {
  return {
    type: newFileConstants.RESET_PRERREQUISITES,
  };
};

export const editStack = (data) => {
  return {
    type: newFileConstants.EDIT_STACK,
    payload: data,
  };
};

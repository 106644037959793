import React, { Fragment, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import imageDonwload from "../../assets/DonwloadDocumentsFlow.png";
import imageDonwloadExpired from "../../assets/DonwloadDocumentsExpired.png";
import { useLocation } from "react-router-dom";
import { DocumentService } from "../../services/DocumentService";
import BackdropComponent from "../../components/BackDrop";

export default function DonwloadDocuments() {
  const query = new URLSearchParams(useLocation().search);
  const uidDonwloadDocument = query.get("uidDocument");
  const idDonwloadDocument = query.get("document_Id");
  const [loading, setLoading] = useState(false);

  const [dataDocument, setDataDocument] = useState(true);

  console.log("idDonwloadDocument", idDonwloadDocument);

  const downloadDocument = async () => {
    setLoading(true);
    console.log("response", uidDonwloadDocument);
    try {
      const blob =
        await DocumentService.getInstance().DownloadDocumentContentByUId({
          uid: uidDonwloadDocument,
          document_Id: idDonwloadDocument,
        });
        console.log('blobblob', blob )
      if (blob) {
        // Crear un enlace de descarga
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Expediente.zip"); // Puedes ajustar el nombre del archivo según sea necesario
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
        console.log("Archivo descargado");
      } else {
        setLoading(false);
        setDataDocument(false);
        console.log("No se encontró el archivo en la respuesta");
      }
      console.log("response", blob);
    } catch (error) {
      setLoading(false);
      console.log("response", error);
    }
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      {dataDocument ? (
        <Fragment>
          <Box>
            <Typography variant="subtitle">
              Para descargar tus archivos, da clic en el botón.
            </Typography>
          </Box>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <Box>
              <Box>
                <img src={imageDonwload} alt="imagen" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    minWidth: 300,
                    height: 38,
                    fontSize: 16,
                  }}
                  onClick={() => downloadDocument()}
                  variant="contained"
                  color="primary"
                >
                  Descargar archivos
                </Button>
              </Box>
            </Box>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography variant="h5">
                  ¡Lo sentimos! Tu enlace de descarga ha expirado
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mt: 2,
                }}
              >
                <img src={imageDonwloadExpired} alt="imagen" />
              </Box>
            </Box>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

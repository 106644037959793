import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
  Grid,
  styled,
} from "@mui/material";
import {  useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CustomButton } from "../../../components/CustomButton";
import LogoComponent from "../../../assets/LogoComponent.svg";
import { Alert } from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import BackdropComponent from "../../../components/BackDrop";
import { PaymentsService } from "../../../services/PaymentsService";
import { useCallback } from "react";
import PurchaseData from "./PurchaseData";
import { UseImage } from "../../../components/UseImage";

const InputText = styled(TextField)(({ theme }) => ({
  border: ".8px solid #9E9E9E",
  borderRadius: 3,
  "& .MuiOutlinedInput-input": {
    height: 22,
    padding: "5px 5px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 5px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 5,
    fontSize: 14,
    border: 0,
    "& fieldset legend span": {
      display: "none",
      border: 0,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
    border: 0,
  },
  "& label.Mui-error": {
    color: "#C20E30 !important",
    border: 0,
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
    border: 0,
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

export const PaymentUserData = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const navigate = useNavigate();

  // const [clientSecret, setClientSecret] = useState("");
  const clientSecret = useSelector(
    (state) => state.RegisterProjectClientPlan?.items?.clientSecret
  );

  const publiskey = useSelector(
    (state) => state.ConfigStripe?.items?.publicSecretKey
  );

  const statePlanSelect = useSelector(
    (state) => state.GetPlansPayment?.planUserSeleted
  );

  useEffect(() => {
    if (publiskey) {
      setStripePromise(loadStripe(publiskey));
    }
  }, [publiskey]);


  return (
    <Grid container alignContent={"center"} justifyContent={"center"}>
      <Grid item xs={12} lg={5} sx={{ m: "1em" }}>
        <Card
          sx={(theme) => ({
            background: theme.palette.primary.mainLyrics,
            borderRadius: "30px",
            padding: "1.5em",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
            minHeight: "300px",
            width: "100%",
          })}
        >
          <Box
            sx={{
              display: "grid !important",
              gridTemplateColumns: "50% 50%",
              width: "100%",
              alignItems: "center",
              position: "sticky",
              zIndex: 25,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                zIndex: 25,
                paddingLeft: "15px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "130%",
              }}
            >
              <img src={LogoComponent} alt="imageLogo" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "20px",
                zIndex: 25,
              }}
            >
              <Typography variant="titulo">
                ${statePlanSelect?.price}
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "13px",
                    marginLeft: "0.5em",
                  }}
                >
                  {statePlanSelect?.currency}{" "}
                </span>
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={(theme) => ({
              color: "red",
              width: "100%",
              border: `0.5px solid ${theme.palette.divider.main}`,
              margin: "1em 0em",
            })}
          />
          <CardContent>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Detalles de la tarjeta
              </Typography>
            </Box>
            {clientSecret && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret,
                  // appearance: {
                  //   theme: 'stripe'
                  // }
                }}
              >
                <CardDetailsForm />

              </Elements>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={5}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <PurchaseData />
      </Grid>
      <Grid
        item
        lg={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <CustomButton
          onClick={() => navigate("/inbox/payments")}
          sx={{ marginTop: "1.5em", marginBottom: "1.5em", maxWidth: "454px" }}
        >
          Regresar
        </CustomButton>
      </Grid>
    </Grid>
  );
};

const CheckOutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      // `Elements` Instancia que se utilizó para crear el elemento de pago
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      //TODO: SI ES CORRECTO DIRIGE A OTRA PAGINA
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="subtitle" sx={{ fontWeight: 500 }}>
        Nombre del propietario *
      </Typography>
      <InputText
        fullWidth
        helperText={""}
        label={""}
        InputProps={{
          inputProps: { style: { textTransform: "uppercase" } },
        }}
        variant="outlined"
      />
      <PaymentElement options={{}} />
      {errorMessage && (
        <Box sx={{ color: "#C21631", margin: "1em 0em" }}>{errorMessage}</Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1em",
        }}
      >
        <CustomButton
          onClick={handleSubmit}
          sx={{ marginTop: "1.5em", marginBottom: "1.5em", maxWidth: "454px" }}
        >
          Realizar pago
        </CustomButton>
      </Box>
    </form>
  );
};

const CardDetailsForm = () => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const stateClientRegister = useSelector(
    (state) => state.DataPaymentClient?.items
  );
  let roles = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles
  );

  const clientSecret = useSelector(
    (state) => state.RegisterProjectClientPlan?.items?.clientSecret
  );
  const elements = useElements();
  const [timerSeconds, setTimerSeconds] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [isConfirmedPayment, setIsConfirmedPayment] = useState(false);

  const [txtPropietario, setTxtPropietario] = useState("");

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Creacion de metodo de pago.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      metadata: {
        ClientId: stateClientRegister?.client_Id,
        Propietario: txtPropietario,
      },
    });

    if (error?.code) {
      setProcessing(false);
      setLoading(false);
      Alert({
        icon: "error",
        title: "Ocurrió un problema",
        text: "Revisa que los datos de tu tarjeta esten correctos",
      });
      return;
    }

    if (!isConfirmedPayment) {
      //Confirmacion de metodo de pago
      const confirmedCardPayment = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethod.id,
        }
      );
      if (confirmedCardPayment.error) {
        //Todo: se agrega una nueva api para el registro de estos logs en DSCloud.
        setProcessing(false);
        setLoading(false);
        Alert({
          icon: "error",
          title: "Ocurrió un problema",
          text: "Revisa la vigencia de tu tarjeta y que el saldo sea suficiente",
        });
        return;
      } else {
        localStorage.setItem(
          "confirmedCardPaymentId",
          confirmedCardPayment?.paymentIntent?.id
        );
        setIsConfirmedPayment(true);
        getStatusPayment();
      }
    }

  };

  useEffect(() => {
    if (timerSeconds === 0) {
      getStatusPayment();
      setTimerSeconds(3);
    }
    if (!timerSeconds) return;
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  const getStatusPayment = useCallback(async () => {
    const paymentService = PaymentsService.getInstance();
    const resStatus = await paymentService.GetPaymentStatus({
      referenceData: "",
      client_Id: stateClientRegister?.client_Id,
      referenceId: localStorage.getItem("confirmedCardPaymentId"),
    });

    if (resStatus.data.status === "Pagado") {
      if (roles.length === 0) {
        Alert({
          icon: "success",
          title: "Se realizó tu pago de manera exitosa",
          text: `No. de transacción: ${resStatus?.data?.paymentStatus_Id}.`,
        });
        setTimerSeconds(-1);
        setLoading(false);
        sessionStorage.clear();
        navigate("/auth/login");
      } else {
        setTimerSeconds(-1);
        setLoading(false);
        Alert({
          icon: "success",
          title: "Se realizó tu pago de manera exitosa",
          text: `No. de transacción: ${resStatus?.data?.paymentStatus_Id}`,
        },
        (result) => {
          if (result.isConfirmed) {
            navigate("/inbox/BillingData");
          }
        }
      );
      }
      // localStorage.removeItem("confirmedCardPaymentId");
    } else {
      setTimerSeconds(3);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} id="form-css">
      <BackdropComponent loading={loading} />
      <Typography variant="subtitle1" sx={{ fontWeight: 550, mb: 1 }}>
        Nombre del propietario *
      </Typography>
      <InputText
        fullWidth
        name={"txtNombrePropietario"}
        InputProps={{
          inputProps: { style: { textTransform: "uppercase" } },
        }}
        variant="outlined"
        validations={{ required: true }}
        defaultValue={""}
        onChange={(event) => {
          setTxtPropietario(event.target.value.toUpperCase());
        }}
        placeholder="Nombre del propietario"
      />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" sx={{ mt: 1.5, fontWeight: 550 }}>
            Número de tarjeta *
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Box>
            <UseImage type="Image" height={25} src={"Logo_AMEX.png"} />
          </Box>
          <Box>
            <UseImage type="Image" height={25} src={"logo_Mastercard.png"} />
          </Box>
          <Box>
            <UseImage type="Image" height={15} src={"logo_visa.png"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ mt: 1.5, p: 1, border: ".8px solid #9E9E9E", borderRadius: 1 }}
      >
        <CardNumberElement style={{ base: { fontSize: "18px" } }} />
      </Box>
      <Box
        sx={{
          display: "grid !important",
          gridTemplateColumns: "50% 50%",
          width: "100%",
          alignItems: "center",
          position: "sticky",
          zIndex: 25,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-start",
            paddingRight: "0.8em",
          }}
        >
          <Typography variant="subtitle1" sx={{ mt: 1.5, fontWeight: 550 }}>
            Fecha de vencimiento *
          </Typography>
          <Box
            sx={{
              mt: 1.5,
              p: 1,
              border: ".8px solid #9E9E9E",
              borderRadius: 1,
            }}
          >
            <CardExpiryElement style={{ base: { fontSize: "20px" } }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-start",
            paddingLeft: "0.8em",
          }}
        >
          <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 550 }}>
            CVV / CVC *
          </Typography>
          <Box
            sx={{
              mt: 1.5,
              p: 1,
              border: ".8px solid #9E9E9E",
              borderRadius: 1,
            }}
          >
            <CardCvcElement
              style={{ base: { fontSize: "18px", width: "100%" } }}
            />
          </Box>
        </Box>
      </Box>
      {error && <Box sx={{ color: "#C21631", margin: "1em 0em" }}>{error}</Box>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1em",
        }}
      >
        <CustomButton
          type="submit"
          disabled={processing}
          sx={{ marginTop: "1.5em", marginBottom: "1.5em", maxWidth: "454px" }}
        >
          Realizar pago
        </CustomButton>
      </Box>
    </form>
  );
};

import { fileFlowConstants } from "../constants";
import { SignatureService } from "../../services/SignatureService";

const request = (actionType) => {
    return {
        type: actionType,
    };
};

const success = (filesData, actionType) => {
    return {
        type: actionType,
        payload: filesData,
    };
};

const failure = (error, actionType) => {
    return {
        type: actionType,
        payload: error,
    };
};


const emptyAction = (actionType) => {
    return {
        type: actionType,
    };
};

export const verifySignFEAWithOutT = (data, isEmpty = false) => {
    return async (dispatch) => {
        if (isEmpty) {
            dispatch(emptyAction(fileFlowConstants.RESET_FLOW_REQUEST));
        } else {
            dispatch(request(fileFlowConstants.FILE_FLOW_REQUEST));
            try {
                const signatureService = SignatureService.getInstance();
                const xmlDoc = await signatureService.verifySignFEAWithOutT(data);
                dispatch(success(xmlDoc.Body, fileFlowConstants.FILE_FLOW_SUCCESS));
            } catch (error) {
                dispatch(emptyAction(fileFlowConstants.RESET_FLOW_REQUEST));
                console.log('errorXML', error)
                dispatch(failure(error.message, fileFlowConstants.FILE_FLOW_FAILURE));
            }
        }
    }
};